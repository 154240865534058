import React, { useEffect, useState } from "react";
import {
  ITheme,
  useClasses,
  SelectCustom,
  ICol,
  useI18n,
} from "@maxeb/admin-ui";

import { Technique, ITechnique } from "@maxeb/art-sdk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faSpinnerThird,
} from "@fortawesome/pro-duotone-svg-icons";

export interface IProps extends ICol {
  value?: string;
  onChange: (value: string) => void;
  categoryId?: string;
}

export interface IState {
  status: "init" | "ok" | "pending" | "error" | "success";
  search: { open: boolean; name?: string; direction: "ASC" | "DESC" };
  data: ITechnique[];
  value?: string;
  categoryId?: string;
}

const styles = (theme: ITheme) => ({
  curtain: {
    zIndex: "201 !important",
  },
  list: {
    padding: "0px",
    margin: "0px",
    listStyle: "none",
  },
  listElement: {
    cursor: "pointer",
    height: "32px",
    lineHeight: "32px",
    margin: "0px -16px",
    padding: "5px 24px  5px 24px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "&:hover": {
      backgroundColor: theme.palette.get("primary"),
      color: "#fff",
    },
  },
  pending: {
    fontSize: "4rem",
    textAlign: "center",
    color: theme.palette.get("primary"),
  },
  exclamation: {
    textAlign: "center",
  },
  exclamationIcon: {
    fontSize: "4rem",
    color: theme.palette.get("danger"),
    margin: "16px 0px",
  },
});

let MOUNTED = false;

export async function init(
  props: IProps,
  state: IState,
  setState: (state: IState) => void
) {
  setState({ ...state, status: "pending", categoryId: props.categoryId });

  try {
    const result = await Technique.get(
      { categoryId: props.categoryId },
      ["id", "name", "nameDe"],
      20,
      "secondaryIndex",
      state.search.direction
    );

    if (MOUNTED && result.isSuccess()) {
      const value = result.body.data.find(
        (element: ITechnique) => props.value === element.id
      )?.name;
      return setState({
        ...state,
        status: "ok",
        data: result.body.data,
        categoryId: props.categoryId,
        value,
      });
    }
  } catch (err) {
    console.error(err);
  }
  if (MOUNTED)
    setState({ ...state, status: "error", categoryId: props.categoryId });
}

export default function TechniqueSelect(props: IProps) {
  const classes = useClasses(styles);
  const i18n = useI18n("technique_select");

  const [state, setState] = useState<IState>({
    status: "init",
    search: { open: false, direction: "ASC" },
    data: [],
    categoryId: props.categoryId,
  });

  const [open, setOpen] = useState(false);

  const { status, data } = state;

  useEffect(() => {
    MOUNTED = true;
    if (
      (status === "init" || props.categoryId !== state.categoryId) &&
      props.categoryId
    )
      init(props, state, setState);

    return () => {
      MOUNTED = false;
    };
  }, [state, status, props]);

  return (
    <SelectCustom
      inline
      label={i18n.get("technique")}
      {...props}
      open={open}
      value={state.value}
      setOpen={() => {
        setOpen(!open);
      }}
      override={{ curtain: classes.curtain }}
    >
      {!props.categoryId && (
        <div className={classes.exclamation}>
          <div>
            <FontAwesomeIcon
              className={classes.exclamationIcon}
              icon={faExclamationTriangle}
            />
          </div>
          {i18n.get("please_first_select")}
        </div>
      )}
      {props.categoryId && (
        <ul className={classes.list}>
          {!(state.status === "init" || state.status === "pending") && (
            <>
              <li
                className={classes.listElement}
                onClick={() => {
                  setState({ ...state, value: "" });
                  props.onChange("");
                }}
              >
                {i18n.get("no")}
              </li>
              {data.map((theme) => (
                <li
                  key={`artist-${theme.id}`}
                  className={classes.listElement}
                  onClick={() => {
                    setState({ ...state, value: theme.name });
                    props.onChange(theme.id || "");
                  }}
                >
                  {i18n.language === "en" ? theme.name : theme.nameDe}
                </li>
              ))}
            </>
          )}
          {(state.status === "init" || state.status === "pending") && (
            <li className={classes.pending}>
              <FontAwesomeIcon icon={faSpinnerThird} spin />
            </li>
          )}
        </ul>
      )}
    </SelectCustom>
  );
}

import { ArtworkWIthIndex, Props, State, initialState } from "./State";
import { useEffect } from "react";
import * as Mutations from "./Mutations";
import useSaveState from "../../SaveState";
import filter from "./Filter";
import { IArtwork } from "@maxeb/art-sdk";

export type { State, Props } from "./State";
export interface Actions {
  next: () => void;
  nextArtworks: (collectionIndex: number) => void;
  loadNotAssigned: (label: string) => void;
  filterArtworks: (collectionIndex: number) => ArtworkWIthIndex[];
  setActive: (
    indexes: [number, number] | null,
    artwork: IArtwork | null
  ) => void;
  updateActive: (artwork: IArtwork | null) => void;
  search: (params: State["search"]) => void;
  setFinished: (id: string) => void;
}

let searchChecksum: string | undefined;

function getSearchChecksum() {
  return searchChecksum;
}

export function useFullCollections(props: Props): [State, Actions] {
  const [state, setState] = useSaveState(initialState(props));

  searchChecksum = state.searchChecksum;

  useEffect(() => {
    if (state.status === "init") Mutations.next(state, setState);
  }, [state, setState]);

  return [
    state,
    {
      next: () => {
        Mutations.next(state, setState);
      },
      nextArtworks: (collectionIndex: number) => {
        Mutations.nextArtworks(
          { collectionIndex },
          state,
          (newState: State) => {
            if (getSearchChecksum() === newState.searchChecksum)
              setState(newState);
          }
        );
      },
      loadNotAssigned: (label: string) => {
        Mutations.loadNotAssigned({ label, team: props.team }, state, setState);
      },
      filterArtworks: (collectionIndex: number) => {
        return filter(collectionIndex, state);
      },
      search: (params) => {
        Mutations.search({ search: params }, state, setState);
      },
      setFinished: (id: string) => {
        Mutations.setFinished({ id }, state, setState);
      },
      setActive: (indexes, artwork) => {
        Mutations.setActive({ indexes, artwork }, state, setState);
      },
      updateActive: (artwork) => {
        Mutations.updateActive({ artwork }, state, setState);
      },
    },
  ];
}

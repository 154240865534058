import React, { useState } from "react";
import { Button, Col, ITheme, Row, Typo, useClasses } from "@maxeb/admin-ui";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faLocationPin,
  faUser,
  faPenToSquare,
  faTrash,
  faCheck,
  faClose,
} from "@fortawesome/pro-light-svg-icons";

export interface IProps {
  title: string;
  description: string;
  date: string;
  type: "owner" | "location" | "condition";
  delete: () => void;
}

export interface IState {}

const styles = (theme: ITheme) => ({
  owner: {
    color: theme.palette.get("primary"),
  },
  location: {
    color: theme.palette.get("success"),
  },
  condition: {
    color: theme.palette.get("danger"),
  },
  date: {
    marginLeft: "8px",
    display: "inline-block",
    float: "right",
  },
  headline: {
    margin: 0,
    fontWeight: 400,
  },
  text: {
    fontSize: "0.9rem",
    margin: 0,
  },
  element: {
    borderLeft: "2px solid " + theme.palette.get("grey"),
    padding: "16px 0px 24px 24px",
    marginLeft: "16px",
    position: "relative",
  },
  ball: {
    position: "absolute",
    left: "-16px",
    backgroundColor: theme.palette.get("primary"),
    borderRadius: "32px",
    width: "32px",
    height: "32px",
    display: "inline-block",
    color: "#fff",
  },
  ballLocation: {
    backgroundColor: theme.palette.get("success"),
  },
  ballCondition: {
    backgroundColor: theme.palette.get("danger"),
  },
  icon: {
    margin: "9px 0px 0px 10px",
  },
  trashbtn: {
    textAlign: "right",
    paddingTop: "4px",
  },
  confirmWrapper: {
    position: "absolute",
    top: 0,
    left: 24,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.95)",
  },
  btn: {
    marginTop: "16px",
  },
  btnDelete: {
    marginTop: "16px",
  },
});

export default function LogElement(props: IProps) {
  const classes = useClasses(styles);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const { description, type } = props;

  let date = "";
  if (props.date) {
    const dateFormater = new Date(props.date);
    date = `${dateFormater.getDate()}.${
      dateFormater.getMonth() + 1
    }.${dateFormater.getFullYear()}`;
  }

  const isOwner = type === "owner";
  const isLocation = type === "location";
  const isCondition = type === "condition";

  return (
    <div className={classes.element}>
      {confirmDelete && (
        <div className={classes.confirmWrapper}>
          <Row spacing={8} root={0}>
            <Col xs="auto">
              <Typo variant="h2">Delete?</Typo>
            </Col>
            <Button
              xs="40px"
              danger
              override={{ col: classes.btnDelete }}
              onClick={props.delete}
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
            <Button
              xs="40px"
              override={{ col: classes.btn }}
              onClick={() => {
                setConfirmDelete(false);
              }}
            >
              <FontAwesomeIcon icon={faClose} />
            </Button>
          </Row>
        </div>
      )}
      <div
        className={classNames([
          classes.ball,
          isLocation && classes.ballLocation,
          isCondition && classes.ballCondition,
        ])}
      >
        <FontAwesomeIcon
          className={classes.icon}
          icon={
            (isLocation && faLocationPin) ||
            (isOwner && faUser) ||
            faPenToSquare
          }
        />
      </div>
      <h3 className={classes.headline}>
        <small className={classes.date}>
          <div>{date}</div>
          <div className={classes.trashbtn}>
            <Button
              width="40px"
              onClick={() => {
                setConfirmDelete(true);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>
        </small>
        <span>{props.title}</span>
      </h3>
      <p className={classes.text}>{description}</p>
    </div>
  );
}

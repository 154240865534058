import React from "react";
import {
  Button,
  ITheme,
  useClasses,
  useI18n,
  LightBox,
  Form,
  Row,
  Col,
  TextInput,
  StateHandler,
  Radio,
  RadioItem,
} from "@maxeb/admin-ui";
import ImageHandler from "../Shared/ImageHandler";
import { useCollectionAdd } from "../../Hooks/Collections/Add";
import { ICollection } from "@maxeb/art-sdk";

export interface IProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  reset: () => void;
  setNewActive: (collection: ICollection) => void;
}

const styles = (theme: ITheme) => ({
  alignCenter: {
    textAlign: "center",
  },
  overflow: {
    overflow: "hidden",
  },
});

export default function CollectionAdd(props: IProps) {
  const classes = useClasses(styles);
  const i18n = useI18n("collection_add");
  const i18nErrors = useI18n("errors");

  const [collection, actions] = useCollectionAdd();
  const { status, values, errors } = collection;

  return (
    <LightBox
      title={i18n.get("add_artist")}
      open={props.open}
      onClose={() => {
        if (status !== "pending") {
          actions.reset();
          props.setOpen(false);
        }
      }}
    >
      <StateHandler state={status}>
        <Form spacing={16} root={0} vertical>
          <Col md="calc(100% - 180px)">
            <Row spacing={16} vertical root={0}>
              <TextInput
                inline
                label={i18n.get("name")}
                value={values.name}
                error={errors.name && i18nErrors.get(errors.name)}
                onChange={(value) => {
                  const newValues = { ...collection.values, name: value || "" };
                  actions.setValues(newValues);
                }}
              />
              <TextInput
                inline
                rows={5}
                label={i18n.get("description")}
                value={values.description}
                error={errors.description && i18nErrors.get(errors.description)}
                onChange={(value) => {
                  const newValues = {
                    ...collection.values,
                    description: value || "",
                  };
                  actions.setValues(newValues);
                }}
              />
            </Row>
          </Col>
          <ImageHandler
            inline
            md="180px"
            height="144px"
            value={collection.values.thumb}
            error={errors.thumb && i18nErrors.get(errors.thumb)}
            override={{ col: classes.overflow }}
            onChange={(value) => {
              const values = { ...collection.values, thumb: value };
              actions.setValues(values);
            }}
          >
            <div className={classes.alignCenter}>
              <b>{i18n.get("thumbnail")}</b>
              <br />
              {i18n.get("upload")}
            </div>
          </ImageHandler>
          <Radio
            label="Sichtbarkeit"
            xs={5}
            value={collection.values.public ? "public" : "private"}
            error={errors.public && i18nErrors.get(errors.public)}
            onChange={(value) => {
              const values = {
                ...collection.values,
                public: value === "public",
              };
              actions.setValues(values);
            }}
          >
            <RadioItem xs={5} label="Öffentlich" value="public" />
            <RadioItem xs={5} label="Privat" value="private" />
          </Radio>
          <Row spacing={16} vertical root={0} horizontalAlign="right">
            <Button
              xs="100px"
              onClick={() => {
                if (status !== "pending") {
                  actions.pending();
                  props.setOpen(false);
                }
              }}
            >
              {i18n.get("close")}
            </Button>
            <Button
              xs="100px"
              success
              onClick={async () => {
                const collection = await actions.save();
                if (collection) {
                  props.setNewActive(collection);
                  setTimeout(() => {
                    actions.reset();
                    props.setOpen(false);
                  }, 1000);
                }
              }}
            >
              {i18n.get("add")}
            </Button>
          </Row>
        </Form>
      </StateHandler>
    </LightBox>
  );
}

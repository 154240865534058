import React from "react";
import {
  Button,
  ITheme,
  useClasses,
  useI18n,
  LightBox,
  Form,
  Row,
  Col,
  TextInput,
  StateHandler,
  Radio,
  RadioItem,
} from "@maxeb/admin-ui";
import { IArtist } from "@maxeb/art-sdk";
import ImageHandler from "../Shared/ImageHandler";
import { useArtistAdd } from "../../Hooks/Artists/Add";

export interface IProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  setNewActive: (artist: IArtist) => void;
  reset: () => void;
}

const styles = (theme: ITheme) => ({
  alignCenter: {
    textAlign: "center",
  },
  overflow: {
    overflow: "hidden",
  },
});

export default function Artists(props: IProps) {
  const classes = useClasses(styles);
  const i18n = useI18n("artist_add");
  const i18nErrors = useI18n("errors");

  const [artist, actions] = useArtistAdd();
  const { status, values, errors } = artist;

  return (
    <LightBox
      title={i18n.get("add_artist")}
      open={props.open}
      onClose={() => {
        if (status !== "pending") actions.reset();
      }}
    >
      <StateHandler state={status}>
        <Form spacing={16} root={0} vertical>
          <Col md="calc(100% - 180px)">
            <Row spacing={16} vertical root={0}>
              <TextInput
                inline
                label={i18n.get("name")}
                value={values.name}
                error={errors.name && i18nErrors.get(errors.name)}
                onChange={(value) => {
                  const newValues = { ...artist.values, name: value || "" };
                  actions.setData(newValues);
                }}
              />
              <TextInput
                inline
                rows={5}
                label={i18n.get("description")}
                value={values.description}
                error={errors.description && i18nErrors.get(errors.description)}
                onChange={(value) => {
                  const newValues = { ...artist.values, description: value };
                  actions.setData(newValues);
                }}
              />
            </Row>
          </Col>
          <ImageHandler
            inline
            md="180px"
            height="144px"
            value={artist.values.thumb}
            error={errors.thumb && i18nErrors.get(errors.thumb)}
            override={{ col: classes.overflow }}
            onChange={(value) => {
              const newValues = { ...artist.values, thumb: value };
              actions.setData(newValues);
            }}
          >
            <div className={classes.alignCenter}>
              <b>{i18n.get("thumbnail")}</b>
              <br />
              {i18n.get("upload")}
            </div>
          </ImageHandler>
          <Radio
            label={i18n.get("visibility")}
            value={artist.values.public ? "public" : "private"}
            error={errors.public && i18nErrors.get(errors.public)}
            onChange={(value) => {
              const newValues = {
                ...artist.values,
                public: value === "public",
              };
              actions.setData(newValues);
            }}
          >
            <RadioItem xs={5} label={i18n.get("public")} value="public" />
            <RadioItem xs={5} label={i18n.get("private")} value="private" />
          </Radio>
          <Row spacing={16} vertical root={0} horizontalAlign="right">
            <Button
              xs="100px"
              onClick={() => {
                if (status !== "pending") {
                  actions.reset();
                  props.setOpen(false);
                }
              }}
            >
              {i18n.get("close")}
            </Button>
            <Button
              xs="100px"
              success
              onClick={async () => {
                const artist = await actions.save();
                if (artist) {
                  props.setNewActive(artist);
                  setTimeout(() => {
                    actions.reset();
                    props.setOpen(false);
                  }, 1000);
                }
              }}
            >
              {i18n.get("add")}
            </Button>
          </Row>
        </Form>
      </StateHandler>
    </LightBox>
  );
}

import { useCallback, useState } from "react";

import useIsMounted from "./IsMounted";

export default function useSaveState<State>(
  initialState: State
): [State, (state: State) => void] {
  const isMounted = useIsMounted();

  const [state, setState] = useState(initialState);

  const setStateSave = useCallback(
    (state: State) => {
      if (isMounted()) setState(state);
    },
    [isMounted]
  );

  return [state, setStateSave];
}

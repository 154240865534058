import React from "react";
import {
  Block,
  Button,
  Col,
  Form,
  ITheme,
  Row,
  Table,
  Typo,
  useClasses,
  useI18n,
} from "@maxeb/admin-ui";
import { IFields } from "./Wizzard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";

export interface IProps {
  type: "artist" | "artwork";
  fields: IFields;
  selectedFields?: string[];
  onNext: () => void;
  onBack: () => void;
}

export interface IState {}

const styles = (theme: ITheme) => ({
  next: { textAlign: "right" },
  fieldName: { color: theme.palette.get("primary") },
});

export default function Instructions(props: IProps) {
  const classes = useClasses(styles);
  const i18n = useI18n("import");

  const { type, selectedFields } = props;

  const fieldsToUse = props.fields[type];

  return (
    <Row spacing={16} vertical root={0}>
      <Typo variant="h1">{i18n.get("import_" + props.type)}</Typo>
      <Col lg={5}>
        <Block>
          <Typo variant="section" color="primary" margin="none">
            5.{i18n.get("import")}
          </Typo>
          <Typo variant="p">{i18n.get("import_desc")}</Typo>
          <Table
            columns={(selectedFields || []).map((item) => ({
              label: item,
              field: item,
            }))}
            data={[{}, {}]}
          />
          <Typo variant="p">{i18n.get("create_csv_desc_2")}</Typo>
          <Form
            spacing={16}
            vertical
            root={{ top: 16, left: 0, right: 0, bottom: 0 }}
          >
            {fieldsToUse
              .filter((item) => selectedFields?.includes(item.name))
              .map((field) => {
                return (
                  <React.Fragment key={`inst-${field.name}`}>
                    <Typo variant="h3">
                      {field.label}
                      <small>
                        {" "}
                        <FontAwesomeIcon icon={faArrowRight} />{" "}
                        <span className={classes.fieldName}>{field.name}</span>
                      </small>
                    </Typo>
                    {field.desc}
                  </React.Fragment>
                );
              })}
            <Row>
              <Col xs={5}>
                <Button width={"100px"} onClick={props.onBack}>
                  Back
                </Button>
              </Col>
              <Col override={{ col: classes.next }} xs={5}>
                <Button
                  width={"100px"}
                  primary
                  onClick={() => {
                    props.onNext();
                  }}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Form>
        </Block>
      </Col>
    </Row>
  );
}

import { useArtworks } from "../../../Hooks/Artworks/List";
import TableView from "./TableView";

import GridView from "./GridView";
import { useEffect } from "react";
import { FullCollection } from "../../../Hooks/Artworks/ListCollection/State";
import { Actions } from "../../../Hooks/Artworks/ListCollection";

export interface IProps {
  view: "grid" | "table";
  team: string;
  collection: FullCollection;
  actions: Actions;
}

export default function Artworks(props: IProps) {
  const { view, collection, actions } = props;

  return (
    <>
      {view === "grid" && (
        <GridView collection={collection} actions={actions} />
      )}
      {/*       {view === "table" && (
        <TableView
          status={status}
          useArtworks={[artworks, actions]}
          team={props.team}
        />
      )} */}
    </>
  );
}

import { Artwork } from "@maxeb/art-sdk";
import { State, SetState, initialState } from "./State";
import { Props } from ".";

export function setValues(
  params: { values: State["values"] },
  state: State,
  setState: SetState
) {
  const { values } = params;
  setState({ ...state, values });
}
export function setArtist(
  params: { id?: string; name?: string },
  state: State,
  setState: SetState
) {
  const { id, name } = params;

  setState({
    ...state,
    values: { ...state.values, artistId: id },
    artistName: name,
  });
}
export function setPending(state: State, setState: SetState) {
  setState({ ...state, status: "pending" });
}
export async function add(state: State, setState: SetState) {
  setPending(state, setState);

  try {
    const response = await Artwork.add({
      title: state.values.title || "",
      description: state.values.description,
      thumb: state.values.thumb,
      artistId: state.values.artistId,
      weight: state.values.weight || 0,
      collection: state.collection,
    });

    if (response.isSuccess()) {
      setState({ ...state, status: "success" });

      return response.getResult().data.id;
    } else {
      const errors = response.getErrors({
        "body.thumb": "thumb",
        "body.title": "title",
        "body.artistId": "artist",
        "body.description": "description",
      });

      return setState({
        ...state,
        status: "ok",
        errors: errors,
      });
    }
  } catch (error) {
    console.error(error);
  }
  setState({ ...state, errors: {}, status: "error" });
}
export function reset(props: Props, state: State, setState: SetState) {
  setState(initialState(props));
}

import { ICollection } from "@maxeb/art-sdk";
import { State, initialState } from "./State";
import { useEffect } from "react";
import * as Mutations from "./Mutations";
import useSaveState from "../../SaveState";

export type { State } from "./State";
export interface Actions {
  next: () => void;
  active: (collection: ICollection | null, index: number | null) => void;
  setNewActive: (collection: ICollection) => void;
  updateActive: (collection: ICollection | null) => void;
  reset: () => void;
  search: (search: State["search"]) => void;
}

export function useCollections(): [State, Actions] {
  const [state, setState] = useSaveState(initialState());

  useEffect(() => {
    if (state.status === "init") {
      Mutations.next(state, setState);
    }
  }, [state, setState]);

  return [
    state,
    {
      next: () => {
        Mutations.next(state, setState);
      },
      search: (params) => {
        Mutations.search({ search: params }, state, setState);
      },
      active: (collection, index) => {
        Mutations.setActive({ collection, index }, state, setState);
      },
      updateActive: (collection) => {
        Mutations.updateActive({ collection }, state, setState);
      },
      setNewActive: (collection) => {
        Mutations.setNewActive({ collection }, state, setState);
      },
      reset: () => {
        Mutations.reset(state, setState);
      },
    },
  ];
}

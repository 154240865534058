import React, { useState } from "react";
import {
  AdminPage,
  ITheme,
  useClasses,
  useI18n,
  Row,
  Col,
  Typo,
} from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPalette, faTheaterMasks } from "@fortawesome/pro-light-svg-icons";
import { IArtist } from "@maxeb/art-sdk";
import Add from "../Add";
import Card from "../../Shared/Card";
import Search from "./Search";
import ArtistEdit from "../Edit";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import IsVisible from "../../Shared/IsVisible";
import { useArtists } from "../../../Hooks/Artists/List";
import Actions from "./Actions";

export interface IProps {
  team: string;
}

export interface IState {
  status: "init" | "ok" | "pending" | "error" | "success";
  search: { open: boolean; name?: string; direction: "ASC" | "DESC" };
  data: IArtist[];
  next?: string;
  active: IArtist | null;
  team: string;
}

const styles = (theme: ITheme) => ({
  overlay: {
    padding: "132px",
    backgroundColor: "rgba(236, 236, 236, 0.95) !important",
  },
  main: {
    height: "calc(100vh - 251px) !important",
    [theme.breakpoints.down("md")]: {
      height: "calc(100vh - 123px) !important",
    },
    "@media print": {
      height: "auto !important",
    },
  },
  holder: { borderRadius: "100%", textAlign: "center" },
  userIcon: {
    fontSize: "3rem",
  },
  row: {
    [theme.breakpoints.up("md")]: {
      width: "200px",
    },
  },
  searchWrapper: {
    backgroundColor: theme.palette.get("grey"),
  },
  icon: {
    fontSize: "4rem",
    margin: "0px 16px 0px 0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
      margin: "0px 4px 0px 0px",
    },
  },
  select: { zIndex: "201 !important" },
  link: {
    textDecoration: "none",
  },
  pending: {
    color: theme.palette.get("primary"),
    fontSize: "5rem",
    textAlign: "center",
    padding: "32px",
  },
});

export default function Artists(props: IProps) {
  const classes = useClasses(styles);
  const i18n = useI18n("artists");

  const [add, setAdd] = useState<boolean>(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [artist, actions] = useArtists();

  const { status, data, search } = artist;

  return (
    <AdminPage
      icon={<FontAwesomeIcon className={classes.icon} icon={faPalette} />}
      title={i18n.get("artists")}
      state={
        status === "init" ? "pending" : status === "pending" ? "ok" : status
      }
      breadcrumbs={[{ label: i18n.get("artists") }, { label: i18n.get("all") }]}
      actions={
        <Actions
          searchOpen={searchOpen}
          setSearchOpen={setSearchOpen}
          setAdd={setAdd}
        />
      }
      override={{ overlay: classes.overlay, main: classes.main }}
      subhead={
        <Search
          respOpen={searchOpen}
          search={search}
          setSearch={actions.search}
        />
      }
      overlayPages={
        artist.active !== null
          ? [
              <div className={classes.active}>
                <ArtistEdit
                  id={artist.active.id || ""}
                  onChange={(artist) => {
                    actions.updateActive(artist);
                  }}
                  close={(isDeleted) => {
                    if (isDeleted) actions.updateActive(null);
                    else actions.active(null, null);
                  }}
                  team={props.team}
                />
              </div>,
            ]
          : undefined
      }
    >
      <Add
        open={add}
        setOpen={setAdd}
        setNewActive={actions.setNewActive}
        reset={() => actions.reset()}
      />
      <Row spacing={16} vertical root={0}>
        {data.map((artist, index) => (
          <Col
            key={`artist-${artist.id}`}
            xs={10}
            sm={5}
            md={10 / 3}
            lg={10 / 4}
            xl={10 / 6}
          >
            <a
              className={classes.link}
              href={`/artists/${artist.id}`}
              onClick={(e) => {
                e.preventDefault();
                actions.active(artist, index);
                window.history.pushState({}, "", `/artists/${artist.id}`);
              }}
            >
              <Card
                name={artist.name}
                icon={faTheaterMasks}
                thumb={
                  artist.thumb && {
                    ...artist.thumb,
                    url: artist.thumb["xs-url"],
                  }
                }
              />
            </a>
          </Col>
        ))}
        {status === "ok" && data.length === 0 && (
          <Row>
            <Col lg={5}>
              <Typo variant="h1">{i18n.get("no_artist")}</Typo>
              <Typo variant="p">{i18n.get("no_artist_desc")}</Typo>
            </Col>
          </Row>
        )}
        {status === "pending" && (
          <Col
            xs={10}
            sm={5}
            md={10 / 3}
            lg={10 / 4}
            xl={10 / 5}
            override={{ col: classes.pending }}
          >
            <FontAwesomeIcon icon={faSpinnerThird} spin />
          </Col>
        )}
        <Col xs={10} sm={5} md={10 / 3} lg={10 / 4} xl={10 / 5}>
          <IsVisible
            onChange={(isVisible) => {
              if (isVisible && status === "ok" && artist.next) {
                actions.next();
              }
            }}
          />
        </Col>
      </Row>
    </AdminPage>
  );
}

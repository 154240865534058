import React from "react";
import { useClasses, ITheme, Col, Form, Typo, useI18n } from "@maxeb/admin-ui";
import office from "../../../Assets/images/mockups/office/preview.png";
import livingRoom from "../../../Assets/images/mockups/livingRoom/preview.png";
import small from "../../../Assets/images/mockups/small/preview.png";
import coffee from "../../../Assets/images/mockups/coffee/preview.png";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";

export interface IProps {
  selected?: string;
  onSelect: (selected: string) => void;
}

const styles = (theme: ITheme) => ({
  alignRight: { textAlign: "right" },
  img: {
    width: "100%",
    height: "200px",
    objectFit: "cover",
    borderRadius: "4px",
    cursor: "pointer",
  },
  selected: {
    border: "10px solid " + theme.palette.get("primary"),
    boxSizing: "border-box",
    borderRadius: "4px",
    cursor: "default",
  },
  relative: {
    position: "relative",
  },
  checked: {
    position: "absolute",
    top: 10,
    left: 10,
    fontSize: "2rem",
    color: "#fff",
    backgroundColor: theme.palette.get("primary"),
    padding: "8px",
    borderBottomRightRadius: "16px",
  },
});

const Card: React.FC<IProps> = (props) => {
  const classes = useClasses(styles, props);

  const i18n = useI18n("artwork_mockups");

  const { selected } = props;

  return (
    <Form spacing={16} root={{ top: 0, left: 0, right: 0 }} vertical>
      <Col>
        <Typo variant="h2" margin="none">
          {i18n.get("select")}
        </Typo>
        <Typo variant="p">{i18n.get("selector_desc")}</Typo>
      </Col>
      <Col
        md={5}
        override={{ col: selected === "office" ? classes.relative : "" }}
      >
        <img
          src={office}
          alt="office-template"
          className={classnames([
            classes.img,
            selected === "office" && classes.selected,
          ])}
          onClick={() => props.onSelect("office")}
        />
        {selected === "office" && (
          <FontAwesomeIcon icon={faCheckCircle} className={classes.checked} />
        )}
      </Col>
      <Col
        md={5}
        override={{ col: selected === "livingRoom" ? classes.relative : "" }}
      >
        <img
          src={livingRoom}
          alt="livingRoom-template"
          className={classnames([
            classes.img,
            selected === "livingRoom" && classes.selected,
          ])}
          onClick={() => props.onSelect("livingRoom")}
        />
        {selected === "livingRoom" && (
          <FontAwesomeIcon icon={faCheckCircle} className={classes.checked} />
        )}
      </Col>
      <Col
        md={5}
        override={{ col: selected === "coffee" ? classes.relative : "" }}
      >
        <img
          src={coffee}
          alt="coffee-template"
          className={classnames([
            classes.img,
            selected === "coffee" && classes.selected,
          ])}
          onClick={() => props.onSelect("coffee")}
        />
        {selected === "coffee" && (
          <FontAwesomeIcon icon={faCheckCircle} className={classes.checked} />
        )}
      </Col>
      <Col
        md={5}
        override={{ col: selected === "small" ? classes.relative : "" }}
      >
        <img
          src={small}
          alt="small-template"
          className={classnames([
            classes.img,
            selected === "small" && classes.selected,
          ])}
          onClick={() => props.onSelect("small")}
        />
        {selected === "small" && (
          <FontAwesomeIcon icon={faCheckCircle} className={classes.checked} />
        )}
      </Col>
    </Form>
  );
};

export default Card;

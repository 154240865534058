import React from "react";
import logo from "../../Assets/images/logoWebcollector.svg";
import classnames from "classnames";
import { ITheme, Row, Col, useClasses, useI18n } from "@maxeb/admin-ui";
import classNames from "classnames";

interface ILogo {
  color?: string;
  size?: number;
  labelSize?: number;
  noLabel?: boolean;
  langSwap?: boolean;
}

const styles = (theme: ITheme) => ({
  logo: (props: ILogo) => ({
    display: "block",
    maxWidth: "100%",
    height: props.size,
  }),
  respBig: {
    [theme.breakpoints.down("md")]: {
      height: "250px !important",
    },
  },
  label: (props: ILogo) => ({
    display: "block",
    fontSize:
      typeof props.labelSize !== "undefined"
        ? `${props.labelSize}rem`
        : "1.7rem",
    marginLeft: "8px",
    color: props.color || "initial",
    lineHeight:
      typeof props.size === "undefined"
        ? "60px"
        : !props.langSwap
        ? `${props.size}px`
        : "25px",
  }),
  langSwap: {
    marginLeft: "40px",
  },
  trButton: {
    cursor: "pointer",
  },
  trActive: {
    color: "#fff",
  },
  link: {
    textDecoration: "none",
  },
});

const Logo: React.FC<ILogo> = (props) => {
  const classes = useClasses(styles, props);
  const i18n = useI18n("logo");
  return (
    <Row spacing={0} horizontalAlign="center" verticalAlign="center">
      <Col xs={typeof props.size !== "undefined" ? `${props.size}px` : "60px"}>
        <a className={classes.link} href="/">
          <img
            src={logo}
            className={classNames([
              classes.logo,
              props.langSwap && classes.respBig,
            ])}
            alt="webcollector-logo"
          />
        </a>
      </Col>
      {!props.noLabel && (
        <Col xs="rest">
          <div>
            <span className={classes.link}>
              <span className={classes.label}>Webcollector</span>
            </span>
            {props.langSwap && (
              <div className={classes.langSwap}>
                <span
                  className={classnames([
                    classes.trButton,
                    i18n.language === "de" && classes.trActive,
                  ])}
                  onClick={() => i18n.language !== "de" && i18n.load("de")}
                >
                  DE
                </span>{" "}
                |{" "}
                <span
                  className={classnames([
                    classes.trButton,
                    i18n.language === "en" && classes.trActive,
                  ])}
                  onClick={() => i18n.language !== "en" && i18n.load("en")}
                >
                  EN
                </span>
              </div>
            )}
          </div>
        </Col>
      )}
    </Row>
  );
};

export default Logo;

import { useEffect } from "react";
import { State, Props, initialState } from "./State";
import { useNavigate } from "@maxeb/admin-ui";
import * as Mutations from "./Mutations";
import useSaveState from "../../SaveState";

export type { State, Props } from "./State";

export interface Actions {
  get: () => void;
  save: () => void;
  remove: () => void;
  setConfirmDelete: (to: boolean) => void;
  setData: (data: State["data"]) => void;
  pending: () => void;
  reload: () => void;
}

export function useArtistEdit(props: Props): [State, Actions] {
  const { id } = props;
  const [state, setState] = useSaveState(initialState(props));

  useEffect(() => {
    if (state.status === "init") Mutations.get(state, setState);

    if (
      id &&
      window.location.href.endsWith &&
      window.location.href.endsWith("/artists")
    )
      window.history.pushState({}, "", `/artists/${id}`);

    return () => {};
  }, [state, setState, id]);

  const navigate = useNavigate();

  return [
    state,
    {
      get: () => {
        Mutations.get(state, setState);
      },
      save: () => {
        Mutations.save(state, setState);
      },
      remove: () => {
        Mutations.remove({ navigate }, state, setState);
      },
      setConfirmDelete: (to) => {
        Mutations.setConfirmDelete({ to }, state, setState);
      },
      pending: () => {
        Mutations.setPending(state, setState);
      },
      reload: () => {
        Mutations.reload(state, setState);
      },
      setData: (data) => {
        Mutations.setData({ data }, state, setState);
      },
    },
  ];
}

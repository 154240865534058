import React, { useState } from "react";

import {
  Row,
  Col,
  Button,
  TextInput,
  Form,
  DateInput,
  Typo,
  useI18n,
  StateHandler,
} from "@maxeb/admin-ui";

export interface IProps {
  onClose: () => void;
  add: (
    name: string,
    date: [string, string, string],
    description: string
  ) => void;
}

const NewCondition: React.FC<IProps> = (props) => {
  const [title, setTitle] = useState<string | undefined>();

  const i18n = useI18n("artworks_condition");

  const [state, setState] = useState<"ok" | "pending">("ok");
  const today = new Date();

  const [fullDate, setDate] = useState<[string, string, string]>([
    `${today.getDate()}`,
    `${today.getMonth() + 1}`,
    `${today.getFullYear()}`,
  ]);
  const [description, setDescription] = useState<string | undefined>();

  return (
    <StateHandler state={state}>
      <Form
        vertical
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Row
          spacing={16}
          vertical
          root={{ top: 0, left: 0, right: 0, bottom: 0 }}
          verticalAlign={"top"}
          horizontalAlign="center"
        >
          <Col>
            <Typo variant="p">{i18n.get("desc")}</Typo>
          </Col>
          <Col>
            <Row spacing={16} vertical root={0} horizontalAlign="right">
              <TextInput
                lg={5}
                inline
                label={i18n.get("title")}
                value={title}
                onChange={(value) => setTitle(value)}
              />
              <DateInput
                lg={5}
                inline
                label={i18n.get("date")}
                value={fullDate}
                onChange={(value) => setDate(value || ["", "", ""])}
              />
              <TextInput
                inline
                label={i18n.get("description")}
                rows={4}
                value={description}
                onChange={(value) => setDescription(value || "")}
              />
              <Button
                xs="120px"
                onClick={() => {
                  props.onClose();
                }}
              >
                {i18n.get("cancel")}
              </Button>
              <Button
                xs="120px"
                success
                onClick={async () => {
                  setState("pending");
                  await props.add(title + "", fullDate, description + "");
                  props.onClose();
                  setState("ok");
                }}
              >
                {i18n.get("add")}
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </StateHandler>
  );
};

export default NewCondition;

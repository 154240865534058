import React, { useEffect, useState } from "react";
import {
  useClasses,
  ITheme,
  Button,
  Row,
  Col,
  useI18n,
  StateHandler,
} from "@maxeb/admin-ui";
import officeBG from "../../../Assets/images/mockups/office/back.png";
import officeFE from "../../../Assets/images/mockups/office/front.png";
import livingRoomBG from "../../../Assets/images/mockups/livingRoom/back.png";
import livingRoomFE from "../../../Assets/images/mockups/livingRoom/front.png";
import coffeeBG from "../../../Assets/images/mockups/coffee/back.png";
import coffeeFE from "../../../Assets/images/mockups/coffee/front.png";
import smallBG from "../../../Assets/images/mockups/small/back.png";
import smallFE from "../../../Assets/images/mockups/small/front.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { Artwork } from "@maxeb/art-sdk";

export interface IProps {
  selected: string;
  width: number;
  height: number;
  thumb: string;
  artwork: string;
  collection: string;
  back?: () => void;
  setSuccess: () => void;
}

export interface IState {
  id: string;
  collection: string;
  status: "ok" | "pending" | "success" | "error";
  errors: { [key: string]: string };
}

const styles = (theme: ITheme) => ({
  wrapper: {
    position: "relative",
  },
  mock: {
    width: "100%",
  },
  alignRight: {
    textAlign: "right",
  },
  pending: {
    color: theme.palette.get("primary"),
    fontSize: "4rem",
    textAlign: "center",
    padding: "32px 0px",
  },
});

function getDimensions(name: string) {
  switch (name) {
    case "office":
      //[width, height, pixelPerMeter, paddingTop, paddingRight, padingBottom, paddingLeft]
      return [1000, 750, 184, 0, 0, 90, 125];
    case "livingRoom":
      //[width, height, pixelPerMeter, paddingTop, paddingRight, padingBottom, paddingLeft]
      return [1000, 667, 180, 12, 0, 90, 200];
    case "coffee":
      //[width, height, pixelPerMeter, paddingTop, paddingRight, padingBottom, paddingLeft]
      return [1000, 1000, 312, 0, 0, 126, 0];
    case "small":
      //[width, height, pixelPerMeter, paddingTop, paddingRight, padingBottom, paddingLeft]
      return [1000, 1179, 620, 0, 0, 0, 0];
    default:
      throw Error("Unknown pixel / meter value for " + name + ".");
  }
}
function getImageSrc(name: string) {
  switch (name) {
    case "office":
      //[back, front]
      return [officeBG, officeFE];
    case "livingRoom":
      //[back, front]
      return [livingRoomBG, livingRoomFE];
    case "coffee":
      //[back, front]
      return [coffeeBG, coffeeFE];
    case "small":
      //[back, front]
      return [smallBG, smallFE];
    default:
      throw Error("Unknown pixel / meter value for " + name + ".");
  }
}
function getSizes(props: IProps) {
  const [width, height, pixelPerMeter, pt, pr, pb, pl] = getDimensions(
    props.selected
  );

  //get ratio
  //get dimennsions
  const realWidth = pixelPerMeter * (props.width / 100);
  const realHeight = pixelPerMeter * (props.height / 100);
  //get position
  const realMiddle = (height - pt - pb) / 2;
  const realCenter = (width - pl - pr) / 2;

  const realTop = pt + realMiddle - realHeight / 2;
  const realLeft = pl + realCenter - realWidth / 2;

  return [realLeft, realTop, realWidth, realHeight];
}
function loadImage(src: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "annonymouse";
    img.src = src;
    img.onload = () => resolve(img);
    img.onerror = (error) => reject(error);
  });
}
async function createImage(props: IProps, setData: (data: string) => void) {
  const [back, front] = getImageSrc(props.selected);
  const [width, height] = getDimensions(props.selected);

  const [imgBG, imgFE, thumb] = await Promise.all([
    loadImage(back),
    loadImage(front),
    loadImage(props.thumb),
  ]);

  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  const context = canvas.getContext("2d");

  if (context) {
    const [left, top, imgWidth, imgHeight] = getSizes(props);
    context?.drawImage(imgBG, 0, 0);
    context.shadowColor = "black";
    context.shadowBlur = 14;
    context.shadowOffsetX = 2;
    context.shadowOffsetY = 2;
    context?.drawImage(
      thumb,
      left || 0,
      top || 0,
      imgWidth || 0,
      imgHeight || 0
    );
    context.shadowBlur = 0;
    context.shadowOffsetX = 0;
    context.shadowOffsetY = 0;
    context?.drawImage(imgFE, 0, 0);

    return setData(canvas.toDataURL("image/jpeg"));
  }

  throw Error("Could not generate Mockup!");
}

async function upload(
  props: IProps,
  data: string,
  state: IState,
  setState: (state: IState) => void
) {
  setState({ ...state, errors: {}, status: "pending" });
  const [, toSend] = data.split(",");
  try {
    const result = await Artwork.update(
      { mockup: `mockup.png,${toSend}` },
      {
        id: state.id,
        collection: state.collection,
      }
    );
    if (result.isSuccess()) {
      setState({ ...state, status: "success" });
      return props.setSuccess();
    }
  } catch (error) {
    console.error(error);
  }
  setState({ ...state, status: "error" });
}

const Card: React.FC<IProps> = (props) => {
  const classes = useClasses(styles, props);

  const i18n = useI18n("artwork_mockups");
  const [data, setData] = useState<string>();
  const [state, setState] = useState<IState>({
    id: props.artwork,
    collection: props.collection,
    status: "ok",
    errors: {},
  });

  useEffect(() => {
    if (!data) createImage(props, setData);
    return () => {};
  }, [data, props]);

  return (
    <StateHandler state={state.status}>
      <Row spacing={16} vertical root={0}>
        <Col>
          {!data && (
            <div className={classes.pending}>
              <FontAwesomeIcon icon={faSpinnerThird} spin />
            </div>
          )}
          {data && <img className={classes.mock} src={data} alt="new-mockup" />}
        </Col>
        <Col xs={5}>
          <Button
            width="100px"
            onClick={() => {
              if (props.back) props.back();
            }}
          >
            {i18n.get("back")}
          </Button>
        </Col>
        <Col xs={5} override={{ col: classes.alignRight }}>
          <Button
            width="100px"
            success
            onClick={() => {
              if (data) upload(props, data, state, setState);
            }}
          >
            {i18n.get("upload")}
          </Button>
        </Col>
      </Row>
    </StateHandler>
  );
};

export default Card;

import React from "react";
import { ISession } from "@maxeb/idp-sdk";
import LightBox from "../Shared/LightBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake, faPeopleArrows } from "@fortawesome/pro-light-svg-icons";
import TeamSwap from "./TeamSwap";
import TeamAdd from "./TeamAdd";
import TeamInvite from "./TeamInvite";

export interface IProps {
  session: ISession;
  setSession: (session: ISession | null) => void;
  children: React.ReactNode;
}

export default function TeamGuard(props: IProps) {
  const { session, setSession } = props;

  const hasInvites = session.invites && session.invites.length > 0;

  const isNotInTeam = !session.currentTeam;
  const isInTeam = !isNotInTeam;
  const hasTeams = (session.teams?.length || 0) > 0;

  if (hasInvites)
    return (
      <LightBox
        title={
          <>
            <FontAwesomeIcon icon={faHandshake} /> Invites
          </>
        }
        primary
        open={true}
      >
        <TeamInvite session={session} setSession={setSession} />
      </LightBox>
    );

  return (
    <>
      {isNotInTeam && hasTeams && (
        <LightBox
          title={
            <>
              <FontAwesomeIcon icon={faPeopleArrows} /> Select a Team
            </>
          }
          primary
          open={true}
        >
          <TeamSwap user={session} setSession={setSession} />
        </LightBox>
      )}
      {isNotInTeam && !hasTeams && (
        <LightBox
          title={
            <>
              <FontAwesomeIcon icon={faPeopleArrows} /> Select a Team
            </>
          }
          primary
          open={true}
        >
          <TeamAdd firstTime user={session} setSession={setSession} />
        </LightBox>
      )}
      {isInTeam && props.children}
    </>
  );
}

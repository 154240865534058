import { IArtwork, IArtworkSet } from "@maxeb/art-sdk";
import { State } from "./State";

export function hasChanged(
  key: keyof IArtwork,
  data: IArtwork,
  original: IArtwork
) {
  return data[key] !== original[key];
}
export function getSet(state: State) {
  const { data, original } = state;
  let set: IArtworkSet = {};

  //data
  if (hasChanged("artistId", data, original))
    set.artistId = data.artistId || null;
  if (hasChanged("title", data, original)) set.title = data.title || null;
  if (hasChanged("customId", data, original))
    set.customId = data.customId || null;
  if (hasChanged("themeId", data, original)) set.themeId = data.themeId || null;
  if (hasChanged("categoryId", data, original))
    set.categoryId = data.categoryId || null;
  if (hasChanged("techniqueId", data, original))
    set.techniqueId = data.techniqueId || null;
  if (hasChanged("year", data, original)) set.year = data.year || null;
  if (hasChanged("width", data, original)) set.width = data.width || null;
  if (hasChanged("height", data, original)) set.height = data.height || null;
  if (hasChanged("depth", data, original)) set.depth = data.depth || null;
  if (hasChanged("weight", data, original)) set.weight = data.weight || 0;
  if (set.width || set.height)
    set.size = 2 * ((data.width || 0) + (data.height || 0));
  if (hasChanged("description", data, original))
    set.description = data.description || null;
  if (hasChanged("color", data, original)) set.color = data.color || null;
  if (hasChanged("available", data, original))
    set.available = data.available || null;
  if (hasChanged("signed", data, original)) set.signed = data.signed || null;
  if (hasChanged("dated", data, original)) set.dated = data.dated || null;
  if (hasChanged("frame", data, original)) set.frame = data.frame || null;
  //selling
  if (hasChanged("reservedFrom", data, original))
    set.reservedFrom = data.reservedFrom || null;
  if (hasChanged("reservedUntil", data, original))
    set.reservedUntil = data.reservedUntil || null;
  if (hasChanged("reservedFor", data, original))
    set.reservedFor = data.reservedFor || null;
  if (hasChanged("reservedReason", data, original))
    set.reservedReason = data.reservedReason || null;
  if (hasChanged("sellingPrice", data, original))
    set.sellingPrice = data.sellingPrice || null;
  //owner
  if (hasChanged("proprietor", data, original))
    set.proprietor = data.proprietor || null;
  if (hasChanged("source", data, original)) set.source = data.source || null;
  if (hasChanged("purchasingPrice", data, original))
    set.purchasingPrice = data.purchasingPrice || null;
  if (hasChanged("purchasingDate", data, original))
    set.purchasingDate = data.purchasingDate || null;
  if (hasChanged("insurer", data, original)) set.insurer = data.insurer || null;
  if (hasChanged("insurerPolicyNumber", data, original))
    set.insurerPolicyNumber = data.insurerPolicyNumber || null;
  if (hasChanged("insurerValue", data, original))
    set.insurerValue = data.insurerValue || null;

  return set;
}
export const locationToObject = (locationString: string) => {
  const [date, location, description] = locationString.split("|");
  return {
    date,
    location,
    description,
  };
};
export const objectToLocationTo = (obj: {
  date?: string;
  location?: string;
  description?: string;
}) => {
  return [obj.date || "", obj.location || "", obj.description || ""].join("|");
};
export const getCurrentLocation = (location: string, latest?: string) => {
  if (!latest) return location;

  const dateLatest = new Date(locationToObject(latest).date);
  const dateLocation = new Date(locationToObject(location).date);

  return new Date(dateLatest) > new Date(dateLocation) ? latest : location;
};
export const getSortedLocations = (location?: string[]) => {
  return (location || [])
    .map(locationToObject)
    .sort((a, b) => {
      return +new Date(b.date) - +new Date(a.date);
    })
    .map(objectToLocationTo);
};

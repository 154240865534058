import { Artist, IArtist } from "@maxeb/art-sdk";
import { SetState, State, initialState } from "./State";

export async function save(state: State, setState: SetState) {
  setState({ ...state, status: "pending" });

  try {
    const response = await Artist.add({
      name: state.values.name || "",
      description: state.values.description,
      thumb: state.values.thumb,
      public: Boolean(state.values.public),
    });

    if (response.isSuccess()) {
      setState({ ...state, status: "success" });

      const result = response.getResult();
      const artist: IArtist = { id: result.data.id, name: state.values.name };
      return artist;
    } else {
      const errors = response.getErrors({
        "body.thumb": "thumb",
        "body.name": "name",
        "body.description": "description",
        "body.public": "public",
      });

      return setState({
        ...state,
        status: "ok",
        errors: errors,
      });
    }
  } catch (error) {
    console.error(error);
  }
  setState({ ...state, errors: {}, status: "error" });
}
export function setData(
  props: { data: State["values"] },
  state: State,
  setState: SetState
) {
  setState({ ...state, values: props.data });
}
export function reset(state: State, setState: SetState) {
  setState(initialState());
}

import React from "react";
import { ITheme, Form, Select, useClasses, useI18n } from "@maxeb/admin-ui";
import { State as IParentState } from "../../../Hooks/Artists/List";

export interface IProps {
  respOpen: boolean;
  search: IParentState["search"];
  setSearch: (search: IParentState["search"]) => void;
}

const styles = (theme: ITheme) => ({
  search: {
    height: "47px",
    backgroundColor: theme.palette.get("grey"),
    zIndex: 2,
    width: "100%",
    padding: "8px",
    boxSizing: "border-box",
    borderBottom: "1px solid #d9d9d9",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      padding: (props: IProps) => (!props.respOpen ? 0 : 16),
      backgroundColor: theme.palette.get("grey"),
      position: "absolute",
    },
  },
  form: {
    margin: "-4px 19px !important",
    [theme.breakpoints.down("md")]: {
      margin: "-4px !important",
    },
  },
  input: {
    backgroundColor: theme.palette.get("grey") + " !important",
    border: "1px solid " + theme.palette.get("darkGrey") + " !important",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  label: {
    color: "black !important",
  },
  respVisible: {
    [theme.breakpoints.down("md")]: {
      display: (props: IProps) =>
        !props.respOpen ? "none !important" : "auto",
    },
  },
});

const Search: React.FC<IProps> = (props) => {
  const classes = useClasses(styles, props);
  const i18n = useI18n("artist_search");

  return (
    <div className={classes.search}>
      <Form
        spacing={8}
        vertical
        root={0}
        horizontalAlign="right"
        override={{ row: classes.form }}
      >
        <Select
          inline
          open
          label={i18n.get("sorting")}
          lg={10 / 6}
          value={props.search.direction}
          onChange={(value) => {
            if (value === "ASC" || value === "DESC")
              props.setSearch({ ...props.search, direction: value });
          }}
          options={[
            {
              label: `${i18n.get("name")} ${i18n.get("asc")}`,
              value: "ASC",
            },
            {
              label: `${i18n.get("name")} ${i18n.get("dsc")}`,
              value: "DESC",
            },
          ]}
          override={{
            col: !props.respOpen ? classes.respVisible : "",
            input: classes.input,
            label: classes.label,
          }}
        />
      </Form>
    </div>
  );
};

export default Search;

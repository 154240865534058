import { IArtist } from "@maxeb/art-sdk";
import { State, initialState } from "./State";
import { useEffect } from "react";
import * as Mutations from "./Mutations";
import useSaveState from "../../SaveState";

export type { State } from "./State";
export interface Actions {
  next: () => void;
  search: (search: State["search"]) => void;
  active: (artist: IArtist | null, index: number | null) => void;
  updateActive: (artist: IArtist | null) => void;
  setNewActive: (artist: IArtist) => void;
  reset: () => void;
}

export function useArtists(): [State, Actions] {
  const [state, setState] = useSaveState(initialState());

  useEffect(() => {
    if (state.status === "init") {
      Mutations.next(state, setState);
    }
  }, [state, setState]);

  return [
    state,
    {
      next: () => {
        Mutations.next(state, setState);
      },
      search: (params) => {
        Mutations.search({ search: params }, state, setState);
      },
      active: (artist, index) => {
        Mutations.setActive({ artist, index }, state, setState);
      },
      updateActive: (artist) => {
        Mutations.updateActive({ set: artist }, state, setState);
      },
      setNewActive: (artist) => {
        Mutations.setNewActive({ artist }, state, setState);
      },
      reset: () => {
        Mutations.reset(state, setState);
      },
    },
  ];
}

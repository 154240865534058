import { IArtist } from "@maxeb/art-sdk";
import { State, initialState } from "./State";

import * as Mutations from "./Mutations";
import useSaveState from "../../SaveState";

export type { State } from "./State";

export interface Actions {
  save: () => Promise<IArtist | void>;
  reset: () => void;
  setData: (values: State["values"]) => void;
}

export function useArtistAdd(): [State, Actions] {
  const [state, setState] = useSaveState(initialState());

  return [
    state,
    {
      save: () => {
        return Mutations.save(state, setState);
      },
      reset: () => {
        Mutations.reset(state, setState);
      },
      setData: (data) => {
        Mutations.setData({ data }, state, setState);
      },
    },
  ];
}

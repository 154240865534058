export const indexes: [string, "ASC" | "DESC"][] = [
  ["secondaryIndex", "DESC"],
  ["secondaryIndex", "ASC"],
  ["nonaryIndex", "DESC"],
  ["nonaryIndex", "ASC"],
  ["quinaryIndex", "DESC"],
  ["quinaryIndex", "ASC"],
  ["senaryIndex", "DESC"],
  ["senaryIndex", "ASC"],
  ["septenaryIndex", "DESC"],
  ["septenaryIndex", "ASC"],
  ["tertiaryIndex", "DESC"],
  ["tertiaryIndex", "ASC"],
  ["octonaryIndex", "DESC"],
  ["octonaryIndex", "ASC"],
  ["twelfthIndex", "DESC"],
  ["twelfthIndex", "ASC"],
  ["quaternaryIndex", "DESC"],
  ["quaternaryIndex", "ASC"],
];

export const prices: [number, number][] = [
  [0, 500],
  [500, 1000],
  [1000, 2500],
  [2500, 5000],
  [5000, 10000],
  [10000, 50000],
  [50000, -1],
];

export const sizes: [number, number][] = [
  [0, 2000],
  [2000, 4000],
  [4000, 8000],
  [8000, -1],
];

import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Block, Col, ITheme, Row, useClasses } from "@maxeb/admin-ui";
import React from "react";

export interface IProps {
  open: boolean;
  onClose?: () => void;
  title?: React.ReactNode;
  children?: React.ReactNode;
  primary?: boolean;
  success?: boolean;
  warning?: boolean;
  danger?: boolean;
}

const styles = (theme: ITheme) => ({
  shadow: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    position: "fixed",
    width: "100%",
    height: "100%",
    zIndex: "200",
    left: 0,
    top: 0,
  },
  box: {
    width: "100%",
    maxWidth: "600px",
    margin: "32px auto 0px",
    textAlign: "left",
  },
  title: {
    backgroundColor: (props: IProps) =>
      props.danger
        ? theme.palette.get("danger")
        : props.primary
        ? theme.palette.get("primary")
        : props.warning
        ? theme.palette.get("warning")
        : props.success
        ? theme.palette.get("success")
        : theme.palette.get("default"),
    color: (props: IProps) =>
      props.danger || props.primary || props.warning || props.success
        ? "#fff"
        : theme.palette.get("font"),
    margin: "-8px -16px 16px !important",
    padding: "8px 16px !important",
    fontSize: "1.2rem",
  },
  actions: {
    textAlign: "right !important",
  },
  icon: {
    cursor: "pointer",
  },
});

export default function LightBox(props: IProps) {
  const classes = useClasses(styles, props);
  if (props.open)
    return (
      <div
        className={classes.shadow}
        onClick={() => {
          if (props.onClose) props.onClose();
        }}
      >
        <div
          className={classes.box}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Block padding="8px 16px 16px 16px">
            <Row override={{ row: classes.title }}>
              <Col xs="auto">{props.title}</Col>
              {props.onClose && (
                <Col xs="rest-fixed" override={{ col: classes.actions }}>
                  <FontAwesomeIcon
                    icon={faTimes}
                    className={classes.icon}
                    onClick={() => {
                      if (props.onClose) props.onClose();
                    }}
                  />
                </Col>
              )}
            </Row>
            {props.children}
          </Block>
        </div>
      </div>
    );
  else return <></>;
}

import React, { useEffect, useState } from "react";
import {
  Block,
  Col,
  Row,
  Typo,
  Form,
  TextInput,
  Radio,
  RadioItem,
  Select,
  DateInput,
  Button,
  AdminPage,
  ITheme,
  useClasses,
  useI18n,
} from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/pro-light-svg-icons";
import { IDP, ISession } from "@maxeb/idp-sdk";
import { ISettings, ISettingsSet } from "@maxeb/idp-sdk/dist/modules/IDP";
import ChangeEmail from "./ChangeEmail";
const spacing = 16;

export interface IProps {
  user: ISession;
  translationGroup?: string;
}

const styles = (theme: ITheme) => ({
  overlay: {
    padding: "132px",
    backgroundColor: "rgba(236, 236, 236, 0.95) !important",
  },
  holder: { borderRadius: "100%", textAlign: "center" },
  userIcon: {
    fontSize: "3rem",
  },
});

let MOUNTED = false;

async function save(values: ISettings, user: string) {
  const toSet: ISettingsSet = {
    salutation: values.salutation || null,
    title: values.title || null,
    firstname: values.firstname || null,
    lastname: values.lastname || null,
    gender: values.gender || null,
    phone: values.phone || null,
    address: values.address || null,
    city: values.city || null,
    zip: values.zip || null,
    birthdate: values.birthdate || null,
    country: values.country || null,
  };

  const result = await IDP.settings(toSet, { id: user });

  return result;
}

export default function Settings(props: IProps) {
  const classes = useClasses(styles);
  const i18n = useI18n(props.translationGroup || "authentication");
  const i18nErrors = useI18n("errors");
  const [state, setState] = useState<
    "init" | "ok" | "pending" | "error" | "success"
  >("init");
  const [values, setValues] = useState<ISettings>({});
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    MOUNTED = true;
    if (state === "init") {
      const getUser = async () => {
        setState("pending");
        try {
          const result = await IDP.getSettings({
            id: props.user.id,
          });
          if (MOUNTED) {
            setValues(result.getResult().data);
            setState("ok");
          }
        } catch (err) {
          if (MOUNTED) setState("error");
        }
      };
      getUser();
    }
    return () => {
      MOUNTED = false;
    };
  });

  if (state === "init") return <div />;

  let birthdate: [string, string, string] | undefined;
  if (values.birthdate) {
    birthdate = values.birthdate.split("-").reverse() as [
      string,
      string,
      string
    ];
  }

  return (
    <AdminPage
      title={i18n.get("settings")}
      state={state}
      breadcrumbs={[
        { label: i18n.get("account") },
        { label: i18n.get("settings") },
      ]}
      actions={
        <Button
          width="100px"
          primary
          onClick={async () => {
            setState("pending");
            try {
              const result = await save(values, props.user.id);

              const errors = result.getErrors({
                "body.firstname": "firstname",
                "body.lastname": "lastname",
                "body.address": "address",
                "body.birthdate": "birthdate",
                "body.city": "city",
                "body.country": "country",
                "body.gender": "gender",
                "body.phone": "phone",
                "body.salutation": "salutation",
                "body.title": "title",
                "body.zip": "zip",
              });

              if (errors.length && MOUNTED) {
                setErrors(errors);
                setState("ok");
              } else if (MOUNTED) {
                setState("success");
                setTimeout(() => {
                  if (MOUNTED) setState("ok");
                }, 1000);
              }
            } catch (err) {
              if (MOUNTED) setState("error");
            }
          }}
        >
          <FontAwesomeIcon
            style={{ margin: "0px 4px 0px 0px" }}
            icon={faSave}
          />
          {i18n.get("save")}
        </Button>
      }
      override={{ overlay: classes.overlay }}
    >
      <Row spacing={16} root={0}>
        <Col lg={7}>
          <Typo margin="0px 0px 8px 0px" variant="section">
            {i18n.get("person")}
          </Typo>
          <Block>
            <Form spacing={spacing} vertical root={0}>
              <Radio
                inline
                label={""}
                value={values.salutation}
                onChange={(value) => {
                  setValues({ ...values, salutation: value });
                }}
                error={errors.salutation && i18nErrors.get(errors.salutation)}
              >
                <RadioItem sm={3} value="ms" label={i18n.get("ms")} />
                <RadioItem sm={3} value="mr" label={i18n.get("mr")} />
                <RadioItem sm={3} value="other" label={i18n.get("other")} />
              </Radio>
              <TextInput
                sm={2}
                label={i18n.get("title")}
                value={values.title}
                onChange={(value) => {
                  setValues({ ...values, title: value });
                }}
                inline
                error={errors.title && i18nErrors.get(errors.title)}
              />
              <TextInput
                sm={4}
                label={i18n.get("firstname")}
                value={values.firstname}
                onChange={(value) => {
                  setValues({ ...values, firstname: value });
                }}
                inline
                error={errors.firstname && i18nErrors.get(errors.firstname)}
              />
              <TextInput
                sm={4}
                label={i18n.get("lastname")}
                value={values.lastname}
                error={errors.lastname && i18nErrors.get(errors.lastname)}
                onChange={(value) => {
                  setValues({ ...values, lastname: value });
                }}
                inline
              />
              <Select
                inline
                sm={5}
                label={i18n.get("gender")}
                value={values.gender}
                error={errors.gender && i18nErrors.get(errors.gender)}
                onChange={(value) => {
                  if (
                    value === "male" ||
                    value === "female" ||
                    value === "other" ||
                    typeof value === "undefined"
                  )
                    setValues({ ...values, gender: value });
                  else if (value === "")
                    setValues({ ...values, gender: undefined });
                }}
                options={[
                  { label: i18n.get("none"), value: "" },
                  { label: i18n.get("female"), value: "female" },
                  { label: i18n.get("male"), value: "male" },
                  { label: i18n.get("other"), value: "other" },
                ]}
              />
              <DateInput
                sm={5}
                label={i18n.get("birthdate")}
                value={birthdate}
                error={errors.birthdate && i18nErrors.get(errors.birthdate)}
                onChange={(value) => {
                  if (value) {
                    const [day, month, year] = value;
                    setValues({
                      ...values,
                      birthdate: `${year}-${month}-${day}`,
                    });
                  } else setValues({ ...values, birthdate: value });
                }}
                inline
              />
            </Form>
          </Block>
          <Typo variant="section">{i18n.get("contact")}</Typo>
          <Block>
            <Form spacing={spacing} vertical root={0}>
              <TextInput
                sm={5}
                label={i18n.get("email")}
                autoComplete="off"
                value={values.email}
                error={errors.email && i18nErrors.get(errors.email)}
                onClick={() => {
                  setOpen(true);
                }}
                inline
              />
              <TextInput
                sm={5}
                label={i18n.get("phone")}
                inline
                value={values.phone}
                error={errors.phone && i18nErrors.get(errors.phone)}
                onChange={(value) => {
                  setValues({ ...values, phone: value });
                }}
              />
              <TextInput
                sm={5}
                label={i18n.get("address")}
                inline
                value={values.address}
                error={errors.address && i18nErrors.get(errors.address)}
                onChange={(value) => {
                  setValues({ ...values, address: value });
                }}
              />
              <TextInput
                sm={3}
                label={i18n.get("city")}
                inline
                value={values.city}
                error={errors.city && i18nErrors.get(errors.city)}
                onChange={(value) => {
                  setValues({ ...values, city: value });
                }}
              />
              <TextInput
                sm={2}
                label={i18n.get("zip")}
                inline
                value={values.zip}
                error={errors.zip && i18nErrors.get(errors.zip)}
                onChange={(value) => {
                  setValues({ ...values, zip: value });
                }}
              />
              <TextInput
                label={i18n.get("country")}
                inline
                value={values.country}
                error={errors.country && i18nErrors.get(errors.country)}
                onChange={(value) => {
                  setValues({ ...values, country: value });
                }}
              />
            </Form>
          </Block>
          <Typo variant="section">{i18n.get("account_actions")}</Typo>
          <Row
            spacing={16}
            vertical
            root={{ top: 0, left: 0, right: 0, bottom: 0 }}
          >
            <ChangeEmail
              setEmail={(value) => {
                setValues({ ...values, email: value });
              }}
              user={props.user}
              open={open}
              setOpen={setOpen}
              translationGroup={props.translationGroup}
            />
          </Row>
        </Col>
      </Row>
    </AdminPage>
  );
}

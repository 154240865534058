import { Artwork, Collection, IArtwork } from "@maxeb/art-sdk";
import { State, SetState, FullCollection } from "./State";
import { indexes } from "./Definitions";

export async function next(state: State, setState: SetState) {
  setState({ ...state, status: "pending" });

  try {
    const result = await Collection.get(
      {},
      ["id", "name", "thumb", "team"],
      1,
      "secondaryIndex",
      "ASC",
      state.next
    );

    if (result.isSuccess()) {
      return setState({
        ...state,
        status: "ok",
        data: [
          ...state.data,
          ...result.body.data.map((collection: any, index: number) => {
            return {
              ...collection,
              status: "init",
              artworksData: [],
              index: index + state.data.length,
            };
          }),
        ],
        next: result.body.next,
      });
    }
  } catch (err) {
    console.error(err);
  }

  setState({ ...state, status: "error" });
}
export function loadNotAssigned(
  props: { label: string; team: string },
  state: State,
  setState: SetState
) {
  const data: FullCollection[] = [
    ...state.data,
    {
      id: props.team,
      name: props.label,
      status: "init",
      artworksData: [],
      index: state.data.length,
    },
  ];
  setState({ ...state, data });
}
export function search(
  props: { search: State["search"] },
  state: State,
  setState: SetState
) {
  const { search } = props;

  if (search.artistId && search.sorting !== 18 && search.sorting !== 19)
    search.sorting = 18;
  else if (
    !search.artistId &&
    (search.sorting === 18 || search.sorting === 19)
  ) {
    search.sorting = 1;
  }

  const searchJson = JSON.stringify(search);
  const deepLinkParam = encodeURIComponent(JSON.stringify(search));

  window.history.pushState({}, "", `/artworks?q=${deepLinkParam}`);

  setState({
    ...state,
    search,
    searchChecksum: searchJson,
    status: "init",
    data: [],
    next: undefined,
  });
}
export async function nextArtworks(
  props: { collectionIndex: number },
  state: State,
  setState: (state: State) => void
) {
  if (
    state.data[props.collectionIndex] &&
    state.data[props.collectionIndex].status !== "pending"
  ) {
    const newData = [...state.data];
    newData[props.collectionIndex].status = "pending";
    setState({ ...state, data: newData });

    const currentCollection = newData[props.collectionIndex];

    try {
      const { search } = state;
      const [index, direction] = indexes[search.sorting];

      const result = await Artwork.get(
        {
          collection: currentCollection.id || "",
          artistId: search.artistId,
        },
        [
          "id",
          "customId",
          "title",
          "artist",
          "thumb",
          "collection",
          "team",
          "sellingPrice",
          "color",
          "categoryId",
          "available",
          "size",
          "currentLocation",
          "proprietor",
        ],
        100,
        index,
        direction,
        currentCollection.artworksNext
      );

      if (result.isSuccess()) {
        const collectionToChange: FullCollection = {
          ...state.data[props.collectionIndex],
          status: "ok",
          artworksNext: result.body.next,
        };

        const artworks = collectionToChange.artworksData || [];

        collectionToChange.artworksData = [
          ...artworks,
          ...result.body.data.map((item: IArtwork, index: number) => ({
            ...item,
            index: artworks.length + index,
          })),
        ];

        const newData = [...state.data];
        newData[props.collectionIndex] = collectionToChange;
        collectionToChange.fullyLoaded = !result.body.next;

        return setState({
          ...state,
          data: newData,
        });
      }
    } catch (err) {
      console.error(err);
    }
    newData[props.collectionIndex].status = "error";
    setState({ ...state, data: newData });
  }
}
export function setFinished(
  props: { id: string },
  state: State,
  setState: SetState
) {
  const { id } = props;

  const newData = [...state.data].map((item) => {
    if (id === item.id) item.fullyLoaded = true;
    return item;
  });

  setState({ ...state, data: newData });
}
export function setActive(
  props: { indexes: [number, number] | null; artwork: IArtwork | null },
  state: State,
  setState: SetState
) {
  const { indexes, artwork } = props;
  console.log(indexes);

  setState({ ...state, activeIndex: indexes, active: artwork });
}
export function updateActive(
  props: { artwork: IArtwork | null },
  state: State,
  setState: SetState
) {
  const { artwork } = props;
  if (state.activeIndex && artwork !== null) {
    const [collectionIndex, artworkIndex] = state.activeIndex;

    const newData = { ...state };
    const newArtwork = {
      ...newData.data[collectionIndex].artworksData[artworkIndex],
      ...artwork,
    };

    newData.data[collectionIndex].artworksData[artworkIndex] = newArtwork;
    setState(newData);
  } else if (state.activeIndex && artwork === null) {
    const [collectionIndex, artworkIndex] = state.activeIndex;

    const newData = { ...state };
    newData.data[collectionIndex].artworksData.splice(artworkIndex, 1);

    setState({ ...newData });
  }
}

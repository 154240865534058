import { faScissors, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, ITheme, Row, useClasses } from "@maxeb/admin-ui";
import React, { useEffect, useRef, useState } from "react";
import ReactCrop, { Crop } from "react-image-crop";

export interface IProps {
  open: boolean;
  onClose?: () => void;
  title?: React.ReactNode;
  primary?: boolean;
  success?: boolean;
  warning?: boolean;
  danger?: boolean;
  onCrop?: (value: string) => void;
  getNewSource: () => Promise<string | undefined>;
}

export interface IState {
  status: "ok" | "init" | "pending";
  src?: string;
}

const styles = (theme: ITheme) => ({
  shadow: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    position: "fixed",
    width: "100%",
    height: "calc(100% - 39px)",
    zIndex: "200",
    left: 0,
    top: 0,
    textAlign: "center",
    [theme.breakpoints.up("lg")]: {
      padding: "16px 0px",
      height: "calc(100% - 71px)",
    },
  },
  title: {
    position: "fixed",
    backgroundColor: theme.palette.get("default"),
    margin: "0px 0px 0px  !important",
    padding: "8px 16px !important",
    fontSize: "1.2rem",
    textAlign: "left",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
  },
  img: {
    maxHeight: "calc(100vh - 39px)",
    maxWidth: "100%",
    boxShadow: "rgb(0 0 0 / 40%) 0px 0px 4px 0px",
    [theme.breakpoints.up("lg")]: {
      maxHeight: "calc(100vh - 71px) !important",
    },
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginTop: "4px",
    whiteSpace: "nowrap",
  },
  close: {
    margin: "3px 4px 0px 0px",
  },
});

function cropImg(
  img: HTMLImageElement,
  params: { x: number; y: number; width: number; height: number }
): string {
  const { x, y, width, height } = params;

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  const widthPerPixel = img.naturalWidth / img.width;
  const heightPerPixel = img.naturalHeight / img.height;

  const realWidth = widthPerPixel * width;
  const realHeight = heightPerPixel * height;

  const realX = widthPerPixel * x;
  const realY = heightPerPixel * y;

  canvas.width = realWidth;
  canvas.height = realHeight;

  if (context)
    context.drawImage(
      img,
      realX,
      realY,
      realWidth,
      realHeight,
      0,
      0,
      realWidth,
      realHeight
    );

  let dataURL = canvas.toDataURL("image/jpeg");

  return dataURL;
}
let MOUNTED = false;

async function load(
  props: IProps,
  state: IState,
  setState: (state: IState) => void
) {
  const newURL = await props.getNewSource();

  const newState = { ...state, src: newURL };
  if (MOUNTED) setState(newState);
}

export default function ImageCroppBox(props: IProps) {
  const classes = useClasses(styles, props);
  const shadow = useRef<HTMLDivElement>(null);

  const [state, setState] = useState<IState>({ status: "init" });

  const [crop, setCrop] = useState<Crop>();
  const img = useRef<HTMLImageElement>(null);

  const [preview, setPreview] = useState<string>();

  useEffect(() => {
    MOUNTED = true;
    if (state.status === "init") {
      const newState: IState = { ...state, status: "ok" };
      setState(newState);
      load(props, newState, setState);
    }
    return () => {
      MOUNTED = false;
    };
  }, [state, props]);

  if (props.open)
    return (
      <div
        className={classes.shadow}
        onClick={(e) => {
          if (props.onClose && e.target === shadow.current) props.onClose();
        }}
        ref={shadow}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Row override={{ row: classes.title }} spacing={8} root={0}>
            <Col xs="calc(100% - 200px)" override={{ col: classes.text }}>
              {props.title}
            </Col>
            {!preview && (
              <Button
                success
                xs="100px"
                onClick={() => {
                  if (img.current && crop) {
                    const dataURL = cropImg(img.current, crop);
                    setPreview(dataURL);
                  }
                }}
              >
                <FontAwesomeIcon icon={faScissors} className={classes.close} />{" "}
                Crop
              </Button>
            )}
            {!preview && props.onClose && (
              <Button
                danger
                xs="100px"
                onClick={() => {
                  if (props.onClose) props.onClose();
                }}
              >
                <FontAwesomeIcon icon={faTimes} className={classes.close} />{" "}
                Close
              </Button>
            )}

            {preview && (
              <Button
                xs="100px"
                onClick={() => {
                  setPreview(undefined);
                }}
              >
                <FontAwesomeIcon icon={faTimes} className={classes.close} />{" "}
                Cancel
              </Button>
            )}
            {preview && (
              <Button
                success
                xs="100px"
                onClick={() => {
                  if (props.onCrop) {
                    props.onCrop(preview);
                    if (props.onClose) props.onClose();
                  }
                }}
              >
                Upload
              </Button>
            )}
          </Row>
        </div>
        {!preview && state.src && (
          <ReactCrop
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => {}}
          >
            <img
              onClick={(e) => {
                e.stopPropagation();
              }}
              crossOrigin="anonymous"
              alt="full-sized-view"
              src={state.src}
              className={classes.img}
              ref={img}
            />
          </ReactCrop>
        )}
        {preview && (
          <img alt="cropped-preview" className={classes.img} src={preview} />
        )}
      </div>
    );
  else return <></>;
}

import React from "react";
import { ITheme, useClasses, useI18n } from "@maxeb/admin-ui";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-light-svg-icons/faEdit";
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus";
import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash";

export interface IProps {
  id?: string;
  method?: string;
  body?: string;
  user?: string;
  usersName?: string;
  createdAt?: string;
}

export interface IState {}

const styles = (theme: ITheme) => ({
  put: {
    color: theme.palette.get("primary"),
  },
  post: {
    color: theme.palette.get("success"),
  },
  delete: {
    color: theme.palette.get("danger"),
  },
  date: {
    marginLeft: "8px",
    display: "inline-block",
    float: "right",
  },
  headline: {
    margin: 0,
    fontWeight: 400,
  },
  text: {
    fontSize: "0.9rem",
    margin: 0,
  },
  element: {
    borderLeft: "2px solid " + theme.palette.get("grey"),
    padding: "16px 0px 16px 24px",
    marginLeft: "16px",
    position: "relative",
  },
  ball: {
    position: "absolute",
    left: "-16px",
    backgroundColor: theme.palette.get("success"),
    borderRadius: "32px",
    width: "32px",
    height: "32px",
    display: "inline-block",
    color: "#fff",
  },
  ballUpdate: {
    backgroundColor: theme.palette.get("primary"),
  },
  ballDelete: {
    backgroundColor: theme.palette.get("danger"),
  },
  icon: {
    margin: "9px 0px 0px 10px",
  },
});

export default function LogElement(props: IProps) {
  const classes = useClasses(styles);
  const i18n = useI18n("artworks_log");

  const { method } = props;
  let date = "";
  if (props.createdAt) {
    const dateFormater = new Date(props.createdAt);
    date = `${dateFormater.getDate()}.${
      dateFormater.getMonth() + 1
    }.${dateFormater.getFullYear()}`;
  }

  const isAdd = method === "POST";
  const isUpdate = method === "PATCH";
  const isDelete = method === "DELTE";
  const bodyFields = Object.keys(JSON.parse(props.body || "{}"));

  return (
    <div className={classes.element} key={props.id}>
      <div
        className={classNames([
          classes.ball,
          isUpdate && classes.ballUpdate,
          isDelete && classes.ballDelete,
        ])}
      >
        <FontAwesomeIcon
          className={classes.icon}
          icon={(isUpdate && faEdit) || (isAdd && faPlus) || faTrash}
        />
      </div>
      <h3 className={classes.headline}>
        <span
          className={
            (isAdd && classes.post) ||
            (isUpdate && classes.put) ||
            (isDelete && classes.delete) ||
            ""
          }
        >
          {(isAdd && i18n.get("created")) ||
            (isUpdate && i18n.get("updated")) ||
            (isDelete && i18n.get("deleted")) ||
            ""}
        </span>
        <small className={classes.date}>{date}</small>
      </h3>
      {isAdd && <p className={classes.text}>{i18n.get("created_info")}</p>}
      {isUpdate && (
        <p className={classes.text}>
          {props.usersName
            ? `${props.usersName} ${i18n.get("changed_fields_by")}`
            : i18n.get("changed_field")}
          <br />
          {bodyFields
            .filter((field) => field !== "team")
            .map((val, index) => {
              return (
                <span key={props.id + "-log-" + index}>
                  <b>{i18n.get(val)}</b>
                  {index < bodyFields.length - 2 ? ", " : ""}
                </span>
              );
            })}
        </p>
      )}

      {}
    </div>
  );
}

import { IWebsite } from "@maxeb/art-sdk";
import { State, initialState } from "./State";
import * as Mutations from "./Mutations";
import useSaveState from "../../SaveState";
import { useEffect } from "react";

export type { State } from "./State";
export interface Actions {
  activate: (path: string) => void;
  deactivate: () => void;
  setData: (data: IWebsite) => void;
  save: () => Promise<void>;
  resetErrors: () => void;
  upload: (thumb: string | null) => void;
}
export interface Props {
  team: string;
}
export function useWebsite(props: Props): [State, Actions] {
  const [state, setState] = useSaveState(initialState());

  useEffect(() => {
    if (state.status === "init") {
      Mutations.get({ team: props.team }, state, setState);
    }
  });

  return [
    state,
    {
      activate: (path: string) => {
        Mutations.activate({ path }, state, setState);
      },
      deactivate: () => {
        Mutations.deactivate(state, setState);
      },
      setData: (data) => {
        Mutations.setData(data, state, setState);
      },
      save: async () => {
        Mutations.save(state, setState);
      },
      upload: async (thumb: string | null) => {
        Mutations.upload({ thumb }, state, setState);
      },
      resetErrors: () => {
        Mutations.resetErrors(state, setState);
      },
    },
  ];
}

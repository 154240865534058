import {
  faGrid,
  faPlus,
  faSearch,
  faTableList,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ITheme, Row, useClasses, useI18n } from "@maxeb/admin-ui";

export interface Props {
  searchOpen: boolean;
  setSearchOpen: (to: boolean) => void;
  setAdd: (to: boolean) => void;
  setView: (to: "table" | "grid") => void;
  view: "table" | "grid";
}

const styles = (theme: ITheme) => ({
  row: {
    [theme.breakpoints.up("md")]: {
      width: "320px",
    },
  },
});

export default function Actions(props: Props) {
  const classes = useClasses(styles);
  const i18n = useI18n("artworks");

  const { setSearchOpen, searchOpen, setAdd, view, setView } = props;

  return (
    <Row
      override={{ row: classes.row }}
      spacing={8}
      root={0}
      horizontalAlign="right"
    >
      <Button
        xs="110px"
        lg="hidden-up"
        primary
        onClick={() => {
          setSearchOpen(!searchOpen);
        }}
      >
        <FontAwesomeIcon
          style={{ margin: "0px 4px 0px 0px" }}
          icon={faSearch}
        />
        {searchOpen ? i18n.get("close") : i18n.get("search")}
      </Button>
      <Button
        xs="100px"
        success
        onClick={() => {
          setAdd(true);
        }}
      >
        <FontAwesomeIcon style={{ margin: "0px 4px 0px 0px" }} icon={faPlus} />
        {i18n.get("add")}
      </Button>
      <Button
        xs="100px"
        onClick={() => setView(view === "grid" ? "table" : "grid")}
      >
        <FontAwesomeIcon
          style={{ margin: "0px 4px 0px 0px" }}
          icon={view === "grid" ? faTableList : faGrid}
        />
        View
      </Button>
    </Row>
  );
}

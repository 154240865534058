import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, ITheme, Row, useClasses } from "@maxeb/admin-ui";
import React from "react";

export interface IProps {
  open: boolean;
  onClose?: () => void;
  title?: React.ReactNode;
  src?: string;
  primary?: boolean;
  success?: boolean;
  warning?: boolean;
  danger?: boolean;
}

const styles = (theme: ITheme) => ({
  shadow: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    position: "fixed",
    width: "100%",
    height: "calc(100% - 39px)",
    zIndex: "200",
    left: 0,
    top: 0,
    textAlign: "center",
    [theme.breakpoints.up("lg")]: {
      padding: "16px 0px",
      height: "calc(100% - 71px)",
    },
  },
  title: {
    position: "fixed",
    backgroundColor: theme.palette.get("default"),
    margin: "0px 0px 0px  !important",
    padding: "8px 16px !important",
    fontSize: "1.2rem",
    textAlign: "left",
    bottom: 0,
    zIndex: 2,
    left: 0,
    right: 0,
  },
  img: {
    maxHeight: "calc(100vh - 39px)",
    maxWidth: "100%",
    boxShadow: "rgb(0 0 0 / 40%) 0px 0px 4px 0px",
    [theme.breakpoints.up("lg")]: {
      height: "calc(100vh - 71px)",
    },
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginTop: "4px",
    whiteSpace: "nowrap",
  },
  close: {
    margin: "3px 4px 0px 0px",
  },
});

export default function LightBox(props: IProps) {
  const classes = useClasses(styles, props);
  if (props.open)
    return (
      <div
        className={classes.shadow}
        onClick={() => {
          if (props.onClose) props.onClose();
        }}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Row override={{ row: classes.title }}>
            <Col xs="calc(100% - 100px)" override={{ col: classes.text }}>
              {props.title}
            </Col>
            {props.onClose && (
              <Button
                danger
                xs="100px"
                onClick={() => {
                  if (props.onClose) props.onClose();
                }}
              >
                <FontAwesomeIcon icon={faTimes} className={classes.close} />{" "}
                Close
              </Button>
            )}
          </Row>
        </div>
        <Row stretch verticalAlign="center">
          <Col>
            <img
              className={classes.img}
              alt="full-sized-view"
              src={props.src}
            />
          </Col>
        </Row>
      </div>
    );
  else return <></>;
}

import React, { useState } from "react";
import { IDP, ISession } from "@maxeb/idp-sdk";
import {
  Button,
  ITheme,
  Row,
  StateHandler,
  Table,
  Typo,
  useClasses,
  useI18n,
} from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/pro-light-svg-icons";

export interface IProps {
  session: ISession;
  setSession: (session: ISession | null) => void;
}

const styles = (theme: ITheme) => ({
  actions: {
    width: "56px",
    textAlign: "center",
  },
  pending: {
    color: theme.palette.get("primary"),
    fontSize: "2.3rem",
  },
});

async function accept(
  invite: { id: string; team: string },
  status: string,
  setStatus: (state: "pending" | "ok") => void,
  session: ISession,
  setSession: (session: ISession) => void
) {
  const { id, team } = invite;
  const isPending = status === "pending";

  if (!isPending) {
    setStatus("pending");
    const result = await IDP.acceptTeam({ id });
    if (result.isSuccess()) {
      setSession({
        ...session,
        teams: [...(session.teams || []), team],
        invites: session.invites?.filter((item) => item.id !== id),
      });
      setStatus("ok");
    }
  }
}

async function reject(
  invite: { id: string; team: string },
  status: string,
  setStatus: (state: "pending" | "ok") => void,
  session: ISession,
  setSession: (session: ISession) => void
) {
  const { id } = invite;
  const isPending = status === "pending";

  if (!isPending) {
    setStatus("pending");
    const result = await IDP.rejectTeam({ id });
    if (result.isSuccess()) {
      setSession({
        ...session,
        invites: session.invites?.filter((item) => item.id !== id),
      });
      setStatus("ok");
    }
  }
}

export default function TeamInvite(props: IProps) {
  const { session, setSession } = props;
  const classes = useClasses(styles);
  const [status, setStaus] = useState<"ok" | "pending">("ok");

  const i18n = useI18n("authentication");

  return (
    <StateHandler state={status}>
      <Typo variant="info">{i18n.get("invites_info")}</Typo>
      <Row spacing={16} vertical root={{ left: 0, right: 0, bottom: 0 }}>
        {session.invites && (
          <Table
            columns={[
              { label: "Team", field: "teamName" },
              { label: "By", field: "hostName" },
              { label: "", field: "actions", classes: classes.actions },
            ]}
            data={session.invites.map((item) => ({
              teamName: item.teamName,
              hostName: item.hostName,
              actions: (
                <Row spacing={8} root={0}>
                  <Button
                    success
                    xs="40px"
                    onClick={() => {
                      accept(
                        { id: item.id, team: item.team || "" },
                        status,
                        setStaus,
                        session,
                        setSession
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </Button>
                  <Button
                    danger
                    xs="40px"
                    onClick={() =>
                      reject(
                        { id: item.id, team: item.team || "" },
                        status,
                        setStaus,
                        session,
                        setSession
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </Row>
              ),
            }))}
          />
        )}
      </Row>
    </StateHandler>
  );
}

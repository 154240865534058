import React from "react";
import {
  Button,
  ITheme,
  useClasses,
  useI18n,
  LightBox,
  Form,
  Row,
  Col,
  TextInput,
  StateHandler,
  NumberInput,
} from "@maxeb/admin-ui";
import ImageHandler from "../Shared/ImageHandler";
import ArtistSelect from "../Artists/Select";
import { useArtworkAdd } from "../../Hooks/Artworks/Add";

export interface IProps {
  collection: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  setNewActive: (
    id: string,
    title: string,
    artist: string,
    artistId: string,
    collection: string
  ) => void;
}

const styles = (theme: ITheme) => ({
  alignCenter: {
    textAlign: "center",
  },
  overflow: {
    overflow: "hidden",
  },
  select: {
    zIndex: "201 !important",
  },
});

export default function Add(props: IProps) {
  const classes = useClasses(styles);
  const i18nErrors = useI18n("errors");

  const [artwork, actions] = useArtworkAdd({ collection: props.collection });
  const { status, values, errors } = artwork;

  return (
    <LightBox
      title="Add Artwork"
      open={props.open}
      onClose={() => actions.reset()}
    >
      <StateHandler state={status}>
        <Form spacing={16} root={0} vertical>
          <Col md="calc(100% - 180px)">
            <Row spacing={16} vertical root={0}>
              <TextInput
                xs={7}
                inline
                label="Title"
                value={values.title}
                error={errors.title && i18nErrors.get(errors.title)}
                onChange={(value) => {
                  const newValues = { ...values, title: value || "" };
                  actions.setValues(newValues);
                }}
              />
              <NumberInput
                xs={3}
                inline
                label="Gewicht"
                value={values.weight}
                error={errors.weight && i18nErrors.get(errors.weight)}
                onChange={(value) => {
                  const newValues = { ...values, weight: value || 0 };
                  actions.setValues(newValues);
                }}
              />
              <ArtistSelect
                onChange={(id, name) => {
                  actions.setArtist(id, name);
                }}
                error={errors.artist}
              />
              <TextInput
                inline
                rows={5}
                label="Description"
                value={values.description}
                error={errors.description && i18nErrors.get(errors.description)}
                onChange={(value) => {
                  const newValues = { ...values, description: value };
                  actions.setValues(newValues);
                }}
              />
            </Row>
          </Col>
          <ImageHandler
            inline
            md="180px"
            height="191px"
            value={values.thumb}
            error={errors.thumb && i18nErrors.get(errors.thumb)}
            override={{ col: classes.overflow }}
            onChange={(value) => {
              const newValues = { ...values, thumb: value };
              actions.setValues(newValues);
            }}
          >
            <div className={classes.alignCenter}>
              <b>Thumbnail</b>
              <br />
              upload
            </div>
          </ImageHandler>
          <Row spacing={16} vertical root={0} horizontalAlign="right">
            <Button
              xs="100px"
              onClick={() => {
                if (status !== "pending") {
                  actions.reset();
                  props.setOpen(false);
                }
              }}
            >
              Close
            </Button>
            <Button
              xs="100px"
              success
              onClick={async () => {
                const result = await actions.save();
                if (result) {
                  props.setNewActive(
                    result.id,
                    result.title,
                    result.artist,
                    result.artistId,
                    result.collection
                  );

                  setTimeout(() => {
                    actions.reset();
                    props.setOpen(false);
                  }, 1000);
                }
              }}
            >
              Add
            </Button>
          </Row>
        </Form>
      </StateHandler>
    </LightBox>
  );
}

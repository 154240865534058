import { IArtwork } from "@maxeb/art-sdk";
import { State } from "./State";
import { prices, sizes } from "./Definitions";

export function filterPrice(search: State["search"], item: IArtwork) {
  if (item.sellingPrice) {
    const [min, max] = prices[search.price - 1];

    return item.sellingPrice >= min && (item.sellingPrice <= max || max === -1);
  }
  return false;
}

function filterColor(search: State["search"], item: IArtwork) {
  return item.color === search.color;
}

function filterCategory(search: State["search"], item: IArtwork) {
  return item.categoryId === search.categoryId;
}

function filterSize(search: State["search"], item: IArtwork) {
  if (item.size) {
    const [min, max] = sizes[search.size - 1];

    return item.size >= min && (item.size <= max || max === -1);
  }
  return false;
}

function filterProprietor(search: State["search"], item: IArtwork) {
  if (!search.proprietor) return true;
  const query = search.proprietor.toLocaleLowerCase();

  if (
    item.proprietor &&
    item.proprietor.toLocaleLowerCase().indexOf(query) > -1
  )
    return true;

  return false;
}

function filterLocation(search: State["search"], item: IArtwork) {
  if (!search.location) return true;
  const query = search.location.toLocaleLowerCase();

  if (
    item.currentLocation &&
    item.currentLocation.toLocaleLowerCase().indexOf(query) > -1
  )
    return true;

  if (item.customId && item.customId.toLocaleLowerCase().indexOf(query) > -1)
    return true;

  return false;
}

function filterText(search: State["search"], item: IArtwork) {
  if (!search.text) return true;
  const query = search.text.toLocaleLowerCase();

  if (item.artist && item.artist.toLocaleLowerCase().indexOf(query) > -1)
    return true;

  if (item.title && item.title.toLocaleLowerCase().indexOf(query) > -1)
    return true;

  return false;
}

export default function filter(state: State) {
  const { search, data } = state;

  if (
    search.price ||
    search.color ||
    search.categoryId ||
    search.size ||
    search.proprietor ||
    search.location ||
    search.text
  ) {
    const isVisible = (item: IArtwork) => {
      let show = true;

      if (search.price && show) show = filterPrice(search, item);
      if (search.color && show) show = filterColor(search, item);
      if (search.categoryId && show) show = filterCategory(search, item);
      if (search.size && show) show = filterSize(search, item);
      if (search.proprietor && show) show = filterProprietor(search, item);
      if (search.location && show) show = filterLocation(search, item);
      if (search.text && show) show = filterText(search, item);

      return show;
    };

    return data.map((item, index) => ({ ...item, index })).filter(isVisible);
  }
  return data;
}

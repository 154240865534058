import React, { useState } from "react";

import { faDownload, faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, LightBox, Row, Typo, useI18n } from "@maxeb/admin-ui";
import { Artwork } from "@maxeb/art-sdk";

export interface IProps {
  artwork: string;
  collection: string;
  id: string;
  name: string;
  url: string;
  setPending: () => void;
  setSuccess: () => void;
}

async function download(url: string, name: string) {
  const response = await fetch(url);
  const blob = await response.blob();
  const blobUrl = window.URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.download = name;
  a.href = blobUrl;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

async function remove(props: IProps) {
  props.setPending();
  await Artwork.update(
    { documents: { "!delete": [props.id] } },
    { id: props.artwork, collection: props.collection }
  );
  props.setSuccess();
}

export default function DocumentActions(props: IProps) {
  const i18n = useI18n("artwork_documents");

  const [confirmDelete, setConfirmDelete] = useState(false);

  return (
    <>
      <Row spacing={8} root={0}>
        <Button
          primary
          xs="40px"
          onClick={() => download(props.url, props.name)}
        >
          <FontAwesomeIcon icon={faDownload} />
        </Button>
        <Button
          xs="40px"
          danger
          onClick={function () {
            setConfirmDelete(true);
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </Row>
      <LightBox
        variant="danger"
        title={i18n.get("delete") + "?"}
        onClose={function () {
          setConfirmDelete(false);
        }}
        open={confirmDelete}
      >
        <Typo variant="p">
          {i18n.get("do_you_really")} <b>{props.name}</b>?
        </Typo>
        <Row spacing={16} root={0} horizontalAlign="right">
          <Button
            xs="100px"
            onClick={function () {
              setConfirmDelete(false);
            }}
          >
            {i18n.get("cancle")}
          </Button>
          <Button
            danger
            xs="100px"
            onClick={() => {
              setConfirmDelete(false);
              remove(props);
            }}
          >
            {i18n.get("delete")}
          </Button>
        </Row>
      </LightBox>
    </>
  );
}

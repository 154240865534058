import { ISession, ITeam } from "@maxeb/idp-sdk/dist/modules/IDP";

export interface State {
  id: string;
  status: "init" | "ok" | "pending" | "error" | "success";
  confirmDelete: boolean;
  data: ITeam;
  errors: { [key: string]: string };
}
export type SetState = (state: State) => void;
export interface Props {
  user: ISession;
  translationGroup?: string;
  setSession: (session: ISession | null) => void;
}
export function initialState(props: Props): State {
  return {
    id: props.user.currentTeam || "",
    status: "init",
    confirmDelete: false,
    data: {},
    errors: {},
  };
}

import React, { useState } from "react";
import {
  ITheme,
  useClasses,
  Form,
  StateHandler,
  useI18n,
} from "@maxeb/admin-ui";

import { Artwork, IArtworkSet } from "@maxeb/art-sdk";
import ImageHandler from "../../Shared/ImageHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";

export interface IProps {
  id: string;
  collection: string;
  images?: { hash: string; name: string; url: string }[];
  setPending: () => void;
  setSuccess: () => void;
}

export interface IState {
  status: "ok" | "pending" | "success" | "error";
  index: number;
}

const styles = (theme: ITheme) => ({
  wrapper: {
    position: "relative",
  },
  previouseSection: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 32,
    color: "rgba(255, 255, 255, 1)",
    textShadow: "2px 2px #000",
    zIndex: 2,
    width: "32px",
    fontSize: "3rem",
    padding: " 90px 16px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      color: "rgba(255, 255, 255, 1)",
    },
  },
  nextSection: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 32,
    color: "rgba(255, 255, 255, 1)",
    textShadow: "2px 2px #000",
    zIndex: 2,
    width: "32px",
    fontSize: "3rem",
    padding: " 90px 16px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      color: "rgba(255, 255, 255, 1)",
    },
  },
  alignCenter: {
    textAlign: "center",
  },
});

async function uploadOrDelete(
  set: IArtworkSet,
  props: IProps,
  state: IState,
  setLocalState: (state: IState) => void
) {
  props.setPending();
  try {
    const result = await Artwork.update(set, {
      id: props.id,
      collection: props.collection,
    });
    if (result.isSuccess()) {
      props.setSuccess();
    } else setLocalState({ ...state, status: "error" });
  } catch (error) {
    setLocalState({ ...state, status: "error" });
  }
}

async function upload(
  value: string,
  props: IProps,
  state: IState,
  setLocalState: (state: IState) => void
) {
  uploadOrDelete({ images: { "!add": [value] } }, props, state, setLocalState);
}

async function remove(
  value: string,
  props: IProps,
  state: IState,
  setLocalState: (state: IState) => void
) {
  uploadOrDelete(
    { images: { "!delete": [value] } },
    props,
    state,
    setLocalState
  );
}

export default function Images(props: IProps) {
  const classes = useClasses(styles);
  const [state, setState] = useState<IState>({ status: "ok", index: 0 });
  const { index } = state;
  const img = (props.images || [])[index];

  const i18n = useI18n("artwork_images");

  return (
    <StateHandler state={state.status}>
      <Form>
        <div className={classes.wrapper}>
          {index !== 0 && (
            <section
              className={classes.previouseSection}
              style={{ bottom: index === (props.images || []).length ? 0 : 32 }}
              onClick={() => {
                setState({ ...state, index: --state.index });
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </section>
          )}
          <ImageHandler
            value={img}
            onChange={(value) => {
              if (value) upload(value, props, state, setState);
              else remove(`${img.name} & ${img.hash}`, props, state, setState);
            }}
            height="335px"
          >
            <div className={classes.alignCenter}>
              <b>{i18n.get("image")}</b>
              <br />
              {i18n.get("upload")}
            </div>
          </ImageHandler>
          {index !== (props.images || []).length && (
            <section
              className={classes.nextSection}
              onClick={() => {
                setState({ ...state, index: ++state.index });
              }}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </section>
          )}
        </div>
      </Form>
    </StateHandler>
  );
}

import React from "react";
import {
  Button,
  AdminPage,
  ITheme,
  useClasses,
  useI18n,
  Row,
  Col,
  usePathVariables,
  Typo,
} from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaintBrush,
  faSave,
  faSquareChevronLeft,
  faTheaterMasks,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { IArtist } from "@maxeb/art-sdk";
import Thumb from "./Thumb";
import Vita from "./Vita";
import Education from "./Education";
import Awards from "./Awards";
import Delete from "../../Shared/Delete";
import { useArtistEdit } from "../../../Hooks/Artists/Edit";
import { useArtworks } from "../../../Hooks/Artworks/List";
import Card from "../../Shared/Card";
import CollectionSelect from "../../Collections/Select";
import ArtworkEdit from "../../Artworks/Edit";

export interface IProps {
  id?: string;
  onChange?: (artwork: IArtist) => void;
  close?: (isDeleted?: boolean) => void;
  team: string;
}

const styles = (theme: ITheme) => ({
  overlay: {
    padding: "132px",
    backgroundColor: "rgba(236, 236, 236, 0.95) !important",
  },
  alignCenter: {
    textAlign: "center",
  },
  row: {
    [theme.breakpoints.up("md")]: {
      width: "330px",
    },
    flexWrap: "nowrap",
    width: "calc(100% + 21px)",
  },
  icon: {
    fontSize: "4rem",
    margin: "0px 16px 0px 0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
      margin: "0px 4px 0px 0px",
    },
  },
  link: {
    textDecoration: "none",
  },
  input: {
    border: "1px solid #b4b4b4 !important",
    marginTop: "24px",
  },
});

export default function Artists(props: IProps) {
  const classes = useClasses(styles);
  const i18n = useI18n("artist_edit");

  const params = usePathVariables();

  const [artist, actions] = useArtistEdit({
    id: props.id || params.id,
    onChange: props.onChange,
  });
  const [artworks, actionsArtwork] = useArtworks({
    team: props.team,
    collection: props.team,
    searchParams: {
      artistId: props.id || params.id,
      open: false,
      collection: props.team,
      price: 0,
      size: 0,
      sorting: 1,
    },
  });

  const { status, data, errors } = artist;

  return (
    <AdminPage
      icon={<FontAwesomeIcon className={classes.icon} icon={faTheaterMasks} />}
      title={i18n.get("artist")}
      state={status === "init" ? "pending" : status}
      breadcrumbs={[
        { label: i18n.get("artist"), onClick: props.close },
        { label: i18n.get("edit") },
      ]}
      actions={
        <Row
          override={{ row: classes.row }}
          spacing={16}
          root={0}
          horizontalAlign="right"
        >
          {props.close && (
            <Button
              xs="100px"
              onClick={() => {
                if (window?.history?.pushState)
                  window.history.pushState({}, "", `/artists`);
                if (props.close) props.close();
              }}
            >
              <FontAwesomeIcon
                style={{ margin: "0px 8px 0px 0px" }}
                icon={faSquareChevronLeft}
              />
              {i18n.get("back")}
            </Button>
          )}
          <Button
            xs="110px"
            danger
            onClick={() => actions.setConfirmDelete(true)}
          >
            <FontAwesomeIcon
              style={{ margin: "0px 8px 0px 0px" }}
              icon={faTrash}
            />
            {i18n.get("delete")}
          </Button>
          <Button xs="120px" primary onClick={actions.save}>
            <FontAwesomeIcon
              style={{ margin: "0px 8px 0px 0px" }}
              icon={faSave}
            />
            {i18n.get("save")}
          </Button>
        </Row>
      }
      override={{ overlay: classes.overlay }}
      overlayPages={
        artworks.active !== null
          ? [
              <div className={classes.active}>
                <ArtworkEdit
                  id={artworks.active.id || ""}
                  collection={artworks.active.collection}
                  team={artworks.team}
                  onChange={(artworks) => actionsArtwork.updateActive(artworks)}
                  close={(isDeleted) => {
                    if (isDeleted) actionsArtwork.updateActive(null);
                    else actionsArtwork.active(null, null);
                  }}
                />
              </div>,
            ]
          : undefined
      }
    >
      {data.id && (
        <>
          <Row spacing={16} vertical root={0}>
            <Col lg="300px">
              <Thumb
                id={data.id}
                thumb={data.thumb}
                setPending={actions.pending}
                setSuccess={actions.reload}
              />
            </Col>
            <Col lg="calc(50% - 300px)">
              <Vita data={data} errors={errors} onChange={actions.setData} />
            </Col>
            <Col lg={2.5}>
              <Education
                id={data.id}
                list={data.education}
                onChange={(list) => {
                  actions.setData({
                    ...artist.data,
                    education: list,
                  });
                }}
              />
            </Col>
            <Col lg={2.5}>
              <Awards
                id={data.id}
                list={data.awardsExhibition}
                onChange={(list) => {
                  actions.setData({
                    ...artist.data,
                    awardsExhibition: list,
                  });
                }}
              />
            </Col>
            <Col>
              <Delete
                name={`Künstler - ${data.name}`}
                open={artist.confirmDelete}
                onClose={function (): void {
                  actions.setConfirmDelete(false);
                }}
                onDelete={async function () {
                  await actions.remove();
                  if (props.close) props.close(true);
                }}
              />
            </Col>
          </Row>
          <Row spacing={16} root={0}>
            <Col lg="auto">
              <Typo variant="section">Werke des Künstlers in</Typo>
            </Col>
            <CollectionSelect
              lg={3}
              onChange={(value) => {
                actionsArtwork.search({
                  ...artworks.search,
                  collection: value || props.team,
                });
              }}
              override={{
                input: classes.input,
                label: classes.label,
              }}
            />
          </Row>
          <Row
            spacing={16}
            vertical
            root={{ top: 24, left: 0, right: 0, bottom: 0 }}
          >
            {actionsArtwork.filter().map((artwork, index) => (
              <Col
                key={`artist-${artwork.id}`}
                xs={10}
                sm={5}
                md={10 / 3}
                lg={10 / 4}
                xl={10 / 5}
                override={{ col: classes.artworkCardsWrapper }}
              >
                <div
                  onClick={() => {
                    actionsArtwork.active(artwork, index);
                    window.history.pushState(
                      {},
                      "",
                      `/artworks/${artwork.collection}/${artwork.id}`
                    );
                  }}
                >
                  <Card
                    name={artwork.artist}
                    subscript={artwork.title}
                    icon={faPaintBrush}
                    thumb={
                      artwork.thumb && {
                        ...artwork.thumb,
                        url: artwork.thumb["xs-url"],
                      }
                    }
                  />
                </div>
              </Col>
            ))}
          </Row>
        </>
      )}
    </AdminPage>
  );
}

import React from "react";
import { Col, ITheme, Row, Typo, useClasses, useI18n } from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaintBrush, faPalette } from "@fortawesome/pro-light-svg-icons";

export interface IProps {
  onChange: (type: "artist" | "artwork") => void;
}

export interface IState {}

const styles = (theme: ITheme) => ({
  button: {
    outline: "none",
    fontSize: "7rem",
    padding: "32px",
    width: "100%",
    display: "block",
    backgroundColor: theme.palette.get("grey"),
    color: theme.palette.get("darkGrey"),
    border: "1px solid " + theme.palette.get("darkGrey"),
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.get("primary"),
      borderColor: theme.palette.get("primary"),
    },
  },
  buttonTxt: {
    fontSize: "2rem",
    marginTop: "16px",
  },
});

export default function HowToAndSelectType(props: IProps) {
  const classes = useClasses(styles);
  const i18n = useI18n("import");

  const { onChange } = props;

  return (
    <Row>
      <Col lg={5}>
        <Typo variant="h1">{i18n.get("how_does_it_work")}</Typo>
        <Typo variant="p">{i18n.get("desc")}</Typo>
        <Typo variant="p">
          <b>
            <i>{i18n.get("note")}</i>
          </b>
        </Typo>
        <Typo variant="h2">{i18n.get("steps")}</Typo>
        <ol>
          <li>{i18n.get("first")}</li>
          <li>{i18n.get("second")}</li>
          <li>{i18n.get("third")}</li>
          <li>{i18n.get("fourth")}</li>
          <li>
            {i18n.get("fifth")}
            <b>{i18n.get("sixed")}</b>
          </li>
          <li>{i18n.get("seventh")}</li>
        </ol>
        <Typo variant="h2">1.{i18n.get("select")}</Typo>
        <Row spacing={16} vertical root={0}>
          <Col xs={5}>
            <button
              className={classes.button}
              onClick={() => {
                onChange("artwork");
              }}
            >
              <FontAwesomeIcon icon={faPaintBrush} />
              <div className={classes.buttonTxt}>
                {i18n.get("import_artwork")}
              </div>
            </button>
          </Col>
          <Col xs={5}>
            <button
              className={classes.button}
              onClick={() => {
                onChange("artist");
              }}
            >
              <FontAwesomeIcon icon={faPalette} />
              <div className={classes.buttonTxt}>
                {i18n.get("import_artist")}
              </div>
            </button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

import React, { useState } from "react";
import {
  ITheme,
  useClasses,
  Form,
  Typo,
  Block,
  StateHandler,
  useI18n,
} from "@maxeb/admin-ui";

import { Artist } from "@maxeb/art-sdk";
import ImageHandler from "../../Shared/ImageHandler";

export interface IProps {
  id: string;
  thumb?: { hash: string; name: string; url: string };
  setPending: () => void;
  setSuccess: () => void;
}

export interface IState {
  status: "ok" | "pending" | "success" | "error";
}

const styles = (theme: ITheme) => ({
  alignCenter: {
    textAlign: "center",
  },
});

async function uploadOrDelete(
  value: string | undefined,
  props: IProps,
  state: IState,
  setLocalState: (state: IState) => void
) {
  props.setPending();
  const isUpload = Boolean(value);

  try {
    const result = await Artist.update(
      { thumb: isUpload ? value : null },
      { id: props.id }
    );
    if (result.isSuccess()) {
      props.setSuccess();
    } else setLocalState({ ...state, status: "error" });
  } catch (error) {
    setLocalState({ ...state, status: "error" });
  }
}

export default function Thumb(props: IProps) {
  const classes = useClasses(styles);
  const i18n = useI18n("artist_edit");
  const [state, setState] = useState<IState>({ status: "ok" });

  return (
    <Block>
      <Typo variant="section" color="primary" margin="none">
        {i18n.get("thumb")}
      </Typo>
      <StateHandler state={state.status}>
        <Form>
          <ImageHandler
            value={props.thumb}
            onChange={(value) => {
              uploadOrDelete(value, props, state, setState);
            }}
            height="356px"
          >
            <div className={classes.alignCenter}>
              <b>{i18n.get("thumb")}</b>
              <br />
              {i18n.get("upload")}
            </div>
          </ImageHandler>
        </Form>
      </StateHandler>
    </Block>
  );
}

import React, { useState } from "react";
import {
  Button,
  Form,
  ITheme,
  StateHandler,
  TextInput,
  Typo,
  useClasses,
  useI18n,
} from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons";
import { IDP } from "@maxeb/idp-sdk";
import { ISession } from "@maxeb/idp-sdk/dist/modules/IDP";
import LightBox from "../Shared/LightBox";

export interface IProps {
  user: ISession;
  open: boolean;
  setEmail: (value: string) => void;
  setOpen: (value: boolean) => void;
  translationGroup?: string;
}

const styles = (theme: ITheme) => ({
  overlay: {
    padding: "0px 0px 36px 0px !important",
  },
});

async function saveEmail(email: string, user: string) {
  const result = await IDP.settings({ newEmail: email }, { id: user });

  return result;
}
async function saveSecret(code: string) {
  const result = await IDP.challengeEmailNew({ secret: code });

  return result;
}

export default function Settings(props: IProps) {
  const classes = useClasses(styles);
  const [state, setState] = useState<"ok" | "pending" | "error" | "success">(
    "ok"
  );
  const { open, setOpen } = props;
  const [mode, setMode] = useState<"email" | "secret">("email");
  const [code, setCode] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const i18n = useI18n(props.translationGroup || "authentication");
  const i18nErrors = useI18n("errors");
  return (
    <>
      <Button
        width="120px"
        success
        onClick={() => {
          setOpen(!open);
        }}
      >
        <FontAwesomeIcon icon={faEnvelope} /> {i18n.get("new_email")}
      </Button>
      <LightBox
        title={
          <>
            <FontAwesomeIcon icon={faEnvelope} /> {i18n.get("new_email")}
          </>
        }
        open={open}
        onClose={function (): void {
          setOpen(false);
          setState("ok");
        }}
      >
        <StateHandler
          state={state}
          override={{
            overlay: classes.overlay,
          }}
        >
          {mode === "email" && (
            <Form spacing={8} vertical root={0} horizontalAlign="right">
              <Typo variant="h2" margin="0">
                {i18n.get("new_email")}
              </Typo>
              <Typo variant="info" margin="0">
                {i18n.get("new_email_info")}
              </Typo>
              <TextInput
                inline
                label={i18n.get("email")}
                autoComplete="email"
                value={email}
                onChange={setEmail}
                error={errors.email && i18nErrors.get(errors.email)}
              />
              <Button
                xs="120px"
                primary
                onClick={async () => {
                  setState("pending");
                  try {
                    const result = await saveEmail(email || "", props.user.id);
                    if (result.isSuccess()) {
                      setState("success");
                      setCode("");
                      setTimeout(() => {
                        setState("ok");
                        setMode("secret");
                      }, 1000);
                    } else {
                      const errors = result.getErrors({
                        "body.newEmail": "email",
                      });
                      setErrors(errors);
                      setState("ok");
                    }
                  } catch (error) {
                    setState("error");
                  }
                }}
              >
                {i18n.get("next")}
              </Button>
            </Form>
          )}
          {mode === "secret" && (
            <Form spacing={8} vertical root={0} horizontalAlign="right">
              <Typo variant="h2" margin="0">
                {i18n.get("code")}
              </Typo>
              <Typo variant="info" margin="0">
                {i18n
                  .get("new_email_info_2")
                  .replace("###email###", email || "")}
              </Typo>
              <TextInput
                inline
                label={i18n.get("code")}
                autoComplete="off"
                value={code}
                onChange={setCode}
                error={errors.code && i18nErrors.get(errors.code)}
              />
              <Button
                xs="120px"
                onClick={() => {
                  setMode("email");
                }}
              >
                {i18n.get("back")}
              </Button>
              <Button
                xs="120px"
                success
                onClick={async () => {
                  setState("pending");
                  try {
                    const result = await saveSecret(code || "");
                    if (result.isSuccess()) {
                      setState("success");
                      setTimeout(() => {
                        setMode("email");
                        setEmail("");
                        setCode("");
                        props.setEmail(email || "");
                        setOpen(false);
                      }, 1000);
                    } else {
                      const errors = result.getErrors({
                        "body.secret": "code",
                      });
                      setErrors(errors);
                      setState("ok");
                    }
                  } catch (error) {
                    setState("error");
                  }
                }}
              >
                {i18n.get("change")}
              </Button>
            </Form>
          )}
        </StateHandler>
      </LightBox>
    </>
  );
}

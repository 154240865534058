import React from "react";
import {
  Button,
  ITheme,
  useClasses,
  Row,
  Col,
  LightBox,
} from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";

export interface IProps {
  name: string;
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}

export interface IState {
  confirmDelete: boolean;
}

const styles = (theme: ITheme) => ({
  icon: {
    marginRight: "4px",
  },
});

export default function CollectionDelete(props: IProps) {
  const classes = useClasses(styles);

  return (
    <LightBox
      open={props.open}
      variant="danger"
      title={
        <>
          <FontAwesomeIcon icon={faTrash} className={classes.icon} /> Delete
        </>
      }
      onClose={() => {
        props.onClose();
      }}
    >
      <Row root={0} spacing={16} horizontalAlign="right" vertical>
        <Col>
          Wollen Sie <b>"{props.name}"</b> wirklich löschen?
        </Col>
        <Button xs="100px" onClick={props.onClose}>
          Abbrechen
        </Button>
        <Button xs="100px" danger onClick={props.onDelete}>
          Löschen
        </Button>
      </Row>
    </LightBox>
  );
}

import React, { useState } from "react";

import { Blurhash } from "react-blurhash";

interface IImage {
  className?: string;
  style?: React.CSSProperties;
  src: string;
  hash?: string;
  alt: string;
}

const ImageLoader: React.FC<IImage> = (props) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      {!loaded && props.hash && (
        <Blurhash
          className={props.className}
          width={32}
          height={32}
          hash={props.hash}
        />
      )}

      <img
        className={props.className}
        style={props.style}
        alt={props.alt}
        src={props.src}
        onLoad={() => {
          setLoaded(true);
        }}
      />
    </>
  );
};

export default ImageLoader;

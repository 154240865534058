import {
  faSave,
  faSquareChevronLeft,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ITheme, Row, useClasses, useI18n } from "@maxeb/admin-ui";
import { Actions as HookActions } from "../../../Hooks/Artworks/Edit";

export interface Props {
  close?: () => void;
  actions: HookActions;
}

const styles = (theme: ITheme) => ({
  overlay: {
    padding: "132px",
    backgroundColor: "rgba(236, 236, 236, 0.95) !important",
  },
  alignCenter: {
    textAlign: "center",
  },
  row: {
    flexWrap: "nowrap",
    [theme.breakpoints.up("md")]: {
      width: "330px",
    },
    width: "calc(100% + 21px)",
  },
  icon: {
    fontSize: "4rem",
    margin: "0px 16px 0px 0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
      margin: "0px 4px 0px 0px",
    },
  },
  link: {
    textDecoration: "none",
  },
});

export default function Actions(props: Props) {
  const classes = useClasses(styles, props);
  const i18n = useI18n("artwork_edit");

  const { actions } = props;

  return (
    <Row
      override={{ row: classes.row }}
      spacing={16}
      root={0}
      horizontalAlign="right"
    >
      {props.close && (
        <Button
          xs="100px"
          override={{}}
          onClick={async () => {
            if (window?.history?.pushState)
              window.history.pushState({}, "", `/artworks`);
            if (props.close) props.close();
          }}
        >
          <FontAwesomeIcon
            style={{ margin: "0px 8px 0px 0px" }}
            icon={faSquareChevronLeft}
          />
          {i18n.get("back")}
        </Button>
      )}
      <Button
        xs="110px"
        danger
        onClick={async () => {
          actions.setConfirmDelete(true);
        }}
      >
        <FontAwesomeIcon style={{ margin: "0px 8px 0px 0px" }} icon={faTrash} />
        {i18n.get("delete")}
      </Button>
      <Button
        xs="120px"
        primary
        onClick={async () => {
          actions.saveData();
        }}
      >
        <FontAwesomeIcon style={{ margin: "0px 8px 0px 0px" }} icon={faSave} />
        {i18n.get("save")}
      </Button>
    </Row>
  );
}

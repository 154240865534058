import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import ReactDOM from "react-dom";
import { ITheme, Row, Col, useClasses } from "@maxeb/admin-ui";

interface ITabs {
  tabs: string[];
  children?: React.ReactNode;
}

const styles = (theme: ITheme) => ({
  respListBtn: {
    textAlign: "center",
  },
  respListIcon: {
    fontSize: "1.3rem",
    color: theme.palette.get("darkgrey"),
    cursor: "pointer",
  },
  list: {
    display: "block",
    listStyle: "none",
    width: "100%",
    height: "33px",
    padding: 0,
    margin: "-8px -8px",
    overflow: "hidden",
  },
  listElement: {
    display: "inline-block",
    "@media print": {
      display: "none",
    },
  },
  activeListElement: {
    "@media print": {
      display: "block",
    },
  },
  tabButton: {
    backgroundColor: "rgba(255, 255, 255, 0)",
    padding: "8px 8px",
    border: "none",
    fontSize: "1rem",
    fontWeight: "600",
    textTransform: "uppercase",
    outline: "none",
    letterSpacing: "0.5px",
    cursor: "pointer",
    color: theme.palette.get("darkgrey"),
  },
  activeButton: {
    color: theme.palette.get("primary"),
  },
  active: {
    color: theme.palette.get("primary"),
  },
});

const Tabs: React.FC<ITabs> = (props) => {
  const classes = useClasses(styles, props);

  const [tab, setTab] = useState(0);
  const [menuAnchor, setMenuAnchor] = useState<HTMLDivElement | null>(null);
  const [ulNode, setUlNode] = useState<HTMLUListElement | null>(null);
  const [liNodes, setLiNodes] = useState<HTMLLIElement[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [inRespMenu, setInRespMenu] = useState<boolean[]>(
    props.tabs.map(() => true)
  );

  const hasRespBtn = inRespMenu.filter((item) => !item).length > 0;

  useEffect(() => {
    const resizer = () => {
      if (
        ulNode &&
        liNodes.filter((item) => typeof item !== "undefined").length ===
          props.tabs.length
      ) {
        const listWidth = ulNode.getBoundingClientRect().width;
        let accu = 0;
        //assign which element should be in resp menu
        const newInRespMenu = liNodes.map((list) => {
          accu += list.getBoundingClientRect().width;
          return accu < listWidth - 32;
        });
        //check if new rerender is neccessary
        if (
          typeof newInRespMenu.find(
            (item, index) => item !== inRespMenu[index]
          ) !== "undefined"
        ) {
          setInRespMenu(newInRespMenu);
        }
      }
    };
    resizer();
    window.addEventListener("resize", resizer);
    return () => window.removeEventListener("resize", resizer);
  });

  return (
    <div>
      <Row spacing={8} root={0}>
        <Col xs="calc(100% - 32px)">
          <div>
            <ul
              className={classes.list}
              ref={(ul) => {
                if (ul !== ulNode && ul !== null) {
                  setUlNode(ul);
                }
              }}
            >
              {props.tabs.map((label, index) => (
                <li
                  key={"tabs-label-" + label + "-" + index}
                  className={classnames([
                    classes.listElement,
                    tab === index && classes.activeListElement,
                  ])}
                  style={{ opacity: inRespMenu[index] ? 1 : 0 }}
                  ref={(li) => {
                    const nodes = [...liNodes];
                    if (nodes[index] !== li && li !== null) {
                      nodes[index] = li;
                      setLiNodes(nodes);
                    }
                  }}
                >
                  <button
                    className={classnames([
                      classes.tabButton,
                      tab === index && classes.activeButton,
                    ])}
                    style={{
                      cursor: inRespMenu[index] ? "pointer" : "default",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      if (inRespMenu[index]) setTab(index);
                    }}
                  >
                    {label}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </Col>
        <Col xs="32px">
          {hasRespBtn && (
            <div
              className={classes.respListBtn}
              ref={(div) => div !== menuAnchor && setMenuAnchor(div)}
            >
              <FontAwesomeIcon
                className={classes.respListIcon}
                icon={faEllipsisV}
                onClick={() => {
                  setIsOpen(true);
                }}
              />
              {isOpen && menuAnchor && (
                <Menu
                  anchor={menuAnchor}
                  options={props.tabs
                    .filter((item, index) => !inRespMenu[index])
                    .map((label, index) => ({
                      label,
                      value: index,
                    }))}
                  onChange={(v) => {
                    if (typeof v === "number") {
                      const tureIndex =
                        inRespMenu.filter((item, index) => inRespMenu[index])
                          .length + v;
                      setTab(tureIndex);
                    }
                  }}
                  onClose={() => {
                    setIsOpen(false);
                  }}
                />
              )}
            </div>
          )}
        </Col>
      </Row>
      <div>
        {React.Children.map(props.children, (child, index) =>
          index === tab ? child : ""
        )}
      </div>
    </div>
  );
};

const menuStyles = (theme: ITheme) => ({
  menu: {
    position: "absolute",
    backgroundColor: "#fff",
    boxSizing: "border-box",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
    borderRadius: "2px",
    padding: "0px",
    margin: "0px",
    listStyle: "none",
  },
  listElement: {
    cursor: "pointer",
    height: "32px",
    lineHeight: "32px",
    padding: "5px 8px  5px 8px",
    "&:hover": {
      backgroundColor: theme.palette.get("formBackgroundActive"),
    },
  },
  curtain: {
    position: "fixed",
    top: "0px",
    left: "0px",
    height: "100%",
    width: "100%",
    zIndex: "999",
  },
});

interface IMenu {
  anchor: HTMLDivElement;
  onClose: () => void;
  options: { value: number; label: string }[];
  onChange: (value?: number) => void;
}

const setId = (node: HTMLElement, id: string): HTMLElement => {
  node.setAttribute("id", id);
  node.style.position = "absollute";
  node.style.zIndex = "100";
  return node;
};

const Menu: React.FC<IMenu> = (props) => {
  const classes = useClasses(menuStyles, props);
  const { anchor } = props;
  const [position, setPosition] = useState(
    anchor && anchor ? anchor.getBoundingClientRect() : null
  );

  const { top = 0, left = 0 } = position || {};

  useEffect(() => {
    function handleResize() {
      if (anchor && anchor) setPosition(anchor.getBoundingClientRect());
    }

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleResize);
    };
  });

  const trueLeft = left - 280 < 10 ? 10 : left - 280;

  return ReactDOM.createPortal(
    <div className={classes.curtain} onClick={props.onClose}>
      <ul
        className={classes.menu}
        style={{ top: top, left: trueLeft, width: 300 }}
      >
        {props.options.map((option: { value: number; label: string }) => (
          <li
            key={option.value}
            className={classes.listElement}
            onClick={(e) => {
              props.onChange(option.value);
              props.onClose();
            }}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>,
    document.getElementById("resp-tab-menu") ||
      document.body.appendChild(
        setId(document.createElement("div"), "resp-tab-menu")
      )
  );
};

export default Tabs;

import React, { useState } from "react";
import {
  Row,
  Form,
  TextInput,
  Button,
  StateHandler,
  Typo,
} from "@maxeb/admin-ui";
import { IDP, ISession } from "@maxeb/idp-sdk";

const spacing = 16;

export interface IProps {
  firstTime?: boolean;
  user: ISession;
  setSession: (session: ISession | null) => void;
  onClose?: () => void;
}

async function add(
  data: { title?: string; description?: string },
  user: ISession
) {
  return IDP.addTeamAndSwap({
    name: data.title || "",
    description: data.description,
    owner: user.id,
  });
}

export default function TeamAdd(props: IProps) {
  const [state, setState] = useState<"success" | "ok" | "pending" | "error">(
    "ok"
  );
  const [values, setValues] = useState<{
    title?: string;
    description?: string;
  }>({});
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  return (
    <StateHandler state={state}>
      <Typo variant="info">
        {props.firstTime && <>Bevor sie starten müssen Sie ein Team anlegen.</>}{" "}
        Mit einem Team können Sie mit anderen Usern Ihre Inhalte bearbeiten,
        wenn Sie diese User zu ihrem Team hinzufügen.
      </Typo>
      <Form
        spacing={spacing}
        vertical
        root={{ top: 16, left: 0, right: 0, bottom: 0 }}
      >
        <Row spacing={spacing} vertical root={0}>
          <TextInput
            label="Name"
            inline
            value={values.title}
            error={errors.title}
            onChange={(value) => {
              setValues({ ...values, title: value });
            }}
          />
          <TextInput
            label="Beschreibung"
            rows={5}
            inline
            value={values.description}
            error={errors.description}
            onChange={(value) => {
              setValues({ ...values, description: value });
            }}
          />
        </Row>
        <Row spacing={spacing} vertical root={0} horizontalAlign="right">
          {props.onClose && (
            <Button xs="100px" onClick={props.onClose}>
              Cancel
            </Button>
          )}
          <Button
            xs="100px"
            success
            onClick={async () => {
              setState("pending");
              try {
                const response = await add(values, props.user);
                if (response.isSuccess()) {
                  const result = response.getResult();
                  setState("success");
                  setTimeout(() => {
                    props.setSession(result.data);
                    if (props.onClose) props.onClose();
                    setState("ok");
                  }, 1000);
                } else {
                  const errors = response.getErrors({
                    "body.name": "title",
                    "body.description": "description",
                  });
                  setErrors(errors);
                  setState("ok");
                }
              } catch (error) {
                setState("error");
              }
            }}
          >
            Add
          </Button>
        </Row>
      </Form>
    </StateHandler>
  );
}

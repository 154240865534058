import { useEffect } from "react";
import { Props, State, getInitialState } from "./State";
import { useNavigate } from "@maxeb/admin-ui";
import * as Mutations from "./Mutations";
import useSaveState from "../../SaveState";

export type { State, Props } from "./State";
export interface Actions {
  pending: () => void;
  reload: () => void;
  get: () => void;
  save: () => void;
  setData: (data: State["data"]) => void;
  setConfirmDelete: (to: boolean) => void;
  remove: () => void;
}

export function useCollection(props: Props): [State, Actions] {
  const [state, setState] = useSaveState(getInitialState(props));

  useEffect(() => {
    if (state.status === "init") Mutations.get(state, setState);
  }, [state, setState]);

  const navigate = useNavigate();

  return [
    state,
    {
      setConfirmDelete: (to) => {
        setState({ ...state, confirmDelete: to });
      },
      remove: () => {
        Mutations.remove({ navigate }, state, setState);
      },
      pending: () => {
        Mutations.setPending(state, setState);
      },
      reload: () => {
        Mutations.reload(state, setState);
      },
      get: () => {
        Mutations.get(state, setState);
      },
      save: () => {
        Mutations.save(state, setState);
      },
      setData: (data) => {
        Mutations.setData({ data }, state, setState);
      },
    },
  ];
}

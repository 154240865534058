import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  TextInput,
  DateInput,
  Typo,
  useI18n,
  StateHandler,
} from "@maxeb/admin-ui";

export interface IProps {
  add: (
    name: string,
    date: [string, string, string],
    description: string
  ) => Promise<boolean>;
  close: () => void;
}

export interface IState {
  status: "ok" | "pending" | "success";
  values: {
    name: string;
    description: string;
  };
  errors: {};
}

const NewOwner: React.FC<IProps> = (props) => {
  //const classes = useClasses(styles, props);
  const [myDate, setMyDate] = useState<[string, string, string]>([
    new Date().getDate() + "",
    new Date().getMonth() + 1 + "",
    new Date().getFullYear() + "",
  ]);

  const i18n = useI18n("artwork_owner");

  const [state, setState] = useState<IState>({
    values: {
      name: "",
      description: "",
    },
    status: "ok",
    errors: {},
  });

  return (
    <StateHandler state={state.status}>
      <Row
        spacing={16}
        vertical
        root={{ top: 0, left: 0, right: 0, bottom: 0 }}
        verticalAlign={"top"}
        horizontalAlign="right"
      >
        <Col>
          <Typo variant="p">{i18n.get("desc")}</Typo>
        </Col>
        <TextInput
          inline
          lg={5}
          label={i18n.get("name")}
          value={state.values.name}
          onChange={(value) => {
            if (!value?.endsWith("|")) {
              const values = { ...state.values, name: (value || "") + "" };
              setState({ ...state, values });
            }
          }}
        />
        <DateInput
          inline
          lg={5}
          label={i18n.get("date")}
          value={myDate}
          onChange={(value) => {
            if (value) setMyDate(value);
          }}
        />
        <TextInput
          inline
          label={i18n.get("description")}
          rows={4}
          value={state.values.description}
          onChange={(value) => {
            if (!value?.endsWith("|")) {
              const values = {
                ...state.values,
                description: (value || "") + "",
              };
              setState({ ...state, values });
            }
          }}
        />
        <Button
          xs="120px"
          onClick={() => {
            props.close();
          }}
        >
          {i18n.get("cancel")}
        </Button>
        <Button
          xs="120px"
          success
          onClick={async () => {
            setState({ ...state, status: "pending" });
            if (
              await props.add(
                state.values.name,
                myDate,
                state.values.description
              )
            )
              setTimeout(() => {
                props.close();
                setState({ ...state, status: "ok" });
              }, 1000);
          }}
        >
          {i18n.get("add")}
        </Button>
      </Row>
    </StateHandler>
  );
};

export default NewOwner;

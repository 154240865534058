import { Artwork, IArtwork } from "@maxeb/art-sdk";
import { indexes } from "./Definitions";
import { State, SetState } from "./State";

export async function next(state: State, setState: SetState) {
  setState({ ...state, status: "pending" });

  try {
    const { search } = state;
    const [index, direction] = indexes[search.sorting];

    const result = await Artwork.get(
      {
        collection: search.collection,
        artistId: search.artistId,
      },
      [
        "id",
        "customId",
        "title",
        "artist",
        "thumb",
        "collection",
        "team",
        "sellingPrice",
        "color",
        "categoryId",
        "available",
        "size",
        "currentLocation",
        "proprietor",
      ],
      100,
      index,
      direction,
      state.next
    );
      
    if (result.isSuccess())
      return setState({
        ...state,
        status: "ok",
        data: [...state.data, ...result.body.data],
        next: result.body.next,
      });
  } catch (err) {
    console.error(err);
  }

  setState({ ...state, status: "error" });
}
export function search(
  props: { search: State["search"] },
  state: State,
  setState: SetState
) {
  const { search } = props;
  const deepLinkParam = encodeURIComponent(JSON.stringify(search));
  window.history.pushState({}, "", `/artworks?q=${deepLinkParam}`);

  setState({ ...state, search, status: "init", data: [], next: undefined });
}
export function setActive(
  props: { artwork: IArtwork | null; index: number | null },
  state: State,
  setState: SetState
) {
  const { artwork, index } = props;
  setState({ ...state, active: artwork, activeIndex: index });
}
export function setNewActive(
  props: {
    id: string;
    title: string;
    artist: string;
    artistId: string;
    collection: string;
  },
  state: State,
  setState: SetState
) {
  const artwork = props;
  setState({
    ...state,
    data: [artwork, ...state.data],
    active: artwork,
    activeIndex: 0,
  });
}
export function updateActive(
  props: { set: IArtwork | null },
  state: State,
  setState: SetState
) {
  if (state.activeIndex !== null) {
    const { set } = props;

    if (set === null) {
      const data = [...state.data];
      data.splice(state.activeIndex, 1);

      setState({ ...state, data, active: null, activeIndex: null });
    } else {
      let data = state.data[state.activeIndex];

      let changed = false;
      const assignIfDifferent = (key: keyof IArtwork) => {
        if (set[key] !== data[key]) {
          data[key] = set[key] as any;
          changed = true;
        }
      };

      assignIfDifferent("title");
      assignIfDifferent("artist");
      assignIfDifferent("thumb");
      assignIfDifferent("collection");
      assignIfDifferent("sellingPrice");
      assignIfDifferent("color");
      assignIfDifferent("categoryId");
      assignIfDifferent("available");
      assignIfDifferent("size");
      assignIfDifferent("currentLocation");

      if (changed) {
        const newData = [...state.data];
        newData[state.activeIndex] = data;
        setState({ ...state, data: newData });
      }
    }
  }
}
export function remove(
  props: { artworkIDs: string[] },
  state: State,
  setState: SetState
) {
  const { artworkIDs } = props;
  let data = [...state.data];

  artworkIDs.forEach((id) => {
    const index = data.findIndex((artwork) => artwork.id === id);
    if (index > -1) data.splice(index, 1);
  });

  setState({ ...state, data });
}

import React, { useState } from "react";
import {
  AdminPage,
  Block,
  Button,
  Col,
  Form,
  ITheme,
  Radio,
  RadioItem,
  Row,
  TextInput,
  Typo,
  useClasses,
  useI18n,
} from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBrowser,
  faCopy,
  faSave,
  faShareAlt,
} from "@fortawesome/pro-light-svg-icons";
import { State, Actions } from "../../Hooks/Website/Edit";
import Delete from "../Shared/Delete";
import ImageHandler from "../Shared/ImageHandler";

export interface IProps {
  state: State;
  actions: Actions;
}

const styles = (theme: ITheme) => ({
  icon: {
    fontSize: "4rem",
    margin: "0px 16px 0px 0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
      margin: "0px 4px 0px 0px",
    },
  },
  row: {
    [theme.breakpoints.up("md")]: {
      width: "330px",
    },
  },
});

export default function EditWebsite(props: IProps) {
  const classes = useClasses(styles);
  const i18n = useI18n("website");
  const { state, actions } = props;
  const { data, errors } = state;

  const [confirmDelete, setConfirmDelete] = useState(false);

  return (
    <AdminPage
      title={i18n.get("website")}
      state={state.status === "init" ? "pending" : state.status}
      icon={<FontAwesomeIcon className={classes.icon} icon={faBrowser} />}
      breadcrumbs={[{ label: i18n.get("website") }]}
      actions={
        <Row
          override={{ row: classes.row }}
          spacing={16}
          root={0}
          horizontalAlign="right"
        >
          <Button xs="120px" danger onClick={() => setConfirmDelete(true)}>
            Deactivate
          </Button>
          <Button
            xs="120px"
            primary
            onClick={() => {
              actions.save();
            }}
          >
            <FontAwesomeIcon
              style={{ margin: "0px 8px 0px 0px" }}
              icon={faSave}
            />
            Save
          </Button>
        </Row>
      }
      override={{ overlay: classes.overlay, main: classes.main }}
    >
      <Row
        spacing={16}
        vertical
        root={{ top: 16, left: 0, right: 0, bottom: 0 }}
      >
        <Col>
          <Block>
            <Typo variant="section" color="primary" margin="none">
              Domain
            </Typo>
            {data !== null && (
              <Row spacing={8} root={{ top: 8, left: 0, right: 0, bottom: 0 }}>
                <TextInput
                  xs="rest"
                  inline
                  label={i18n.get("description")}
                  value={`https://my.webcollector.at/${data.path}`}
                  onChange={(value) => {}}
                />
                <Button
                  primary
                  xs={"50px"}
                  onClick={async () => {
                    try {
                      await navigator.share({
                        title: "My Site",
                        text: "",
                        url: `https://my-site.webcollector.at/${data.path}`,
                      });
                    } catch (err) {}
                  }}
                >
                  <FontAwesomeIcon icon={faShareAlt} />
                </Button>
                <Button
                  success
                  xs={"50px"}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://my-site.webcollector.at/${data.path}`
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faCopy} />
                </Button>
              </Row>
            )}
            <Typo variant="p">To enable custom domain, please contact us.</Typo>
            <Typo variant="section" color="primary" margin="16px 0px 0px 0px">
              Language
            </Typo>
            <Form
              spacing={16}
              vertical
              root={{ top: 8, left: 0, right: 0, bottom: 0 }}
            >
              <Radio
                inline
                label={""}
                value={data?.language}
                onChange={(value) => actions.setData({ language: value })}
                error={errors.language}
              >
                <RadioItem label={"EN"} value="en" lg={10 / 3} />
                <RadioItem label={"DE"} value="de" lg={10 / 3} />
              </Radio>
            </Form>
          </Block>
        </Col>
        <Col lg={5}>
          <Block>
            <Typo variant="section" color="primary" margin="none">
              About
            </Typo>
            <Form
              spacing={16}
              vertical
              root={{ top: 16, left: 0, right: 0, bottom: 0 }}
            >
              <Row spacing={16} vertical root={0}>
                <Col lg={5}>
                  <Row spacing={16} vertical root={0}>
                    <TextInput
                      inline
                      label="Title"
                      value={data?.title}
                      onChange={(value) => actions.setData({ title: value })}
                      error={errors.title}
                    />
                    <TextInput
                      inline
                      label="Teaser"
                      rows={5}
                      value={data?.teaser}
                      onChange={(value) => actions.setData({ teaser: value })}
                      error={errors.teaser}
                    />
                    <TextInput
                      inline
                      label="Description"
                      rows={15}
                      value={data?.description}
                      onChange={(value) =>
                        actions.setData({ description: value })
                      }
                      error={errors.description}
                    />
                  </Row>
                </Col>
                <ImageHandler
                  inline
                  lg={5}
                  value={data?.thumb}
                  onChange={(value) => {
                    actions.upload(value || null);
                  }}
                  height="432px"
                >
                  <div className={classes.alignCenter}>
                    <b>Thumb</b>
                    <br />
                    upload
                  </div>
                </ImageHandler>
              </Row>
            </Form>
          </Block>
        </Col>
        <Col lg={5}>
          <Block>
            <Typo variant="section" color="primary" margin="none">
              Imprint
            </Typo>
            <Form
              spacing={16}
              vertical
              root={{ top: 16, left: 0, right: 0, bottom: 0 }}
            >
              <TextInput
                inline
                label="Name"
                value={data?.name}
                onChange={(value) => actions.setData({ name: value })}
                error={errors.name}
              />
              <Radio
                inline
                label={""}
                value={data?.legalForm}
                onChange={(value) => actions.setData({ legalForm: value })}
                error={errors.legalForm}
              >
                <RadioItem label={"GmbH"} value="gmbh" lg={10 / 3} />
                <RadioItem label={"OG"} value="og" lg={10 / 3} />
                <RadioItem label={"AG"} value="ag" lg={10 / 3} />
                <RadioItem label={"KG"} value="kg" lg={10 / 3} />
                <RadioItem label={"GesbR"} value="gesbr" lg={10 / 3} />
                <RadioItem label={"StGes"} value="stges" lg={10 / 3} />
                <RadioItem label={"Gen"} value="gen" lg={10 / 3} />
                <RadioItem label={"Einzelunternehmer"} value="eu" lg={20 / 3} />
              </Radio>
              <TextInput
                inline
                label="Person responsible"
                value={data?.personResponsible}
                onChange={(value) =>
                  actions.setData({ personResponsible: value })
                }
                error={errors.personResponsible}
              />
              <TextInput
                inline
                label="Email"
                value={data?.email}
                onChange={(value) => actions.setData({ email: value })}
                error={errors.email}
              />
              <TextInput
                inline
                label="Jurisdiction"
                value={data?.jurisdiction}
                onChange={(value) => actions.setData({ jurisdiction: value })}
                error={errors.jurisdiction}
              />
              <TextInput
                inline
                label="CorporateRegisterNumber"
                value={data?.corporateRegisterNumber}
                onChange={(value) =>
                  actions.setData({ corporateRegisterNumber: value })
                }
                error={errors.corporateRegisterNumber}
              />
              <TextInput
                inline
                label="Address"
                value={data?.address}
                onChange={(value) => actions.setData({ address: value })}
                error={errors.address}
              />
              <TextInput
                inline
                label="Country"
                value={data?.country}
                onChange={(value) => actions.setData({ country: value })}
                error={errors.country}
              />
            </Form>
          </Block>
        </Col>
      </Row>
      <Delete
        name={`Website`}
        open={confirmDelete}
        onClose={() => {
          setConfirmDelete(false);
        }}
        onDelete={async () => {
          setConfirmDelete(false);
          actions.deactivate();
        }}
      />
    </AdminPage>
  );
}

import { IWebsite } from "@maxeb/art-sdk";

export interface State {
  status: "init" | "ok" | "pending" | "error" | "success";
  data: IWebsite | null;
  errors: { [key: string]: string };
}
export type SetState = (state: State) => void;
export function initialState(): State {
  return {
    status: "init",
    data: null,
    errors: {},
  };
}

import React, { useState } from "react";
import {
  ITheme,
  useClasses,
  Typo,
  Block,
  Row,
  Col,
  Button,
  LightBox,
  Form,
  TextInput,
  NumberInput,
  StateHandler,
  useI18n,
} from "@maxeb/admin-ui";

import { Artist, IArtistSet } from "@maxeb/art-sdk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/pro-light-svg-icons";
import ListElement from "./ListElement";

export interface IProps {
  id: string;
  list?: string[];
  onChange: (list: string[] | undefined) => void;
}
export interface IState {
  status: "ok" | "pending" | "success" | "error";
  open: boolean;
  data: {
    from?: number;
    until?: number;
    name?: string;
  };
  errors: { [key: string]: string };
}

const styles = (theme: ITheme) => ({
  icon: {
    fontSize: "4rem",
    backgroundColor: theme.palette.get("grey"),
    textAlign: "center",
    borderRadius: "100%",
    height: "92px",
    width: "92px",
    boxSizing: "border-box",
    padding: "6px  0px",
    margin: "7px 0px 0px",
  },
  listWrapper: {
    padding: "16px 0px",
  },
  listText: {
    paddingTop: "7px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  eduTime: {
    marginRight: "8px",
  },
});

async function save(
  id: string,
  state: IState,
  setState: (state: IState) => void,
  list: string[] | undefined,
  setList: (newList: string[] | undefined) => void
) {
  setState({ ...state, status: "pending" });

  try {
    const { data } = state;

    const newElement = `${data.from}|${data.until || ""}|${data.name}`;
    const set: IArtistSet = {
      education: { "!add": [newElement] },
    };

    const response = await Artist.update(set, { id });

    if (response.isSuccess()) {
      setState({ ...state, status: "success" });
      setList([...(list || []), newElement]);
      return setTimeout(() => {
        setState({ ...state, status: "ok", open: false });
      }, 1000);
    } else {
      const errors = response.getErrors({
        "body.education": "name",
      });

      if (Object.keys(errors).length)
        return setState({ ...state, status: "ok", errors });
    }
  } catch (error) {
    console.error(error);
  }

  return setState({ ...state, status: "error" });
}

const initialState: IState = {
  status: "ok",
  open: false,
  data: {},
  errors: {},
};

export default function Education(props: IProps) {
  const classes = useClasses(styles);

  const i18n = useI18n("artist_edu");

  const [state, setState] = useState<IState>({
    ...initialState,
  });

  const { data, errors } = state;

  const isEmpty = !props.list?.length;

  return (
    <Block>
      <Typo variant="section" color="primary" margin="none">
        {i18n.get("edu")}
      </Typo>
      {isEmpty && (
        <Row
          spacing={8}
          root={{ top: 32, bottom: 32, left: 0, right: 0 }}
          vertical
        >
          <Col lg="100px">
            <div className={classes.icon}>
              <FontAwesomeIcon icon={faBook} />
            </div>
          </Col>
          <Col lg="calc(100% - 100px)">
            <Typo variant="h2">{i18n.get("edu")}</Typo>
            <Typo variant="p">{i18n.get("desc")}</Typo>
          </Col>
        </Row>
      )}
      {!isEmpty && (
        <div className={classes.listWrapper}>
          {props.list
            ?.sort((a, b) => {
              const [fromA] = a.split("|");
              const [fromB] = b.split("|");

              return parseInt(fromA) - parseInt(fromB);
            })
            .map((item) => (
              <ListElement
                key={`edu-${item}`}
                id={props.id}
                value={item}
                onDelete={() => {
                  if (props.list) {
                    const newList = props.list.filter(
                      (value) => item !== value
                    );
                    props.onChange(newList);
                  }
                }}
              />
            ))}
        </div>
      )}
      <LightBox
        title={
          <>
            <FontAwesomeIcon icon={faBook} style={{ marginRight: "8px" }} />
            {i18n.get("new_edu")}
          </>
        }
        open={state.open}
        onClose={() => {
          if (state.status !== "pending") setState({ ...state, open: false });
        }}
      >
        <StateHandler state={state.status}>
          <Form spacing={16} vertical root={0} horizontalAlign="right">
            <Typo variant="info">{i18n.get("info")}</Typo>
            <NumberInput
              inline
              label={i18n.get("start")}
              xs={5}
              md={2.5}
              value={data.from}
              error={errors.from}
              onChange={(value) => {
                setState({ ...state, data: { ...data, from: value } });
              }}
            />
            <NumberInput
              inline
              label={i18n.get("end")}
              xs={5}
              md={2.5}
              value={data.until}
              error={errors.until}
              onChange={(value) => {
                setState({ ...state, data: { ...data, until: value } });
              }}
            />
            <TextInput
              inline
              label={i18n.get("edu")}
              md={5}
              value={data.name}
              error={errors.name}
              onChange={(value) => {
                setState({ ...state, data: { ...data, name: value } });
              }}
            />
            <Button
              xs="120px"
              onClick={() => {
                if (state.status !== "pending")
                  setState({ ...state, open: false });
              }}
            >
              {i18n.get("cancel")}
            </Button>
            <Button
              xs="120px"
              success
              onClick={() => {
                if (state.status !== "pending")
                  save(props.id, state, setState, props.list, props.onChange);
              }}
            >
              {i18n.get("add")}
            </Button>
          </Form>
        </StateHandler>
      </LightBox>
      <Button
        success
        onClick={() => {
          setState({ ...initialState, open: true });
        }}
      >
        {i18n.get("add")}
      </Button>
    </Block>
  );
}

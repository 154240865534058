import React from "react";
import { Button, ITheme, useClasses, useI18n, Row } from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/pro-light-svg-icons";

export interface IProps {
  searchOpen: boolean;
  setSearchOpen: (to: boolean) => void;
  setAdd: (to: boolean) => void;
}

const styles = (theme: ITheme) => ({
  row: {
    [theme.breakpoints.up("md")]: {
      width: "200px",
    },
  },
});

export default function Actions(props: IProps) {
  const classes = useClasses(styles);
  const i18n = useI18n("artists");

  const { setSearchOpen, searchOpen, setAdd } = props;

  return (
    <Row
      override={{ row: classes.row }}
      spacing={16}
      root={0}
      horizontalAlign="right"
    >
      <Button
        xs="120px"
        md="hidden-up"
        primary
        onClick={() => {
          setSearchOpen(!searchOpen);
        }}
      >
        <FontAwesomeIcon
          style={{ margin: "0px 4px 0px 0px" }}
          icon={faSearch}
        />
        {searchOpen ? i18n.get("close") : i18n.get("search")}
      </Button>
      <Button
        xs="100px"
        success
        onClick={async () => {
          setAdd(true);
        }}
      >
        <FontAwesomeIcon style={{ margin: "0px 4px 0px 0px" }} icon={faPlus} />
        {i18n.get("add")}
      </Button>
    </Row>
  );
}

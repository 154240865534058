import { useState } from "react";
import { AdminPage, ITheme, useClasses, useI18n } from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaintBrush } from "@fortawesome/pro-light-svg-icons";
import Search from "./Search";
import ArtworkEdit from "../Edit";
import Actions from "./Actions";
import Add from "../Add";
import ArtworksForCollection from "./Artworks";
import { useFullCollections } from "../../../Hooks/Artworks/ListCollection";
import IsVisble from "../../Shared/IsVisible";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";

export interface IProps {
  team: string;
}

const styles = (theme: ITheme) => ({
  main: {
    height: "calc(100vh - 251px) !important",
    [theme.breakpoints.down("md")]: {
      height: "calc(100vh - 123px) !important",
    },
    "@media print": {
      display: "none",
    },
  },
  overlay: {
    padding: "132px",
    backgroundColor: "rgba(236, 236, 236, 0.95) !important",
  },
  icon: {
    fontSize: "4rem",
    margin: "0px 16px 0px 0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
      margin: "0px 4px 0px 0px",
    },
  },
  active: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.get("default"),
    "@media print": {
      position: "static",
    },
  },
  collectionName: {
    margin: "32px 0px 16px 0px",
    textTransform: "uppercase",
    fontWeight: "700",
    color: theme.palette.get("darkGrey"),
    letterSpacing: "0.25px",
  },
  pending: {
    color: theme.palette.get("primary"),
    fontSize: "5rem",
    padding: "0px 32px",
  },
});

export default function Artworks(props: IProps) {
  const classes = useClasses(styles);
  const i18n = useI18n("artworks");

  const [view, setView] = useState<"table" | "grid">("grid");

  const [searchOpen, setSearchOpen] = useState(false);

  const [collections, actions] = useFullCollections({
    team: props.team,
  });
  const { status, active, search, team } = collections;

  const [add, setAdd] = useState<boolean>(false);
  const lastCollection = collections.data[collections.data.length - 1];
  const lastCollectionFullyLoaded =
    lastCollection && lastCollection.fullyLoaded;

  return (
    <AdminPage
      title={i18n.get("artworks")}
      state={
        status === "init" ? "pending" : status === "pending" ? "ok" : status
      }
      icon={<FontAwesomeIcon className={classes.icon} icon={faPaintBrush} />}
      breadcrumbs={[
        { label: i18n.get("artworks") },
        { label: i18n.get("all") },
      ]}
      actions={
        <Actions
          searchOpen={searchOpen}
          setSearchOpen={setSearchOpen}
          setAdd={setAdd}
          setView={setView}
          view={view}
        />
      }
      override={{ overlay: classes.overlay, main: classes.main }}
      subhead={
        <Search
          respOpen={searchOpen}
          data={search}
          team={props.team}
          onChange={actions.search}
        />
      }
      overlayPages={
        active !== null
          ? [
              <div className={classes.active}>
                <ArtworkEdit
                  id={active.id || ""}
                  collection={active.collection}
                  team={team}
                  onChange={(artworks) => {
                    actions.updateActive(artworks);
                  }}
                  close={(isDeleted) => {
                    if (isDeleted) actions.updateActive(null);
                    actions.setActive(null, null);
                  }}
                />
              </div>,
            ]
          : undefined
      }
    >
      <Add
        open={add}
        setOpen={(value) => {
          setAdd(value);
        }}
        collection={props.team}
        setNewActive={() => {
          //actions.setNewActive
        }}
      />
      {collections.search.collection === "all" && (
        <>
          {collections.data.map((collection) => (
            <>
              <h4 key={`${collection.id}`} className={classes.collectionName}>
                {collection.name}
              </h4>
              <ArtworksForCollection
                view={view}
                team={collection.team || ""}
                collection={collection}
                actions={actions}
              />
            </>
          ))}
          {collections.status === "pending" && (
            <div className={classes.pending}>
              <FontAwesomeIcon icon={faSpinnerThird} spin />
            </div>
          )}
          <IsVisble
            onChange={(isVisible) => {
              if (isVisible) {
                if (
                  status === "ok" &&
                  collections.next &&
                  lastCollectionFullyLoaded
                )
                  actions.next();
                else if (
                  status === "ok" &&
                  ((lastCollectionFullyLoaded &&
                    lastCollection.id !== props.team) ||
                    (collections.data.length === 0 && !collections.next))
                ) {
                  actions.loadNotAssigned(i18n.get("no_collection"));
                }
              }
            }}
          />
        </>
      )}
    </AdminPage>
  );
}

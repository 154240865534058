import React, { useState } from "react";
import { Button, Form, LightBox, Typo, useI18n } from "@maxeb/admin-ui";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { IActions } from "../../Hooks/Team";

export interface IProps {
  id: string;
  firstname: string;
  lastname: string;
  actions: IActions;
}

export default function Settings(props: IProps) {
  const i18n = useI18n("authentication");

  const [confirmDelete, setConfirmDelete] = useState(false);

  return (
    <>
      <Button
        danger
        onClick={() => {
          setConfirmDelete(true);
        }}
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
      <LightBox
        title="Delete"
        open={confirmDelete}
        variant="danger"
        onClose={function (): void {
          setConfirmDelete(false);
        }}
      >
        <Typo variant="p">
          {i18n.get("do_you_really")}{" "}
          <b>
            {props.firstname} {props.lastname}
          </b>{" "}
          {i18n.get("from_your_team")}
        </Typo>
        <Form
          spacing={16}
          vertical
          horizontalAlign="right"
          root={{ bottom: 0, left: 0, right: 0 }}
        >
          <Button xs="100px" onClick={() => setConfirmDelete(false)}>
            {i18n.get("cancel")}
          </Button>
          <Button
            danger
            xs="100px"
            onClick={() => {
              props.actions.removeUser(props.id);
              setConfirmDelete(false);
            }}
          >
            {i18n.get("delete")}
          </Button>
        </Form>
      </LightBox>
    </>
  );
}

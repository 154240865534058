import React, { useState } from "react";
import {
  Block,
  Button,
  Checkbox,
  Col,
  Form,
  ITheme,
  Row,
  Typo,
  useClasses,
  useI18n,
} from "@maxeb/admin-ui";
import { IFields } from "./Wizzard";

export interface IProps {
  type: "artist" | "artwork";
  fields: IFields;
  values?: string[];
  onNext: (fields: string[]) => void;
  onBack: () => void;
}

export interface IState {
  fields?: string[];
}

const styles = (theme: ITheme) => ({
  next: { textAlign: "right" },
});

const artistRequired = ["name"];
const artworksRequired = ["artistId", "title", "weight"];

export default function HowToAndSelectType(props: IProps) {
  const classes = useClasses(styles);
  const i18n = useI18n("import");

  const { type } = props;
  const [state, setState] = useState<IState>({
    fields:
      props.values || (type === "artist" ? artistRequired : artworksRequired),
  });

  const fieldsToUse = props.fields[type];

  return (
    <Row spacing={16} vertical root={0}>
      <Typo variant="h1">{i18n.get("import_" + props.type)}</Typo>
      <Col lg={5}>
        <Block>
          <Typo variant="section" color="primary" margin="none">
            2.{i18n.get("select_fields")}
          </Typo>
          <Typo variant="p">{i18n.get("select_fields_desc")}</Typo>
          <Form
            spacing={16}
            vertical
            root={{ top: 16, left: 0, right: 0, bottom: 0 }}
          >
            {fieldsToUse.map((field) => {
              return (
                <Checkbox
                  key={`cb-${field.name}`}
                  xs={10}
                  md={10 / 3}
                  label={field.label}
                  inline
                  value={state.fields?.includes(field.name)}
                  onChange={(value) => {
                    if (
                      type === "artist" &&
                      artistRequired.includes(field.name)
                    )
                      return;
                    if (
                      type === "artwork" &&
                      artworksRequired.includes(field.name)
                    )
                      return;

                    if (value) {
                      const fields = [...(state.fields || []), field.name];
                      setState({ ...state, fields });
                    } else {
                      const fields = [...(state.fields || [])].filter(
                        (item) => item !== field.name
                      );
                      setState({ ...state, fields });
                    }
                  }}
                />
              );
            })}
            <Row>
              <Col xs={5}>
                <Button width={"100px"} onClick={props.onBack}>
                  Back
                </Button>
              </Col>
              <Col override={{ col: classes.next }} xs={5}>
                <Button
                  width={"100px"}
                  primary
                  onClick={() => {
                    props.onNext(state.fields || []);
                  }}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Form>
        </Block>
      </Col>
    </Row>
  );
}

import { useEffect, useState } from "react";
import { Props, State, initialState } from "./State";
import * as Mutations from "./Mutations";

export interface Actions {
  pending: () => void;
  reload: () => void;
  get: () => void;
  setData: (data: State["data"]) => void;
  save: () => void;
  remove: () => void;
  invite: (email: string) => void;
  uninvite: (id: string) => void;
  removeUser: (id: string) => void;
}

export function useTeam(props: Props): [State, Actions] {
  const [state, setState] = useState(initialState(props));

  useEffect(() => {
    if (state.status === "init") {
      Mutations.get(state, setState);
    }
  }, [state]);

  return [
    state,
    {
      pending: () =>
        setState({
          ...state,
          status: "pending",
        }),
      reload: () => {
        setState({
          ...state,
          status: "success",
        });
        setTimeout(() => {
          setState({
            ...state,
            status: "init",
          });
        }, 1000);
      },
      get: () => Mutations.get(state, setState),
      setData: (data) =>
        setState({
          ...state,
          data,
        }),
      save: () => {
        Mutations.save(state, setState);
      },
      remove: () => Mutations.remove(props, state, setState),
      invite: (email: string) => Mutations.invite({ email }, state, setState),
      uninvite: (id: string) => Mutations.uninvite({ id }, state, setState),
      removeUser: (id: string) => Mutations.removeUser({ id }, state, setState),
    },
  ];
}

import { Collection, ICollection } from "@maxeb/art-sdk";
import { SetState, State, initialState } from "./State";

export async function next(state: State, setState: SetState) {
  setState({ ...state, status: "pending" });
  try {
    const result = await Collection.get(
      {},
      ["id", "name", "thumb", "team"],
      100,
      "secondaryIndex",
      state.search.direction,
      state.next
    );

    if (result.isSuccess())
      return setState({
        ...state,
        status: "ok",
        data: [...state.data, ...result.body.data],
        next: result.body.next,
      });
  } catch (err) {
    console.error(err);
  }
  setState({ ...state, status: "error" });
}
export function setActive(
  props: { collection: ICollection | null; index: number | null },
  state: State,
  setState: SetState
) {
  const { collection, index } = props;
  setState({
    ...state,
    active: collection,
    activeIndex: index,
  });
}
export function setNewActive(
  props: { collection: ICollection },
  state: State,
  setState: SetState
) {
  const { collection } = props;
  setState({
    ...state,
    data: [collection, ...state.data],
    active: collection,
    activeIndex: 0,
  });
}
export function updateActive(
  props: { collection: ICollection | null },
  state: State,
  setState: SetState
) {
  if (state.activeIndex !== null) {
    const set = props.collection;
    if (set === null) {
      const data = [...state.data];
      data.splice(state.activeIndex, 1);

      setState({ ...state, data, active: null, activeIndex: null });
    } else {
      let data = state.data[state.activeIndex];

      let changed = false;
      const assignIfDifferent = (key: keyof ICollection) => {
        if (set[key] !== data[key]) {
          data[key] = set[key] as any;
          changed = true;
        }
      };

      assignIfDifferent("name");
      assignIfDifferent("thumb");

      if (changed) {
        const newData = [...state.data];
        newData[state.activeIndex] = data;
        setState({ ...state, data: newData });
      }
    }
  }
}
export function search(
  props: { search: State["search"] },
  state: State,
  setState: SetState
) {
  const { search } = props;
  setState({ ...state, search, status: "init", data: [] });
}
export function reset(state: State, setState: SetState) {
  setState(initialState());
}

import React, { useState } from "react";
import { useClasses, ITheme, Row, Col, Button, useI18n } from "@maxeb/admin-ui";
import Selector from "./Selector";
import Generator from "./Generator";

export interface IProps {
  width: number;
  height: number;
  thumb: string;
  artwork: string;
  collection: string;
  setSuccess: () => void;
}
export interface IState {
  index: number;
  lockedUntil: number;
  selected?: string;
}

const styles = (theme: ITheme) => ({
  alignRight: { textAlign: "right" },
});

const Card: React.FC<IProps> = (props) => {
  const classes = useClasses(styles, props);

  const i18n = useI18n("artwork_mockups");
  const [state, setState] = useState<IState>({ index: 0, lockedUntil: 0 });

  return (
    <Row>
      <Col>
        {state.index === 0 && (
          <Selector
            selected={state.selected}
            onSelect={(selected) =>
              setState({ ...state, selected, lockedUntil: 1 })
            }
          />
        )}
        {state.index === 1 && state.selected && (
          <Generator
            setSuccess={props.setSuccess}
            artwork={props.artwork}
            collection={props.collection}
            selected={state.selected}
            width={props.width}
            height={props.height}
            thumb={props.thumb}
            back={() => {
              setState({ ...state, index: state.index - 1 });
            }}
          />
        )}
      </Col>
      {state.index !== 1 && (
        <Col xs={5}>
          <Button
            width="100px"
            disabled={state.index === 0}
            onClick={() => {
              setState({ ...state, index: state.index - 1 });
            }}
          >
            {i18n.get("back")}
          </Button>
        </Col>
      )}
      {state.index !== 1 && (
        <Col xs={5} override={{ col: classes.alignRight }}>
          <Button
            width="100px"
            primary
            disabled={!(state.index < state.lockedUntil)}
            onClick={() => {
              setState({ ...state, index: state.index + 1 });
            }}
          >
            {i18n.get("next")}
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default Card;

import { Props, State, initialState } from "./State";
import * as Mutations from "./Mutations";
import useSaveState from "../../SaveState";

export type { Props, State } from "./State";

export interface Actions {
  setValues: (values: State["values"]) => void;
  setArtist: (id?: string, name?: string) => void;
  save: () => Promise<{
    id: string;
    title: string;
    artistId: string;
    artist: string;
    collection: string;
  } | void>;
  pending: () => void;
  reset: () => void;
}

export function useArtworkAdd(props: Props): [State, Actions] {
  const [state, setState] = useSaveState(initialState(props));

  return [
    state,
    {
      setValues: (values) => {
        Mutations.setValues({ values }, state, setState);
      },
      setArtist: (id, name) => {
        Mutations.setArtist({ id, name }, state, setState);
      },
      save: async () => {
        const id = await Mutations.add(state, setState);

        if (id) {
          const artwork = {
            id: id || "",
            title: state.values.title || "",
            artistId: state.values.artistId || "",
            artist: state.artistName || "",
            collection: state.collection,
          };

          return artwork;
        }
      },
      pending: () => Mutations.setPending(state, setState),
      reset: () => Mutations.reset(props, state, setState),
    },
  ];
}

import { AdminPage, ITheme, useClasses, useI18n } from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrowser } from "@fortawesome/pro-light-svg-icons";
import { useWebsite } from "../../Hooks/Website/Edit";
import DeactivatedWebsite from "./Deactivated";
import EditWebsite from "./Edit";

export interface IProps {
  team: string;
}

const styles = (theme: ITheme) => ({
  icon: {
    fontSize: "4rem",
    margin: "0px 16px 0px 0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
      margin: "0px 4px 0px 0px",
    },
  },
});

export default function Artworks(props: IProps) {
  const classes = useClasses(styles);
  const i18n = useI18n("website");
  const [state, actions] = useWebsite({ team: props.team });

  if (state.data === null)
    return <DeactivatedWebsite state={state} actions={actions} />;

  if (state.data !== null)
    return <EditWebsite state={state} actions={actions} />;

  return (
    <AdminPage
      title={i18n.get("website")}
      state={state.status === "init" ? "pending" : state.status}
      icon={<FontAwesomeIcon className={classes.icon} icon={faBrowser} />}
      breadcrumbs={[{ label: i18n.get("website") }]}
      override={{ overlay: classes.overlay, main: classes.main }}
    ></AdminPage>
  );
}

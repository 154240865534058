import React, { useState } from "react";

import {
  Row,
  Col,
  Button,
  TextInput,
  Form,
  DateInput,
  Typo,
  useI18n,
  StateHandler,
} from "@maxeb/admin-ui";

export interface IProps {
  add: (
    location: string,
    date: [string, string, string],
    description: string
  ) => Promise<boolean> | void;
  onClose: () => void;
}

function createAddressString(data: (string | undefined)[]) {
  return data.filter((value) => Boolean(value)).join(", ");
}

const EditLocation: React.FC<IProps> = (props) => {
  const i18n = useI18n("artworks_location");
  const i18nErrors = useI18n("errors");

  const [country, setCountry] = useState<string | undefined>();
  const [address, setAddress] = useState<string | undefined>();
  const [addressError, setAddressError] = useState<string>();

  const [state, setState] = useState<"ok" | "pending">("ok");

  const [place, setPlace] = useState<string | undefined>();
  const today = new Date();
  const [fullDate, setDate] = useState<[string, string, string]>([
    `${today.getDate()}`,
    `${today.getMonth() + 1}`,
    `${today.getFullYear()}`,
  ]);

  const [dateError, setDateError] = useState<string>();

  const [description, setDescription] = useState<string | undefined>();

  return (
    <StateHandler state={state}>
      <Form
        vertical
        onSubmit={(e) => {
          e.preventDefault();
        }}
        spacing={16}
        root={{ top: 0, left: 0, right: 0, bottom: 0 }}
        verticalAlign={"top"}
        horizontalAlign="center"
      >
        <Col lg={10}>
          <Row spacing={16} vertical horizontalAlign="right" root={0}>
            <Col>
              <Typo variant="p">{i18n.get("desc")}</Typo>
            </Col>
            <TextInput
              xs={7}
              inline
              label={i18n.get("address")}
              value={address}
              error={addressError && i18nErrors.get(addressError)}
              onChange={(value) => {
                setAddress(value);
              }}
            />
            <TextInput
              inline
              lg={3}
              label={i18n.get("city")}
              value={place}
              onChange={(value) => setPlace(value)}
            />
            <TextInput
              inline
              lg={5}
              label={i18n.get("country")}
              value={country}
              onChange={(value) => setCountry(value)}
            />
            <DateInput
              inline
              lg={5}
              label={i18n.get("date")}
              value={fullDate}
              error={dateError && i18nErrors.get(dateError)}
              onChange={(value) => {
                if (value) setDate(value);
              }}
            />
            <TextInput
              inline
              label={i18n.get("description")}
              rows={4}
              value={description}
              onChange={(value) => {
                setDescription(value);
              }}
            />
            <Button
              xs="120px"
              onClick={() => {
                props.onClose();
              }}
            >
              {i18n.get("cancel")}
            </Button>
            <Button
              xs="120px"
              success
              onClick={async () => {
                const isDate = !isNaN(
                  +new Date(`${fullDate[2]}-${fullDate[1]}-${fullDate[0]}`)
                );

                if (!address) setAddressError("notSet");

                if (!isDate) setDateError("notDate");

                if (isDate && address) {
                  setDateError(undefined);
                  setState("pending");
                  const result = await props.add(
                    createAddressString([`${address}`, place, country]),
                    fullDate,
                    description || ""
                  );
                  setState("ok");
                  if (result) {
                    props.onClose();
                  }
                }
              }}
            >
              {i18n.get("add")}
            </Button>
          </Row>
        </Col>
      </Form>
    </StateHandler>
  );
};

export default EditLocation;

import { ICollection } from "@maxeb/art-sdk";
import { State, initialState } from "./State";
import * as Mutations from "./Mutations";
import useSaveState from "../../SaveState";

export type { State } from "./State";
export interface Actions {
  pending: () => void;
  reload: () => void;
  reset: () => void;
  setValues: (values: State["values"]) => void;
  save: () => Promise<ICollection | void>;
}

export function useCollectionAdd(): [State, Actions] {
  const [state, setState] = useSaveState(initialState());

  return [
    state,
    {
      pending: () => {
        Mutations.setPending(state, setState);
      },
      reload: () => {
        Mutations.reload(state, setState);
      },
      reset: () => {
        Mutations.reset(state, setState);
      },
      setValues: (values) => {
        Mutations.setValues({ values }, state, setState);
      },
      save: () => {
        return Mutations.save(state, setState);
      },
    },
  ];
}

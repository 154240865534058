import React, { useState } from "react";
import "./Assets/styles/app.css";
import Artworks from "./Components/Artworks/List";
import ArtworkEdit from "./Components/Artworks/Edit";
import {
  useSession,
  SingelPageAuthLayout as SinglePageAuthLayout,
  I18nProvider,
  AdminLayout,
  Routes,
  Route,
  Router,
  StateHandler,
  useClasses,
  ITheme,
} from "@maxeb/admin-ui";
import Settings from "./Components/Account/Settings";
import Artists from "./Components/Artists/List";
import ArtistEdit from "./Components/Artists/Edit";
import Logo from "./Components/Shared/Logo";
import TopBar from "./Components/Shared/TopBar";
import Navigation from "./Components/Shared/Navigation";
import TeamSettings from "./Components/Account/TeamSettings";
import Collections from "./Components/Collections";
import CollectionsEdit from "./Components/Collections/Edit";
import TeamGuard from "./Components/Account/TeamGuard";
import Import from "./Components/Import";
import Website from "./Components/Website";

const styles = (theme: ITheme) => ({
  app: {
    height: "100vh",
    "@media print": {
      height: "auto",
    },
  },
  overlay: {
    paddingTop: "120px !important",
  },
});

interface IApp {}
const App: React.FC<IApp> = (props) => {
  const classes = useClasses(styles);
  const [session, setSession] = useSession(null);
  const [state, setState] = useState<"ok" | "pending" | "error">("ok");
  const [showSideBar, setShowSidebar] = useState(false);
  //fix this has to be some typescript issue with wrapper components of router
  return (
    <div
      className={classes.app}
      style={{ backgroundColor: session === null ? "#212a37" : "" }}
    >
      <StateHandler state={state} override={{ overlay: classes.overlay }}>
        <Router>
          <I18nProvider
            language="en"
            pathMap={{ de: "/i18n/de.json", en: "/i18n/en.json" }}
            pending={<div>Pending</div>}
            error={<div>Error</div>}
          >
            {session === null && (
              <SinglePageAuthLayout session={session} setSession={setSession}>
                <Logo langSwap color="#fff" size={130} />
              </SinglePageAuthLayout>
            )}
            {session !== null && (
              <AdminLayout
                showSideBar={showSideBar}
                setShowSidebar={setShowSidebar}
                sidebar={<Navigation setShowSidebar={setShowSidebar} />}
                topbar={
                  <TopBar
                    user={session}
                    setSession={setSession}
                    setState={setState}
                  />
                }
              >
                <TeamGuard session={session} setSession={setSession}>
                  <Routes>
                    <Route path="/">
                      <Artworks team={session.currentTeam || ""} />
                    </Route>
                    <Route path="/artworks">
                      <Artworks team={session.currentTeam || ""} />
                    </Route>
                    <Route path="/artworks/$collection/$id">
                      <ArtworkEdit team={session.currentTeam || ""} />
                    </Route>
                    <Route path="/artists">
                      <Artists team={session.currentTeam || ""} />
                    </Route>
                    <Route path="/artists/$id">
                      <ArtistEdit team={session.currentTeam || ""} />
                    </Route>
                    <Route path="/collections">
                      <Collections />
                    </Route>
                    <Route path="/collections/$id">
                      <CollectionsEdit />
                    </Route>
                    <Route path="/import">
                      <Import team={session.currentTeam || ""} />
                    </Route>
                    <Route path="/website">
                      <Website team={session.currentTeam || ""} />
                    </Route>
                    <Route path="/user/settings">
                      <Settings user={session} />
                    </Route>
                    <Route path="/team/settings">
                      <TeamSettings user={session} setSession={setSession} />
                    </Route>
                  </Routes>
                </TeamGuard>
              </AdminLayout>
            )}
          </I18nProvider>
        </Router>
      </StateHandler>
    </div>
  );
};

export default App;

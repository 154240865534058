import React, { useState } from "react";
import { ITheme, useClasses, Row, Col, Button } from "@maxeb/admin-ui";

import { Artist, IArtistSet } from "@maxeb/art-sdk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faTrash } from "@fortawesome/pro-light-svg-icons";
import {
  faSpinnerThird,
  faExclamationTriangle,
} from "@fortawesome/pro-duotone-svg-icons";

export interface IProps {
  id: string;
  value: string;
  onDelete: () => void;
}
export interface IState {
  status: "ok" | "pending" | "success" | "error";
  confirmDelete: boolean;
}

const styles = (theme: ITheme) => ({
  listText: {
    paddingTop: "7px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  eduTime: {
    marginRight: "8px",
  },
  spinner: {
    fontSize: "32px",
    color: theme.palette.get("primary"),
    marginLeft: "4px",
  },
  error: {
    fontSize: "32px",
    color: theme.palette.get("danger"),
    marginLeft: "4px",
  },
});

async function remove(
  id: string,
  value: string,
  state: IState,
  setState: (state: IState) => void,
  onDelete: () => void
) {
  setState({ ...state, status: "pending", confirmDelete: false });

  try {
    const set: IArtistSet = {
      awardsExhibition: { "!delete": [value] },
    };

    const response = await Artist.update(set, { id });

    if (response.isSuccess()) {
      return onDelete();
    }
  } catch (error) {
    console.error(error);
  }

  return setState({ ...state, status: "error" });
}

const initialState: IState = {
  status: "ok",
  confirmDelete: false,
};

export default function Education(props: IProps) {
  const classes = useClasses(styles);
  const [state, setState] = useState<IState>({
    ...initialState,
  });

  const { confirmDelete } = state;

  const [from, ...name] = props.value.split("|");

  return (
    <Row root={{ top: 8, bottom: 8 }} override={{ row: classes.listElement }}>
      <Col
        xs={confirmDelete ? "calc(100% - 96px)" : "calc(100% - 40px)"}
        override={{ col: classes.listText }}
      >
        <span className={classes.eduTime}>{from}</span>{" "}
        <b className={classes.eduName}>{name}</b>
      </Col>
      {confirmDelete && (
        <Col xs="56px">
          <Button
            width="40px"
            danger
            onClick={() => {
              remove(props.id, props.value, state, setState, props.onDelete);
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
          </Button>
        </Col>
      )}
      {confirmDelete && (
        <Col xs="40px">
          <Button onClick={() => setState({ ...state, confirmDelete: false })}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </Col>
      )}
      {!confirmDelete && state.status !== "pending" && (
        <Col xs="40px">
          <Button
            danger
            onClick={() => setState({ ...state, confirmDelete: true })}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </Col>
      )}
      {!confirmDelete && state.status === "pending" && (
        <Col xs="40px">
          <FontAwesomeIcon
            className={classes.spinner}
            icon={faSpinnerThird}
            spin
          />
        </Col>
      )}
      {!confirmDelete && state.status === "error" && (
        <Col xs="40px">
          <FontAwesomeIcon
            className={classes.error}
            icon={faExclamationTriangle}
          />
        </Col>
      )}
    </Row>
  );
}

import { IArtwork } from "@maxeb/art-sdk";

export interface State {
  status: "init" | "ok" | "pending" | "error" | "success";
  confirmDelete: boolean;
  data: IArtwork;
  original: IArtwork;
  errors: { [key: string]: string };
  id: string;
  collection: string;
  onChange?: (artwork: IArtwork) => void;
}

export type SetState = (state: State) => void;
export interface Props {
  id: string;
  collection: string;
  onChange?: (artwork: IArtwork) => void;
}

export function initialState(props: Props): State {
  return {
    status: "init",
    confirmDelete: false,
    data: {},
    original: {},
    errors: {},
    id: props.id,
    collection: props.collection,
    onChange: props?.onChange,
  };
}

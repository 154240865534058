import React from "react";
import {
  Sidebar,
  SidebarSection,
  SidebarLink,
  useRouter,
  useI18n,
  ITheme,
  useClasses,
} from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaintbrush } from "@fortawesome/pro-light-svg-icons/faPaintbrush";
import { faInventory } from "@fortawesome/pro-light-svg-icons/faInventory";
import { faCog } from "@fortawesome/pro-light-svg-icons/faCog";
import classNames from "classnames";
import {
  faBrowser,
  faPalette,
  faUpload,
} from "@fortawesome/pro-light-svg-icons";
import Logo from "./Logo";

export interface INavigation {
  onClose?: () => void;
  setShowSidebar?: (to: boolean) => void;
}

const styles = (theme: ITheme) => ({
  languageBtn: {
    color: theme.palette.get("grey"),
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "1rem",
    margin: "0px 0px 0px 0px",
    padding: "8px",
    cursor: "pointer",
    width: "40px",
    height: "40px",
    textAlign: "center",
    "&:hover": {
      borderRadius: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
  },
  seperator: {
    margin: "0px 8px",
    color: theme.palette.get("grey"),
    fontSize: "1rem",
  },
  active: {
    color: "#fff",
    cursor: "default",
    borderRadius: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
});

export default function Navigation(props: INavigation) {
  const [router] = useRouter();
  const currentPath = router.path;
  const i18n = useI18n("navigation");
  const classes = useClasses(styles);

  const content = [
    {
      icon: faPaintbrush,
      label: i18n.get("artwork"),
      path: "/artworks",
    },
    {
      icon: faPalette,
      label: i18n.get("artist"),
      path: "/artists",
    },
    {
      icon: faInventory,
      label: i18n.get("collection"),
      path: "/collections",
    },
    {
      icon: faUpload,
      label: i18n.get("import"),
      path: "/import",
    },
    {
      icon: faBrowser,
      label: i18n.get("website"),
      path: "/website",
    },
    {
      icon: faCog,
      label: i18n.get("settings"),
      path: "/user/settings",
    },
  ];

  return (
    <Sidebar logo={<Logo size={34} color="#fff" />} onClose={props.onClose}>
      <SidebarSection label={i18n.get("navigation")}>
        {content.map((link, linkPos) => (
          <SidebarLink
            active={currentPath === link.path}
            icon={<FontAwesomeIcon icon={link.icon} />}
            to={link.path}
            key={"content-" + linkPos}
            onClick={() => {
              if (props.setShowSidebar) props.setShowSidebar(false);
            }}
          >
            {link.label}
          </SidebarLink>
        ))}
      </SidebarSection>
      <SidebarSection label={i18n.get("language")}>
        <button
          className={classNames([
            classes.languageBtn,
            i18n.language === "de" && classes.active,
          ])}
          onClick={() => {
            if (i18n.language !== "de") i18n.load("de");
          }}
        >
          DE
        </button>
        <span className={classes.seperator}>|</span>
        <button
          className={classNames([
            classes.languageBtn,
            i18n.language === "en" && classes.active,
          ])}
          onClick={() => {
            if (i18n.language !== "en") i18n.load("en");
          }}
        >
          EN
        </button>
      </SidebarSection>
    </Sidebar>
  );
}

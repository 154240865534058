import React, { useEffect, useState } from "react";
import { ITheme, useClasses, Typo } from "@maxeb/admin-ui";
import { IDP } from "@maxeb/idp-sdk";
import classNames from "classnames";
import { ITeam } from "@maxeb/idp-sdk/dist/modules/IDP";

export interface IProps {
  id: string;
  selected: boolean;
  onSelected: () => void;
}

const styles = (theme: ITheme) => ({
  col: {
    margin: "-8px -16px",
    padding: "16px 16px",
  },
  hover: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.get("grey"),
    },
  },
  selected: {
    backgroundColor: theme.palette.get("primary"),
    color: "#fff !important",
    "&:hover": {
      backgroundColor: theme.palette.get("primary"),
    },
  },
  titlePlaceHolder: {
    height: "1.5rem",
    width: "150px",
    backgroundColor: theme.palette.get("grey"),
    borderRadius: "4px",
  },
  descriptionPlaceHolder: {
    height: "1.5rem",
    backgroundColor: theme.palette.get("grey"),
    borderRadius: "4px",
    marginTop: "8px",
  },
});

let MOUNTED = false;

export default function TeamListItem(props: IProps) {
  const classes = useClasses(styles);
  const [state, setState] = useState<
    "init" | "success" | "ok" | "pending" | "error"
  >("init");
  const [values, setValues] = useState<ITeam>({});

  const { selected, onSelected } = props;

  useEffect(() => {
    MOUNTED = true;
    if (state === "init") {
      const getTeam = async () => {
        setState("pending");
        try {
          const result = await IDP.getTeam({
            id: props.id,
          });
          if (MOUNTED) {
            setValues(result.getResult().data);
            setState("ok");
          }
        } catch (err) {
          if (MOUNTED) setState("error");
        }
      };
      getTeam();
    }
    return () => {
      MOUNTED = false;
    };
  }, [state, props.id]);

  if (state === "error") {
    return <></>;
  }

  return (
    <div
      className={classNames([
        classes.col,
        state === "ok" && classes.hover,
        selected && classes.selected,
      ])}
      onClick={() => {
        state === "ok" && onSelected();
      }}
    >
      {state !== "ok" && (
        <>
          <div className={classes.titlePlaceHolder} />
          <div className={classes.descriptionPlaceHolder} />
        </>
      )}
      {state === "ok" && (
        <>
          <Typo variant="p" margin="none">
            {values.name}
          </Typo>
          <Typo
            override={{
              className: classNames(selected && classes.selected),
            }}
            variant="info"
            margin="none"
          >
            {values.description}
          </Typo>
        </>
      )}
    </div>
  );
}

import { ICollection } from "@maxeb/art-sdk";

export interface State {
  id: string;
  status: "init" | "ok" | "pending" | "error" | "success";
  confirmDelete: boolean;
  data: ICollection;
  errors: { [key: string]: string };
  onChange?: (collection: ICollection) => void;
}
export type SetState = (state: State) => void;
export interface Props {
  id: string;
  onChange?: (collection: ICollection) => void;
}
export function getInitialState(props: Props): State {
  const { id, onChange } = props;

  return {
    id: id,
    status: "init",
    confirmDelete: false,
    data: {},
    errors: {},
    onChange,
  };
}

import { Artwork, IArtworkSet } from "@maxeb/art-sdk";
import { State, SetState } from "./State";
import { getSet } from "./Helper";

export async function get(state: State, setState: SetState) {
  setState({ ...state, status: "pending" });
  try {
    const result = await Artwork.get(
      {
        id: state.id,
        collection: state.collection,
      },
      [
        "id",
        "thumb",
        "images",
        "mockup",
        "documents",
        "title",
        "artist",
        "artistId",
        "customId",
        "themeId",
        "categoryId",
        "techniqueId",
        "year",
        "width",
        "height",
        "depth",
        "weight",
        "description",
        "signed",
        "dated",
        "frame",
        "available",
        "color",
        "reservedFrom",
        "reservedUntil",
        "reservedFor",
        "reservedReason",
        "sellingPrice",
        "proprietor",
        "source",
        "purchasingPrice",
        "purchasingDate",
        "owner",
        "condition",
        "location",
        "insurer",
        "insurerPolicyNumber",
        "insurerValue",
        "team",
        "collection",
      ],
      20
    );

    if (result.isSuccess() && result.body.data.length === 1) {
      const data = result.body.data[0];
      setState({ ...state, status: "ok", data, original: data });
      if (state.onChange) state.onChange(data);
      return data;
    }
  } catch (err) {
    console.error(err);
  }
  setState({ ...state, status: "error" });
}
export function setOk(state: State, setState: SetState) {
  setState({
    ...state,
    status: "ok",
  });
}
export function setPending(state: State, setState: SetState) {
  setState({
    ...state,
    status: "pending",
  });
}
export function setReload(state: State, setState: SetState) {
  setState({
    ...state,
    status: "success",
  });
  setTimeout(() => {
    setState({
      ...state,
      status: "init",
    });
  }, 1000);
}
export function setData(
  params: { data: State["data"] },
  state: State,
  setState: SetState
) {
  const { data } = params;
  setState({
    ...state,
    data,
  });
}

export async function save(
  props: { set: IArtworkSet; navigate?: (to: string) => void },
  state: State,
  setState: SetState
) {
  setState({ ...state, status: "pending" });

  const { set, navigate } = props;

  try {
    const { id, data } = state;

    const response = await Artwork.update(set, {
      id,
      collection: data.collection || "",
    });

    if (response.isSuccess()) {
      setState({ ...state, status: "success" });
      if (set.collection && navigate)
        navigate(`/artworks/${set.collection}/${id}`);

      return setTimeout(() => {
        setState({
          ...state,
          status: "init",
          collection: set.collection || state.collection,
        });
      }, 1000);
    } else {
      const errors = response.getErrors({
        "body.artistId": "artistId",
        "body.title": "title",
        "body.customId": "customId",
        "body.themeId": "themeId",
        "body.categoryId": "categoryId",
        "body.techniqueId": "techniqueId",
        "body.year": "year",
        "body.width": "width",
        "body.height": "height",
        "body.depth": "depth",
        "body.size": "size",
        "body.color": "color",
        "body.weight": "weight",
        "body.description": "description",
        "body.signed": "signed",
        "body.dated": "dated",
        "body.framed": "framed",
        "body.reservedFor": "reservedFor",
        "body.reservedFrom": "reservedFrom",
        "body.reservedReason": "reservedReason",
        "body.reservedUntil": "reservedUntil",
        "body.sellingPrice": "sellingPrice",
        "body.insurer": "insurer",
        "body.insurerPolicyNumber": "insurerPolicyNumber",
        "body.insurerValue": "insurerValue",
        "body.proprietor": "proprietor",
        "body.purchasingDate": "purchasingDate",
        "body.purchasingPrice": "purchasingPrice",
        "body.source": "source",
        "body.owner": "owner",
        "body.location": "location",
        "body.condition": "condition",
        "body.collection": "collection",
      });

      if (Object.keys(errors).length)
        return setState({ ...state, status: "ok", errors });
    }
  } catch (error) {
    console.error(error);
  }

  return setState({ ...state, status: "error" });
}
export async function saveData(state: State, setState: SetState) {
  const set: IArtworkSet = getSet(state);
  return save({ set }, state, setState);
}
export async function remove(
  props: { navigate: (to: string) => void },
  state: State,
  setState: SetState
) {
  const { navigate } = props;

  const newState = { ...state, confirmDelete: false };
  setState({ ...newState, status: "pending" });

  try {
    const response = await Artwork.remove({
      id: state.id,
      collection: state.collection,
    });

    if (response.isSuccess()) {
      setState({ ...newState, status: "success" });
      return setTimeout(() => {
        navigate("/artworks");
      }, 1000);
    }
  } catch (error) {
    console.error(error);
  }

  return setState({ ...newState, status: "error" });
}

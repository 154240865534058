import React, { useState } from "react";
import {
  ITheme,
  useClasses,
  Form,
  StateHandler,
  useI18n,
} from "@maxeb/admin-ui";

import { Artwork } from "@maxeb/art-sdk";
import ImageHandler from "../../Shared/ImageHandler";

export interface IProps {
  id: string;
  collection: string;
  thumb?: { hash: string; name: string; url: string };
  setPending: () => void;
  setSuccess: () => void;
}

export interface IState {
  status: "ok" | "pending" | "success" | "error";
}

const styles = (theme: ITheme) => ({
  alignCenter: {
    textAlign: "center",
  },
});

async function uploadOrDelete(
  value: string | undefined,
  props: IProps,
  state: IState,
  setLocalState: (state: IState) => void
) {
  props.setPending();
  const isUpload = Boolean(value);

  try {
    const result = await Artwork.update(
      { thumb: isUpload ? value : null },
      { id: props.id, collection: props.collection }
    );
    if (result.isSuccess()) {
      props.setSuccess();
    } else setLocalState({ ...state, status: "error" });
  } catch (error) {
    setLocalState({ ...state, status: "error" });
  }
}

async function crop(
  img: string,
  props: IProps,
  state: IState,
  setLocalState: (state: IState) => void
) {
  if (props.thumb) {
    props.setPending();

    const name = props.thumb.name.substring(0, props.thumb.name.length - 41);
    const delimiter = img.indexOf(",");
    const extensionDelimiter = props.thumb.name.lastIndexOf(".");
    const croppedThumb =
      name +
      props.thumb.name.substring(extensionDelimiter) +
      img.substring(delimiter);

    try {
      const resultDelete = await Artwork.update(
        { thumb: null },
        { id: props.id, collection: props.collection }
      );
      if (resultDelete.isSuccess()) {
        const result = await Artwork.update(
          { thumb: croppedThumb },
          { id: props.id, collection: props.collection }
        );
        if (result.isSuccess()) return props.setSuccess();
        else console.error("Thumb upload failed!");
      } else console.error("Delete failed!");
    } catch (error) {
      console.error(error);
    }
    setLocalState({ ...state, status: "error" });
  }
}

export default function Thumb(props: IProps) {
  const classes = useClasses(styles);
  const i18n = useI18n("artwork_images");
  const [state, setState] = useState<IState>({ status: "ok" });

  return (
    <StateHandler state={state.status}>
      <Form>
        <ImageHandler
          value={props.thumb}
          onChange={(value) => {
            uploadOrDelete(value, props, state, setState);
          }}
          height="335px"
          onCrop={(value) => {
            crop(value, props, state, setState);
          }}
          getNewSource={async () => {
            const result = await Artwork.get(
              { collection: props.collection, id: props.id },
              ["thumb", "id", "team"],
              1
            );
            if (result.isSuccess()) {
              const artwork = result.getResult().data[0];
              return (artwork.thumb || {}).url;
            }
          }}
        >
          <div className={classes.alignCenter}>
            <b>{i18n.get("thumb")}</b>
            <br />
            {i18n.get("upload")}
          </div>
        </ImageHandler>
      </Form>
    </StateHandler>
  );
}

import React from "react";
import {
  Form,
  TextInput,
  NumberInput,
  DateInput,
  useI18n,
} from "@maxeb/admin-ui";

import { IArtwork } from "@maxeb/art-sdk";
import { State as IParentState } from "../../../Hooks/Artworks/Edit";

export interface IProps {
  data: IArtwork;
  errors: { [key: string]: string };
  onChange: (data: IParentState["data"]) => void;
}

function getDate(value?: string): [string, string, string] {
  if (value) {
    const [year, month, day] = value.split("-");
    return [day, month, year];
  } else return ["", "", ""];
}

export default function Proprietor(props: IProps) {
  const { data } = props;
  const i18n = useI18n("artwork_proprietor");

  return (
    <Form spacing={16} vertical root={{ top: 2, left: 0, right: 0, bottom: 0 }}>
      <TextInput
        lg={5}
        print={10 / 3}
        label={i18n.get("proprietor")}
        value={data.proprietor}
        onChange={(value) =>
          props.onChange({
            ...data,
            proprietor: value,
          })
        }
      />
      <TextInput
        lg={5}
        print={10 / 3}
        label={i18n.get("source")}
        value={data.source}
        onChange={(value) =>
          props.onChange({
            ...data,
            source: value,
          })
        }
      />
      <NumberInput
        lg={5}
        print={10 / 3}
        label={i18n.get("purchase_price")}
        value={data.purchasingPrice}
        onChange={(value) =>
          props.onChange({
            ...data,
            purchasingPrice: value,
          })
        }
      />
      <DateInput
        lg={5}
        print={10 / 3}
        label={i18n.get("purchase_date")}
        value={getDate(data.purchasingDate)}
        onChange={(value) =>
          props.onChange({
            ...data,
            purchasingDate: value
              ? `${value[2]}-${value[1]}-${value[0]}`
              : value,
          })
        }
      />
      <TextInput
        lg={10 / 3}
        print={10 / 3}
        label={i18n.get("insurer")}
        value={data.insurer}
        onChange={(value) =>
          props.onChange({
            ...data,
            insurer: value,
          })
        }
      />
      <TextInput
        lg={10 / 3}
        print={10 / 3}
        label={i18n.get("policynumber")}
        value={data.insurerPolicyNumber}
        onChange={(value) =>
          props.onChange({
            ...data,
            insurerPolicyNumber: value,
          })
        }
      />
      <NumberInput
        lg={10 / 3}
        print={10 / 3}
        label={i18n.get("insurance_value")}
        value={data.insurerValue}
        onChange={(value) =>
          props.onChange({
            ...data,
            insurerValue: value,
          })
        }
      />
    </Form>
  );
}

import { IArtwork, ICollection } from "@maxeb/art-sdk";

export interface ArtworkWIthIndex extends IArtwork {
  index: number;
}

export interface FullCollection extends ICollection {
  status: "init" | "ok" | "pending" | "error" | "success";
  fullyLoaded?: boolean;
  artworksData: ArtworkWIthIndex[];
  index: number;
  artworksNext?: string;
}

export interface State {
  status: "init" | "ok" | "pending" | "error" | "success";
  search: {
    open: boolean;
    currentLocation?: string;
    artist?: string;
    artistId?: string;
    categoryId?: string;
    collection: string;
    price: number;
    size: number;
    color?: string;
    proprietor?: string;
    location?: string;
    text?: string;
    sorting: number;
  };
  searchChecksum?: string;
  data: FullCollection[];
  next?: string;
  active: IArtwork | null;
  activeIndex: [number, number] | null;
  team: string;
}
export type SetState = (state: State) => void;
export interface Props {
  team: string;
  searchParams?: State["search"];
  onFinished?: () => void;
}
export function searchInit(): State["search"] {
  try {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const plainQuery = urlParams.get("q");

    if (plainQuery) {
      const jsonString = decodeURIComponent(plainQuery);
      const query = JSON.parse(jsonString);
      return query;
    }
  } catch (err) {
    console.error("Could not read deep link query param:", err);
  }

  return {
    open: false,
    collection: "all",
    price: 0,
    size: 0,
    sorting: 1,
  };
}
export function initialState(props: Props): State {
  const { searchParams, team } = props;

  return {
    status: "init",
    search: searchParams || searchInit(),
    searchChecksum: JSON.stringify(searchParams),
    data: [],
    active: null,
    activeIndex: null,
    team: team,
  };
}

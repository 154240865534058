import { IDP, ISession } from "@maxeb/idp-sdk";
import { SetState, State } from "./State";

export function reload(state: State, setState: SetState) {
  setState({
    ...state,
    status: "success",
  });
  setTimeout(() => {
    setState({
      ...state,
      status: "init",
    });
  }, 1000);
}

export async function get(state: State, setState: SetState) {
  setState({ ...state, status: "pending" });
  try {
    const result = await IDP.getTeam({
      id: state.id || "",
    });

    if (result.isSuccess()) {
      const data = result.body.data;
      setState({ ...state, status: "ok", data });
      return data;
    }
  } catch (err) {
    console.error(err);
  }
  setState({ ...state, status: "error" });
}

export async function save(state: State, setState: SetState) {
  setState({ ...state, status: "pending" });
  try {
    const result = await IDP.updateTeam(
      { name: state.data.name, description: state.data.description },
      {
        id: state.id,
      }
    );

    if (result.isSuccess()) {
      return reload(state, setState);
    } else {
      const errors = result.getErrors({
        "body.name": "name",
        "body.description": "description",
      });
      return setState({ ...state, status: "ok", errors });
    }
  } catch (err) {
    setState({ ...state, status: "error" });
  }
}

export async function remove(
  props: { user: ISession; setSession: (session: ISession | null) => void },
  state: State,
  setState: SetState
) {
  setState({ ...state, status: "pending" });
  try {
    const isOwner = props.user.id === state.data.owner;

    const result = isOwner
      ? await IDP.removeTeam({
          id: state.id || "",
        })
      : await IDP.leaveTeam({
          id: state.id || "",
        });

    if (result.isSuccess()) {
      //setState("success");
      setTimeout(() => {
        //setState("ok");
        let newSession = { ...props.user };
        delete newSession.currentTeam;
        delete newSession.currentTeamName;
        newSession.teams = (newSession.teams || []).filter((item) => {
          return item !== props.user.currentTeam;
        });
        props.setSession(newSession);
      }, 1000);
    } else {
      const errors = result.getErrors({});

      setState({ ...state, errors, status: "ok" });
    }
  } catch (err) {
    //if (MOUNTED) setState("error");
  }
}

export async function invite(
  props: { email: string },
  state: State,
  setState: SetState
) {
  setState({ ...state, status: "pending" });
  try {
    const { email } = props;

    const result = await IDP.updateTeam(
      { invites: { "!add": [email] } },
      {
        id: state.id,
      }
    );

    if (result.isSuccess()) {
      return reload(state, setState);
    } else {
      const errors = result.getErrors({
        "body.users.0": "email",
      });
      return setState({ ...state, status: "ok", errors });
    }
  } catch (err) {
    setState({ ...state, status: "error" });
  }
}

export async function uninvite(
  props: { id: string },
  state: State,
  setState: SetState
) {
  setState({ ...state, status: "pending" });
  try {
    const { id } = props;

    const result = await IDP.updateTeam(
      { invites: { "!delete": [id] } },
      {
        id: state.id,
      }
    );

    if (result.isSuccess()) {
      return reload(state, setState);
    } else {
      const errors = result.getErrors({
        "body.users.0": "email",
      });
      return setState({ ...state, status: "ok", errors });
    }
  } catch (err) {
    setState({ ...state, status: "error" });
  }
}

export async function removeUser(
  props: { id: string },
  state: State,
  setState: SetState
) {
  setState({ ...state, status: "pending" });
  try {
    const { id } = props;
    const result = await IDP.updateTeam(
      { users: { "!delete": [id] } },
      {
        id: state.id,
      }
    );

    if (result.isSuccess()) {
      return reload(state, setState);
    } else {
      const errors = result.getErrors({
        "body.users.0": "users",
      });
      return setState({ ...state, status: "ok", errors });
    }
  } catch (err) {
    setState({ ...state, status: "error" });
  }
}

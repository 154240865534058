import React, { useState } from "react";
import {
  Form,
  ITheme,
  Select,
  TextInput,
  useClasses,
  useI18n,
} from "@maxeb/admin-ui";

import CollectionSelect from "../../Collections/Select";
import ArtistSelect from "../../Artists/Select";
import { State as IArtworks } from "../../../Hooks/Artworks/List";

export interface IProps {
  respOpen: boolean;
  data: IArtworks["search"];
  onChange: (search: IArtworks["search"]) => void;
  team: string;
}

export interface IState {}

const styles = (theme: ITheme) => ({
  search: {
    height: "47px",
    backgroundColor: theme.palette.get("grey"),
    zIndex: 2,
    width: "100%",
    padding: "8px",
    boxSizing: "border-box",
    borderBottom: "1px solid #d9d9d9",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      padding: (props: IProps) => (!props.respOpen ? 0 : 16),
      backgroundColor: theme.palette.get("grey"),
      position: "absolute",
    },
  },
  form: {
    margin: "-4px 19px !important",
    [theme.breakpoints.down("md")]: {
      margin: "-4px !important",
    },
  },
  input: {
    backgroundColor: theme.palette.get("grey") + " !important",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    border: "1px solid " + theme.palette.get("darkGrey") + " !important",
  },
  label: {
    color: "black !important",
  },
  indicator: {
    borderColor: theme.palette.get("darkGrey") + " !important",
  },
  respVisible: {
    [theme.breakpoints.down("lg")]: {
      display: (props: IProps) =>
        !props.respOpen ? "none !important" : "auto",
    },
  },
});

const inputDelay = 500;
let textTimeout: NodeJS.Timeout | null = null;

export default function Artworks(props: IProps) {
  const classes = useClasses(styles, props);

  const { data, onChange } = props;
  const [proprietor, setProprietor] = useState<string>(data.proprietor || "");
  const [location, setLocation] = useState<string>(data.location || "");
  const [text, setText] = useState<string>(data.text || "");

  const i18n = useI18n("artworks_search");

  return (
    <div className={classes.search}>
      <Form
        spacing={8}
        vertical
        root={0}
        override={{
          row: classes.form,
        }}
      >
        <CollectionSelect
          lg={10 / 8}
          allowAll
          onChange={(value) => {
            onChange({ ...data, collection: value || props.team });
          }}
          override={{
            col: !props.respOpen ? classes.respVisible : "",
            input: classes.input,
            label: classes.label,
          }}
        />
        <TextInput
          lg={10 / 8}
          label="Suche"
          value={text}
          inline
          onChange={(value) => {
            setText(value || "");

            if (textTimeout) clearTimeout(textTimeout);
            textTimeout = setTimeout(() => {
              onChange({ ...data, text: value });
            }, inputDelay);
          }}
          override={{
            col: !props.respOpen ? classes.respVisible : "",
            input: classes.input,
            label: classes.label,
          }}
        />
        <ArtistSelect
          lg={10 / 8}
          allowReset
          value={data.artist}
          onChange={(id, name) => {
            onChange({ ...data, artistId: id, artist: name });
          }}
          override={{
            col: !props.respOpen ? classes.respVisible : "",
            input: classes.input,
            label: classes.label,
          }}
        />
        <TextInput
          lg={10 / 8}
          label="Proprietor"
          value={proprietor}
          inline
          onChange={(value) => {
            setProprietor(value || "");

            if (textTimeout) clearTimeout(textTimeout);
            textTimeout = setTimeout(() => {
              onChange({ ...data, proprietor: value });
            }, inputDelay);
          }}
          override={{
            col: !props.respOpen ? classes.respVisible : "",
            input: classes.input,
            label: classes.label,
          }}
        />
        <TextInput
          lg={10 / 8}
          label="Location"
          value={location}
          inline
          onChange={(value) => {
            setLocation(value || "");

            if (textTimeout) clearTimeout(textTimeout);
            textTimeout = setTimeout(() => {
              onChange({ ...data, location: value });
            }, inputDelay);
          }}
          override={{
            col: !props.respOpen ? classes.respVisible : "",
            input: classes.input,
            label: classes.label,
          }}
        />
        <Select
          inline
          lg={10 / 8}
          label={i18n.get("all_prices")}
          value={data.price}
          options={[
            { label: i18n.get("all_prices"), value: 0 },
            { label: "0€ - 500€", value: 1 },
            { label: "500€ - 1.000€", value: 2 },
            { label: "1.000€ - 2.500€", value: 3 },
            { label: "2.500€ - 5.000€", value: 4 },
            { label: "5.000€ - 10.000€", value: 5 },
            { label: "10.000€ - 50.000€", value: 6 },
            { label: "ab 50.000€", value: 7 },
          ]}
          override={{
            col: !props.respOpen ? classes.respVisible : "",
            input: classes.input,
            label: classes.label,
          }}
          onChange={(value) => {
            if (typeof value === "number") onChange({ ...data, price: value });
          }}
        />
        <Select
          inline
          lg={10 / 8}
          label={i18n.get("all_sizes")}
          value={data.size}
          options={[
            { label: i18n.get("all_sizes"), value: 0 },
            { label: "0m - 2m " + i18n.get("scope"), value: 1 },
            { label: "2m - 4m " + i18n.get("scope"), value: 2 },
            { label: "4m - 8m " + i18n.get("scope"), value: 3 },
            { label: "ab 8m " + i18n.get("scope"), value: 4 },
          ]}
          override={{
            col: !props.respOpen ? classes.respVisible : "",
            input: classes.input,
            label: classes.label,
          }}
          onChange={(value) => {
            if (typeof value === "number") onChange({ ...data, size: value });
          }}
        />
        <Select
          inline
          lg={10 / 8}
          label={i18n.get("sorting")}
          value={data.sorting}
          options={[
            { label: i18n.get("available_first"), value: 14 },
            { label: i18n.get("sold_first"), value: 15 },
            { label: i18n.get("artist_dsc"), value: 0 },
            { label: i18n.get("artist_asc"), value: 1 },
            { label: i18n.get("weight_dsc"), value: 2 },
            { label: i18n.get("weight_asc"), value: 3 },
            { label: i18n.get("created_dsc"), value: 4 },
            { label: i18n.get("created_asc"), value: 5 },
            { label: i18n.get("size_dsc"), value: 6 },
            { label: i18n.get("size_asc"), value: 7 },
            { label: i18n.get("price_dsc"), value: 8 },
            { label: i18n.get("price_asc"), value: 9 },
            { label: i18n.get("title_dsc"), value: 10 },
            { label: i18n.get("title_asc"), value: 11 },
            { label: i18n.get("year_dsc"), value: 12 },
            { label: i18n.get("year_asc"), value: 13 },
            { label: i18n.get("customId_dsc"), value: 16 },
            { label: i18n.get("customId_asc"), value: 17 },
            { label: i18n.get("weight_dsc"), value: 18 },
            { label: i18n.get("weight_asc"), value: 19 },
          ].filter((item) =>
            data.artistId ? item.value === 18 || item.value === 19 : true
          )}
          override={{
            col: !props.respOpen ? classes.respVisible : "",
            input: classes.input,
            label: classes.label,
          }}
          onChange={(value) => {
            if (typeof value === "number")
              onChange({ ...data, sorting: value });
          }}
        />
      </Form>
    </div>
  );
}

import React, { useState } from "react";
import {
  Block,
  Col,
  Row,
  Typo,
  Form,
  TextInput,
  Button,
  AdminPage,
  ITheme,
  useClasses,
  useI18n,
  Table,
} from "@maxeb/admin-ui";
import LightBox from "../Shared/LightBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faPersonToDoor,
  faSave,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { ISession } from "@maxeb/idp-sdk";
import { useTeam } from "../../Hooks/Team/Edit";
import TeamMemberActions from "./TeamMemberActions";

const spacing = 16;

export interface IProps {
  user: ISession;
  translationGroup?: string;
  setSession: (session: ISession | null) => void;
}

const styles = (theme: ITheme) => ({
  overlay: {
    padding: "132px",
    backgroundColor: "rgba(236, 236, 236, 0.95) !important",
  },
  holder: { borderRadius: "100%", textAlign: "center" },
  userIcon: {
    fontSize: "3rem",
  },
  row: {
    width: "340px",
  },
  tableBlock: {},
  actions: {
    textAlign: "right",
  },
  actionsHead: {
    textAlign: "right",
    margin: "0px -8px",
  },
  inviteActions: {
    width: "10px",
  },
});

export default function Settings(props: IProps) {
  const classes = useClasses(styles);
  const i18n = useI18n(props.translationGroup || "authentication");
  const i18nErrors = useI18n("errors");

  const [addInvite, setAddInvite] = useState<boolean>(false);
  const [email, setEmail] = useState<string>();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [state, actions] = useTeam(props);

  const values = state.data;
  const errors = state.errors;

  const isOwner = props.user.id === state.data.owner;
  if (state.status === "init") return <div />;

  const hasInvites = values.invites && values.invites.length > 0;
  const hasUsers = values.users && values.users.length > 0;

  return (
    <AdminPage
      title={"Team"}
      state={state.status}
      breadcrumbs={[
        { label: i18n.get("account") },
        { label: i18n.get("team") },
      ]}
      actions={
        isOwner ? (
          <Row
            override={{ row: classes.row }}
            spacing={spacing}
            root={0}
            horizontalAlign="right"
          >
            <Button
              xs="110px"
              success
              onClick={() => {
                setAddInvite(true);
              }}
            >
              <FontAwesomeIcon icon={faPaperPlane} /> {i18n.get("invite")}
            </Button>
            <Button
              xs="110px"
              danger
              onClick={() => {
                setConfirmDelete(true);
              }}
            >
              <FontAwesomeIcon icon={faTrash} /> {i18n.get("delete")}
            </Button>
            <Button
              xs="120px"
              primary
              onClick={async () => {
                actions.save();
              }}
            >
              <FontAwesomeIcon
                style={{ margin: "0px 4px 0px 0px" }}
                icon={faSave}
              />
              {i18n.get("save")}
            </Button>
          </Row>
        ) : (
          <>
            <Row
              override={{ row: classes.row }}
              spacing={spacing}
              root={0}
              horizontalAlign="right"
            >
              <Button
                xs="120px"
                danger
                onClick={() => {
                  setConfirmDelete(true);
                }}
              >
                <FontAwesomeIcon icon={faPersonToDoor} /> {i18n.get("leave")}
              </Button>
            </Row>
          </>
        )
      }
      override={{ overlay: classes.overlay }}
    >
      <Row spacing={16} root={0}>
        <Col lg={7}>
          <Typo margin="0px 0px 8px 0px" variant="section">
            {i18n.get("team")}
          </Typo>
          <Block>
            <Form spacing={spacing} vertical root={0}>
              <TextInput
                inline
                readOnly={!isOwner}
                label={i18n.get("name")}
                value={values.name}
                onChange={(value) => {
                  actions.setData({ ...values, name: value });
                }}
                error={errors.name && i18nErrors.get(errors.name)}
              />
              <TextInput
                inline
                readOnly={!isOwner}
                label={i18n.get("description")}
                value={values.description}
                onChange={(value) => {
                  actions.setData({ ...values, description: value });
                }}
                error={errors.description && i18nErrors.get(errors.description)}
                rows={6}
              />
            </Form>
          </Block>

          <Typo variant="section">{i18n.get("members")}</Typo>
          {!hasUsers && (
            <Typo margin="0px 0px 8px 0px" variant="p">
              {i18n.get("no_members")}
            </Typo>
          )}
          {hasUsers && (
            <Table
              columns={
                isOwner
                  ? [
                      { label: i18n.get("firstname"), field: "firstname" },
                      { label: i18n.get("lastname"), field: "lastname" },
                      {
                        label: "",
                        field: "action",
                        classes: classes.inviteActions,
                      },
                    ]
                  : [
                      { label: i18n.get("firstname"), field: "firstname" },
                      { label: i18n.get("lastname"), field: "lastname" },
                    ]
              }
              data={
                values.users?.map((item) => {
                  return {
                    ...item,
                    action: (
                      <TeamMemberActions
                        id={item.id}
                        firstname={item.firstname}
                        lastname={item.lastname}
                        actions={actions}
                      />
                    ),
                  };
                }) || []
              }
            />
          )}
          <Typo variant="section">{i18n.get("invites")}</Typo>
          {!hasInvites && (
            <Typo margin="0px 0px 8px 0px" variant="p">
              {i18n.get("no_invites")}
            </Typo>
          )}
          {hasInvites && (
            <Block override={{ wrapper: classes.tableBlock }}>
              <Table
                columns={[
                  { label: i18n.get("email"), field: "email" },
                  { label: i18n.get("status"), field: "state" },
                  {
                    label: "",
                    field: "action",
                    classes: classes.inviteActions,
                  },
                ]}
                data={(values.invites || []).map((item) => ({
                  email: item.email,
                  state: i18n.get(item.state),
                  action: (
                    <Button
                      danger
                      onClick={() => {
                        actions.uninvite(item.id);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  ),
                }))}
              />
            </Block>
          )}
        </Col>
      </Row>
      <LightBox
        open={confirmDelete}
        title={i18n.get(isOwner ? "delete_confirm" : "leave_confirm")}
        danger
        onClose={() => {
          setConfirmDelete(false);
        }}
      >
        {i18n
          .get(isOwner ? "delete_team_confirm" : "leave_team_confirm")
          .replace("###team###", `"${props.user.currentTeamName}"`)}
        <Row spacing={spacing} vertical root={0} horizontalAlign="right">
          <Button
            xs="100px"
            onClick={() => {
              setConfirmDelete(false);
            }}
          >
            Cancel
          </Button>
          <Button
            xs="100px"
            danger
            onClick={async () => {
              //setState("pending");
              setConfirmDelete(false);
              actions.remove();
            }}
          >
            {isOwner ? i18n.get("delete") : i18n.get("leave")}
          </Button>
        </Row>
      </LightBox>
      <LightBox
        primary
        open={addInvite}
        title={i18n.get("invite")}
        onClose={() => {
          setAddInvite(false);
        }}
      >
        <Form spacing={16} vertical root={0}>
          <Typo variant="info">{i18n.get("invite_info")}</Typo>
          <TextInput
            inline
            label="Email"
            value={email}
            onChange={setEmail}
            autoComplete="off"
          />
          <Row spacing={16} vertical root={0} horizontalAlign="right">
            <Button
              xs="100px"
              onClick={() => {
                setAddInvite(false);
              }}
            >
              Cancel
            </Button>
            <Button
              xs="100px"
              success
              onClick={async () => {
                setAddInvite(false);
                if (email) actions.invite(email);
              }}
            >
              Invite
            </Button>
          </Row>
        </Form>
      </LightBox>
    </AdminPage>
  );
}

import { faDownload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, ITheme, useClasses } from "@maxeb/admin-ui";
import QRCode from "qrcode";
import { useEffect, useState } from "react";

export interface IProps {
  id: string;
  collection: string;
  name?: string;
}

function downloadURI(uri: string, name: string) {
  const link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const style = (theme: ITheme) => ({
  alignCenter: { textAlign: "center" },
  img: {
    width: "100%",
  },
});

export default function QR(props: IProps) {
  const classes = useClasses(style);
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    if (!url) {
      QRCode.toDataURL(
        `https://link.webcollector.at/artwork/${props.collection}/${props.id}`,
        {},
        (err, url) => {
          if (err) return console.error(err);
          else {
            setUrl(url);
          }
        }
      );
    }
  }, [url, props.collection, props.id]);

  return (
    <Form horizontalAlign="center">
      <Col xs={"317px"}>
        <img className={classes.img} alt="qr-code" src={url} />
      </Col>
      <Button
        primary
        xs="120px"
        onClick={() => {
          if (url) {
            const fileName = `${
              (props.name ? props.name + "-" : "") + "qrCode"
            }`;
            downloadURI(url, fileName);
          }
        }}
      >
        <FontAwesomeIcon
          style={{ margin: "0px 8px 0px 0px" }}
          icon={faDownload}
        />
        Download
      </Button>
    </Form>
  );
}

import React, { useState } from "react";
import {
  Button,
  AdminPage,
  ITheme,
  useClasses,
  useI18n,
  Row,
  Col,
  Typo,
} from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch, faShelves } from "@fortawesome/pro-light-svg-icons";
import Add from "./Add";
import Card from "../Shared/Card";
import CollectionsEdit from "./Edit";
import IsVisble from "../Shared/IsVisible";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { useCollections } from "../../Hooks/Collections/List";
import Search from "./Search";

export interface IProps {}

const styles = (theme: ITheme) => ({
  main: {
    height: "calc(100vh - 251px) !important",
    [theme.breakpoints.down("md")]: {
      height: "calc(100vh - 123px) !important",
    },
  },
  overlay: {
    padding: "132px",
    backgroundColor: "rgba(236, 236, 236, 0.95) !important",
  },
  holder: { borderRadius: "100%", textAlign: "center" },
  userIcon: {
    fontSize: "3rem",
  },
  row: {
    [theme.breakpoints.up("md")]: {
      width: "200px",
    },
  },
  searchWrapper: {
    backgroundColor: theme.palette.get("grey"),
  },
  icon: {
    fontSize: "4rem",
    margin: "0px 16px 0px 0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
      margin: "0px 4px 0px 0px",
    },
  },
  select: { zIndex: "201 !important" },
  link: {
    textDecoration: "none",
  },
  pending: {
    color: theme.palette.get("primary"),
    fontSize: "5rem",
    textAlign: "center",
    padding: "32px",
  },
  noIcon: {
    fontSize: "5rem",
    backgroundColor: "#fff",
    borderRadius: "100%",
    padding: "32px",
  },
});

export default function Collections(props: IProps) {
  const classes = useClasses(styles);
  const i18n = useI18n("collection");

  const [searchOpen, setSearchOpen] = useState(false);
  const [add, setAdd] = useState<boolean>(false);
  const [collections, actions] = useCollections();
  const { status, data } = collections;

  return (
    <AdminPage
      icon={<FontAwesomeIcon className={classes.icon} icon={faShelves} />}
      title={i18n.get("collections")}
      state={
        status === "init" ? "pending" : status === "pending" ? "ok" : status
      }
      breadcrumbs={[
        { label: i18n.get("collections") },
        { label: i18n.get("all") },
      ]}
      actions={
        <Row
          override={{ row: classes.row }}
          spacing={16}
          root={0}
          horizontalAlign="right"
        >
          <Button
            xs="120px"
            md="hidden-up"
            primary
            onClick={() => {
              setSearchOpen(!searchOpen);
            }}
          >
            <FontAwesomeIcon
              style={{ margin: "0px 4px 0px 0px" }}
              icon={faSearch}
            />
            {searchOpen ? i18n.get("close") : i18n.get("search")}
          </Button>
          <Button
            xs="100px"
            success
            onClick={async () => {
              setAdd(true);
            }}
          >
            <FontAwesomeIcon
              style={{ margin: "0px 4px 0px 0px" }}
              icon={faPlus}
            />
            {i18n.get("add")}
          </Button>
        </Row>
      }
      override={{ overlay: classes.overlay, main: classes.main }}
      subhead={
        <Search
          respOpen={searchOpen}
          search={collections.search}
          setSearch={actions.search}
        />
      }
      overlayPages={
        collections.active !== null
          ? [
              <div className={classes.active}>
                <CollectionsEdit
                  id={collections.active.id || ""}
                  onChange={(collection) => actions.updateActive(collection)}
                  close={(isDeleted) => {
                    if (isDeleted) actions.updateActive(null);
                    else actions.active(null, null);
                  }}
                />
              </div>,
            ]
          : undefined
      }
    >
      <Add
        open={add}
        setOpen={setAdd}
        setNewActive={actions.setNewActive}
        reset={() => actions.reset()}
      />
      <Row spacing={16} vertical root={0}>
        {data.map((collection, index) => (
          <Col
            key={`collection-${collection.id}`}
            xs={10}
            sm={5}
            md={10 / 3}
            lg={10 / 4}
            xl={10 / 6}
          >
            <a
              className={classes.link}
              href={`/collections/${collection.id}`}
              onClick={(e) => {
                e.preventDefault();
                actions.active(collection, index);
                window.history.pushState(
                  {},
                  "",
                  `/collections/${collection.id}`
                );
              }}
            >
              <Card
                name={collection.name}
                icon={faShelves}
                thumb={
                  collection.thumb && {
                    ...collection.thumb,
                    url: collection.thumb["xs-url"],
                  }
                }
              />
            </a>
          </Col>
        ))}
        {status === "ok" && data.length === 0 && (
          <Row>
            <Col lg={5}>
              <Typo variant="h1">{i18n.get("no_collection")}</Typo>
              <Typo variant="p">{i18n.get("no_collection_desc")}</Typo>
              <Typo variant="h2">{i18n.get("why_collection")}</Typo>
              <ul>
                <li>{i18n.get("why_collection_li1")}</li>
                <li>{i18n.get("why_collection_li2")}</li>
              </ul>
            </Col>
          </Row>
        )}
        {status === "pending" && (
          <Col
            xs={10}
            sm={5}
            md={10 / 3}
            lg={10 / 4}
            xl={10 / 5}
            override={{ col: classes.pending }}
          >
            <FontAwesomeIcon icon={faSpinnerThird} spin />
          </Col>
        )}
        <Col xs={10} sm={5} md={10 / 3} lg={10 / 4} xl={10 / 5}>
          <IsVisble
            onChange={(isVisible) => {
              if (isVisible && status === "ok" && collections.next)
                actions.next();
            }}
          />
        </Col>
      </Row>
    </AdminPage>
  );
}

import React, { useState } from "react";
import { AccountWidget, useNavigate, LightBox } from "@maxeb/admin-ui";

import { IDP, ISession } from "@maxeb/idp-sdk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPeopleGroup } from "@fortawesome/pro-light-svg-icons";
import TeamAdd from "../Account/TeamAdd";
import TeamSwap from "../Account/TeamSwap";

export interface IProps {
  user: ISession;
  setSession: (session: ISession | null) => void;
  setState: (state: "ok" | "pending" | "error") => void;
}

export default function TopBar(props: IProps) {
  const [mode, setMode] = useState<"addTeam" | "swapTeam" | "none">("none");

  const navigate = useNavigate();

  return (
    <div style={{ textAlign: "right" }}>
      <AccountWidget
        user={props.user}
        state={"ok"}
        onTeamAdd={() => {
          setMode("addTeam");
        }}
        onTeamChange={() => {
          setMode("swapTeam");
        }}
        onTeamSettings={() => {
          navigate("/team/settings");
        }}
        onSettings={() => {
          navigate("/user/settings");
        }}
        onSignOut={async () => {
          props.setState("pending");
          try {
            await IDP.logout();
            props.setState("ok");
            props.setSession(null);
          } catch (error) {
            console.error(error);
            props.setState("error");
          }
        }}
      />
      <LightBox
        title={
          <>
            <FontAwesomeIcon icon={faPeopleGroup} /> Neues Team
          </>
        }
        open={mode === "addTeam"}
        onClose={() => {
          setMode("none");
        }}
      >
        <TeamAdd
          user={props.user}
          setSession={props.setSession}
          onClose={() => {
            setMode("none");
          }}
        />
      </LightBox>
      <LightBox
        title={
          <>
            <FontAwesomeIcon icon={faPeopleGroup} /> Select a Team
          </>
        }
        open={mode === "swapTeam"}
        onClose={() => {
          setMode("none");
        }}
      >
        <TeamSwap
          user={props.user}
          setSession={props.setSession}
          onClose={() => {
            setMode("none");
          }}
        />
      </LightBox>
    </div>
  );
}

import React, { useState } from "react";
import {
  ITheme,
  useClasses,
  ICol,
  UploadArea,
  Row,
  Button,
  Typo,
  useI18n,
} from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCancel,
  faCheck,
  faScissors,
  faExpand,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import ImageLightBox from "./ImageLightBox";
import ImageLoader from "./ImageLoader";
import ImageCroppBox from "./ImageCroppBox";
import "react-image-crop/dist/ReactCrop.css";

const maxWidth = 1920;
const maxHeight = 1080;

interface IUploadArea extends ICol {
  value?: string | { hash: string; name: string; url: string } | undefined;
  height: string | number;
  pendingElement?: React.ReactNode;
  getNewSource?: () => Promise<string | undefined>;
  onCrop?: (value: string) => void;
  onChange?: (value?: string) => void;
  onError?: (error: unknown) => void;
  inline?: boolean;
  info?: string;
  error?: string;
  override?: {
    input?: string;
    col?: string;
    wrapper?: string;
    holder?: string;
  };
}

const styles = (theme: ITheme) => ({
  preview: {
    marginTop: (props: IUploadArea) => (props.inline ? "initial" : "18px"),
    position: "relative",
    backgroundColor: theme.palette.get("formBackground"),
    borderRadius: "2px",
    overflow: "hidden",
  },
  previewImgHolder: {
    textAlign: "center",
    height: "calc(100% - 32px)",
    width: "100%",
    position: "relative",
  },
  previewImg: {
    position: "absolute !important",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100% !important",
    height: "100% !important",
    objectFit: "cover",
    borderRadius: "2px",
  },
  actions: {
    position: "absolute",
    bottom: "0px",
    left: 0,
    right: 0,
    "@media print": {
      display: "none",
    },
  },
  confirm: {
    position: "absolute",
    zIndex: 3,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 1)",
  },
});

const signatures: [string, string][] = [
  ["iVBORw0KGgo", "image/png"],
  ["/9j/", "image/jpeg"],
];

function getMimeType(base64: string) {
  const isSigniture = ([signature]: [string, string]) =>
    base64.indexOf(signature) === 0;

  const found = signatures.find(isSigniture);
  if (found) return found[1];
  return;
}

function getVisibleImage(
  value: string | { hash: string; name: string; url: string }
) {
  if (typeof value === "string") {
    const seperator = value.lastIndexOf(",");
    const fileName = value.slice(0, seperator);
    const data = value.slice(seperator + 1);

    const mimeType = getMimeType(data);
    const src = `data:${mimeType};base64,${data}`;

    return [src, "", fileName];
  } else {
    return [value.url, value.hash, value.name];
  }
}

function loadImage(src: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "annonymouse";
    img.src = src;
    img.onload = () => resolve(img);
    img.onerror = (error) => reject(error);
  });
}

const ImageHandler: React.FC<IUploadArea> = (props) => {
  const classes = useClasses(styles, props);
  const i18n = useI18n("image_upload");

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [imageLightBox, setImageLightBox] = useState(false);
  const [imageCroppBox, setImageCroppBox] = useState(false);
  const [resizeError, setResizeError] = useState<string>();

  if (!props.value)
    return (
      <UploadArea
        value={props.value}
        height={props.height}
        pendingElement={props.pendingElement}
        onChange={async (value) => {
          if (value && props.onChange) {
            const [file, data] = value?.split(",");
            const extension = file
              .substring(file.lastIndexOf(".") + 1)
              .toLowerCase();

            let dataUrl = "data:";
            let mimeType = "";
            if (extension === "jpg" || extension === "jpeg") {
              mimeType = "image/jpeg";
            } else if (extension === "png") {
              mimeType = "image/png";
            } else {
              setResizeError("notSupported");
              return;
            }

            dataUrl += mimeType + ";base64," + data;
            const img = await loadImage(dataUrl);

            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");

            if (context) {
              const width = img.width;
              const height = img.height;

              context.drawImage(img, 0, 0);

              if (width > height && width > maxWidth) {
                const newHeight = (height / width) * maxWidth;

                canvas.width = maxWidth;
                canvas.height = newHeight;
                context.drawImage(img, 0, 0, maxWidth, newHeight);
              } else if (height > width && height > maxHeight) {
                const newWidth = (width / height) * maxHeight;

                canvas.width = newWidth;
                canvas.height = maxHeight;
                context.drawImage(img, 0, 0, newWidth, maxHeight);
              } else {
                canvas.width = width;
                canvas.height = height;
                context.drawImage(img, 0, 0, width, height);
              }

              const [, data] = canvas.toDataURL("image/jpeg").split(",");
              props.onChange("file.jpg," + data);
            }
          } else if (props.onChange) {
            props.onChange();
          }
        }}
        onError={props.onError}
        inline={props.inline}
        info={props.info}
        error={props.error || (resizeError && i18n.get(resizeError))}
        override={props.override}
      >
        {props.children}
      </UploadArea>
    );

  const [src, hash, fileName] = getVisibleImage(props.value);
  return (
    <div style={{ height: props.height }} className={classes.preview}>
      <div className={classes.previewImgHolder}>
        <ImageLoader
          className={classes.previewImg}
          src={src}
          hash={hash}
          alt={fileName}
        />
      </div>
      <Row override={{ root: classes.actions }} root={0}>
        <Button
          primary
          xs={props.onCrop ? 10 / 3 : 5}
          onClick={() => {
            setImageLightBox(true);
          }}
        >
          <FontAwesomeIcon icon={faExpand} />
        </Button>
        {props.onCrop && props.getNewSource && (
          <Button
            xs={10 / 3}
            onClick={() => {
              setImageCroppBox(true);
            }}
          >
            <FontAwesomeIcon icon={faScissors} />
          </Button>
        )}
        <Button
          danger
          xs={props.onCrop ? 10 / 3 : 5}
          onClick={() => {
            setConfirmDelete(true);
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </Row>
      {confirmDelete && (
        <div className={classes.confirm}>
          <Typo variant="h2" margin="none">
            {i18n.get("Delete")}
          </Typo>
          <Typo variant="p">{i18n.get("do_you_really")}</Typo>
          <Row override={{ root: classes.actions }} root={0}>
            <Button
              danger
              xs={5}
              onClick={() => {
                if (props.onChange) {
                  props.onChange();
                  setConfirmDelete(false);
                }
              }}
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
            <Button
              xs={5}
              onClick={() => {
                setConfirmDelete(false);
              }}
            >
              <FontAwesomeIcon icon={faCancel} />
            </Button>
          </Row>
        </div>
      )}
      {imageLightBox && props.value && (
        <ImageLightBox
          title={fileName}
          src={src}
          open={true}
          onClose={() => setImageLightBox(false)}
        />
      )}
      {imageCroppBox && props.value && props.getNewSource && (
        <ImageCroppBox
          title={fileName}
          getNewSource={props.getNewSource}
          open={true}
          onClose={() => setImageCroppBox(false)}
          onCrop={props.onCrop}
        />
      )}
    </div>
  );
};

export default ImageHandler;

import { IArtwork } from "@maxeb/art-sdk";
import { Props, State, initialState } from "./State";
import { useEffect } from "react";
import * as Mutations from "./Mutations";
import filter from "./Filter";
import useSaveState from "../../SaveState";

export interface ArtworkWIthIndex extends IArtwork {
  index?: number;
}

export type { State, Props } from "./State";
export interface Actions {
  next: () => void;
  filter: () => ArtworkWIthIndex[];
  search: (params: State["search"]) => void;
  active: (artwork: IArtwork | null, index: number | null) => void;
  setNewActive: (
    id: string,
    title: string,
    artist: string,
    artistId: string,
    collection: string
  ) => void;
  updateActive: (artwork: IArtwork | null) => void;
  remove: (artworkIDs: string[]) => void;
}

export function useArtworks(props: Props): [State, Actions] {
  const [state, setState] = useSaveState(initialState(props));

  useEffect(() => {
    if (state.status === "init") Mutations.next(state, setState);
  }, [state, setState]);

  return [
    state,
    {
      next: () => {
        Mutations.next(state, setState);
      },
      filter: () => {
        return filter(state);
      },
      search: (params) => {
        Mutations.search({ search: params }, state, setState);
      },
      active: (artwork, index) => {
        Mutations.setActive({ artwork, index }, state, setState);
      },
      setNewActive: (
        id: string,
        title: string,
        artist: string,
        artistId: string,
        collection: string
      ) => {
        Mutations.setNewActive(
          { id, title, artist, artistId, collection },
          state,
          setState
        );
      },
      updateActive: (artwork) => {
        Mutations.updateActive({ set: artwork }, state, setState);
      },
      remove: (artworkIDs) => {
        Mutations.remove({ artworkIDs }, state, setState);
      },
    },
  ];
}

import React, { useEffect, useState } from "react";

import { faShelves } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Form,
  ITheme,
  Row,
  StateHandler,
  Typo,
  useClasses,
  useI18n,
} from "@maxeb/admin-ui";
import {
  IArtwork,
  Collection as CollectionSDK,
  ICollection,
} from "@maxeb/art-sdk";
import CollectionSelect from "../../Collections/Select";
import ImageLoader from "../../Shared/ImageLoader";

export interface IProps {
  data: IArtwork;
  onChange: (value: string) => void;
}

export interface IState {
  status: "init" | "ok" | "pending" | "error";
  data: ICollection;
  errors: { [key: string]: string };
  loaded?: string;
}

let MOUNTED = false;

const style = (theme: ITheme) => ({
  iconHolder: {
    textAlign: "center",
  },
  icon: {
    textAlign: "center",
    display: "block",
    width: "56px",
    height: "56px",
    lineHeight: "50px",
    fontSize: "3.25rem",
    padding: "32px",
    borderRadius: "100px",
    backgroundColor: theme.palette.get("formBackground"),
    color: theme.palette.get("darkGrey"),
  },
  public: {
    color: theme.palette.get("danger"),
    fontWeight: 700,
  },
  private: {
    color: theme.palette.get("primary"),
    fontWeight: 700,
  },
  thumb: {
    height: "164px",
  },
  collectionHolder: {
    height: "120px",
  },
});

async function get(
  id: string,
  state: IState,
  setState: (state: IState) => void
) {
  setState({ ...state, status: "pending" });
  try {
    const result = await CollectionSDK.get(
      {
        id,
      },
      ["id", "name", "thumb", "team", "description", "public"],
      1
    );

    if (MOUNTED && result.isSuccess())
      if (result.body.data.length === 1) {
        const data = result.body.data[0];
        setState({ ...state, status: "ok", data, loaded: data.id });
      } else throw Error("not found not implemented");
  } catch (err) {
    console.error(err);
    if (MOUNTED) setState({ ...state, status: "error" });
  }
}

export default function Collection(props: IProps) {
  const classes = useClasses(style);
  const i18n = useI18n("artworks_collection");

  const { data } = props;
  const { team, collection } = data;
  const hasCollection = collection !== team;

  const [change, setChange] = useState(false);
  const [newCollection, setNewCollection] = useState("");
  const [state, setState] = useState<IState>({
    status: hasCollection ? "init" : "ok",
    data: {},
    errors: {},
  });

  const { status, loaded } = state;

  useEffect(() => {
    MOUNTED = true;
    if (
      collection &&
      hasCollection &&
      status !== "pending" &&
      status !== "error" &&
      collection !== loaded
    )
      get(collection, state, setState);
    return () => {
      MOUNTED = false;
    };
  }, [collection, status, hasCollection, loaded, state]);

  return (
    <Form>
      <StateHandler state={status === "init" ? "pending" : status}>
        {(!hasCollection || status === "pending") && !change && (
          <Row
            spacing={16}
            vertical
            root={{ top: 21, left: 0, right: 0, bottom: 21 }}
            verticalAlign={"center"}
            horizontalAlign="center"
          >
            <Col lg={4}>
              <Row spacing={16} vertical root={0} horizontalAlign="center">
                <Button
                  xs="200px"
                  primary
                  onClick={() => {
                    setChange(true);
                  }}
                >
                  Sammlung hinzufügen
                </Button>
              </Row>
            </Col>
            <Col lg={6}>
              <Row
                spacing={16}
                vertical
                verticalAlign="center"
                root={{ top: 0, left: 0, right: 0, bottom: 0 }}
              >
                <Col xs="120px">
                  <span className={classes.icon}>
                    <FontAwesomeIcon icon={faShelves} />
                  </span>
                </Col>
                <Col xs="calc(100% - 136px)">
                  <Typo variant="h3">Keine Sammlungen</Typo>
                  <p>
                    Das Werk ist noch nicht Teil einer Sammlung. Sie können
                    Sammlungen verwenden um ihre Werke zu Ordnen und zu teilen.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {hasCollection && status === "ok" && !change && (
          <>
            {" "}
            <Row
              spacing={16}
              vertical
              root={{ top: 16, left: 0, right: 0, bottom: 0 }}
              verticalAlign={"center"}
              horizontalAlign="left"
            >
              {state.data.thumb && (
                <Col lg="auto">
                  <ImageLoader
                    className={classes.thumb}
                    src={state.data.thumb?.["xs-url"] || ""}
                    alt="collection-thumbnail"
                  />
                </Col>
              )}
              <Col lg="rest">
                <div className={classes.collectionHolder}>
                  <Typo variant="h3">
                    {state.data.name}{" "}
                    <small>
                      {state.data.public ? (
                        <span className={classes.public}>Öffentlich</span>
                      ) : (
                        <span className={classes.private}>Privat</span>
                      )}
                    </small>
                  </Typo>
                  {state.data.description && (
                    <Typo variant="p">{state.data.description}</Typo>
                  )}
                  {!state.data.description && (
                    <Typo variant="info">
                      Diese Sammlung hat keine Beschreibung
                    </Typo>
                  )}
                </div>
                <Row horizontalAlign="right">
                  <Button xs="150px" danger onClick={() => setChange(true)}>
                    Andere Sammlung
                  </Button>
                </Row>
              </Col>
            </Row>
          </>
        )}
        {change && (
          <Row
            spacing={16}
            vertical
            root={{ top: 8, left: 0, right: 0, bottom: 7 }}
            verticalAlign={"center"}
            horizontalAlign="right"
          >
            <Col>
              <Typo variant="h3">{i18n.get("change_collection")}</Typo>
              <Typo variant="p">{i18n.get("select_desc")}</Typo>
            </Col>
            <CollectionSelect
              value={newCollection}
              onChange={(value) => setNewCollection(value)}
            />
            <Button xs="100px" onClick={() => setChange(false)}>
              {i18n.get("cancel")}
            </Button>
            <Button
              xs="100px"
              success
              onClick={async () => {
                if (team) {
                  props.onChange(newCollection || team);
                  setChange(false);
                }
              }}
            >
              {i18n.get("add")}
            </Button>
          </Row>
        )}
      </StateHandler>
    </Form>
  );
}

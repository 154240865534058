import React, { useEffect, useState } from "react";
import {
  Block,
  Button,
  Col,
  ITheme,
  Row,
  Typo,
  useClasses,
  useI18n,
} from "@maxeb/admin-ui";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faSpinnerThird,
} from "@fortawesome/pro-duotone-svg-icons";
import { Artist, Artwork, IArtistAdd, IArtworkAdd } from "@maxeb/art-sdk";

export interface IProps {
  type: "artist" | "artwork";
  selectedFields: string[];
  csv: string[][];
  collection: string;
  onReset: () => void;
  onBack: () => void;
}

export interface IState {
  init: boolean;
  activeIndex: number;
  errors: number;
  finished: boolean;
  results: string[][];
  collection: string;
}

const styles = (theme: ITheme) => ({
  next: { textAlign: "right" },
  fieldName: { color: theme.palette.get("primary") },
  previewTable: {
    borderCollapse: "collapse",
    marginTop: "16px",
    maxWidth: "100%",
    overflowX: "scroll",
    overflowY: "hidden",
    display: "block",
  },
  previewTableRowEven: {
    backgroundColor: theme.palette.get("default"),
  },
  previewTableRowOdd: {},
  primaryRow: {
    border: "10px solid " + theme.palette.get("primary"),
  },
  previewTableCell: {
    border: "1px solid",
    padding: "4px 8px",
    whiteSpace: "nowrap",
    maxWidth: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  state: {
    position: "relative",
    fontSize: "7rem",
    lineHeight: "7rem",
    color: theme.palette.get("primary"),
    display: "inline-block",
  },
  stateTxt: {
    position: "absolute",
    left: 0,
    width: "115px",
    textAlign: "center",
    color: theme.palette.get("font"),
    fontSize: "2rem",
  },
  progressHolder: { textAlign: "center" },
  successCol: {
    textAlign: "center",
  },
  successIcon: {
    fontSize: "8rem",
    color: theme.palette.get("success"),
  },
});

let MOUNTED = false;
const DELAY = 500;

function createTableRow(
  index: number,
  csv: string[][],
  results: string[][],
  classes: {
    [key: string]: string;
  },
  isActive?: boolean
) {
  if (index > 1 && csv[index - 1])
    return (
      <tr
        className={classNames([
          classes.previewTableRowEven,
          isActive && classes.primaryRow,
        ])}
      >
        <td className={classes.previewTableCell}>#{index - 1}</td>
        <td className={classes.previewTableCell}>
          {results[index - 2] && results[index - 2][1]}
          {isActive && !results[index - 2] && "uploading"}
          {!isActive && !results[index - 2] && "pending"}
        </td>
        {csv[index - 1].map((field, i) => (
          <td key={`${index}-field-${i}`} className={classes.previewTableCell}>
            {field}
          </td>
        ))}
      </tr>
    );
  else return null;
}
function parseArtist(header: string[], row: string[]): IArtistAdd {
  const nameIndex = header.indexOf("name");
  const publicIndex = header.indexOf("public");
  const add: IArtistAdd = {
    name: row[nameIndex],
    public: row[publicIndex] === "1",
  };

  const thumbIndex = header.indexOf("thumb");
  if (thumbIndex > -1 && row[thumbIndex]) add.thumb = row[thumbIndex];

  const descriptionIndex = header.indexOf("description");
  if (thumbIndex > -1 && row[descriptionIndex])
    add.description = row[descriptionIndex];

  const birthdateIndex = header.indexOf("birthdate");
  if (birthdateIndex > -1 && row[birthdateIndex])
    add.birthdate = row[birthdateIndex];

  const educationIndex = header.indexOf("education");
  if (educationIndex > -1 && row[educationIndex])
    add.education = JSON.parse(row[educationIndex]);

  const awardsExhibitionIndex = header.indexOf("awardsExhibition");
  if (awardsExhibitionIndex > -1 && row[awardsExhibitionIndex])
    add.awardsExhibition = JSON.parse(row[awardsExhibitionIndex]);

  return add;
}
async function uploadArtist(csv: string[][], state: IState) {
  const index = state.activeIndex;
  const row = csv[index];

  const toAdd = parseArtist(csv[0], row);
  const result = await Artist.add(toAdd);
  if (result.isSuccess()) {
    const id = result.getResult().data.id;
    return [index + "", "ok", id, ...row];
  } else {
    throw result.getBody();
  }
}
function parseArtwork(header: string[], row: string[]): IArtworkAdd {
  const artistIndex = header.indexOf("artistId");
  const titleIndex = header.indexOf("title");
  const weightIndex = header.indexOf("weight");

  const add: IArtworkAdd = {
    artistId: row[artistIndex],
    title: row[titleIndex],
    weight: parseInt(row[weightIndex]),
  };

  const thumbIndex = header.indexOf("thumb");
  if (thumbIndex > -1 && row[thumbIndex]) add.thumb = row[thumbIndex];

  const customIdIndex = header.indexOf("customId");
  if (customIdIndex > -1 && row[customIdIndex])
    add.customId = row[customIdIndex];

  const themeIdIndex = header.indexOf("themeId");
  if (themeIdIndex > -1 && row[themeIdIndex]) add.themeId = row[themeIdIndex];

  const categoryIdIndex = header.indexOf("categoryId");
  if (categoryIdIndex > -1 && row[categoryIdIndex])
    add.categoryId = row[categoryIdIndex];

  const techniqueIdIndex = header.indexOf("techniqueId");
  if (techniqueIdIndex > -1 && row[techniqueIdIndex])
    add.techniqueId = row[techniqueIdIndex];

  const descriptionIndex = header.indexOf("description");
  if (descriptionIndex > -1 && row[descriptionIndex])
    add.description = row[descriptionIndex];

  const widthIndex = header.indexOf("width");
  if (widthIndex > -1 && row[widthIndex]) add.width = parseInt(row[widthIndex]);

  const heightIndex = header.indexOf("height");
  if (heightIndex > -1 && row[heightIndex])
    add.height = parseInt(row[heightIndex]);

  const depthIndex = header.indexOf("depth");
  if (depthIndex > -1 && row[depthIndex]) add.depth = parseInt(row[depthIndex]);

  const yearIndex = header.indexOf("year");
  if (yearIndex > -1 && row[yearIndex]) add.year = parseInt(row[yearIndex]);

  const colorIndex = header.indexOf("color");
  if (colorIndex > -1 && row[colorIndex]) add.color = row[colorIndex];

  const sourceIndex = header.indexOf("source");
  if (sourceIndex > -1 && row[sourceIndex]) add.source = row[sourceIndex];

  const signedIndex = header.indexOf("signed");
  if (signedIndex > -1 && row[signedIndex])
    add.signed = Boolean(row[signedIndex]);

  const availableIndex = header.indexOf("available");
  if (availableIndex > -1 && row[availableIndex])
    add.available = parseInt(row[availableIndex]);

  const datedIndex = header.indexOf("dated");
  if (datedIndex > -1 && row[datedIndex]) add.dated = Boolean(row[datedIndex]);

  const frameIndex = header.indexOf("frame");
  if (frameIndex > -1 && row[frameIndex]) add.frame = Boolean(row[frameIndex]);

  const reservedFromIndex = header.indexOf("reservedFrom");
  if (reservedFromIndex > -1 && row[reservedFromIndex])
    add.reservedFrom = row[reservedFromIndex];

  const reservedUntilIndex = header.indexOf("reservedUntil");
  if (reservedUntilIndex > -1 && row[reservedUntilIndex])
    add.reservedUntil = row[reservedUntilIndex];

  const reservedForIndex = header.indexOf("reservedFor");
  if (reservedForIndex > -1 && row[reservedForIndex])
    add.reservedFor = row[reservedForIndex];

  const reservedReasonIndex = header.indexOf("reservedReason");
  if (reservedReasonIndex > -1 && row[reservedReasonIndex])
    add.reservedReason = row[reservedReasonIndex];

  const sellingPriceIndex = header.indexOf("sellingPrice");
  if (sellingPriceIndex > -1 && row[sellingPriceIndex])
    add.sellingPrice = parseInt(row[sellingPriceIndex]);

  const purchasingPriceIndex = header.indexOf("purchasingPrice");
  if (purchasingPriceIndex > -1 && row[purchasingPriceIndex])
    add.purchasingPrice = parseInt(row[purchasingPriceIndex]);

  const proprietorIndex = header.indexOf("proprietor");
  if (proprietorIndex > -1 && row[proprietorIndex])
    add.proprietor = row[proprietorIndex];

  const purchasingDateIndex = header.indexOf("purchasingDate");
  if (purchasingDateIndex > -1 && row[purchasingDateIndex])
    add.purchasingDate = row[purchasingDateIndex];

  const insurerIndex = header.indexOf("insurer");
  if (insurerIndex > -1 && row[insurerIndex]) add.insurer = row[insurerIndex];

  const insurerPolicyNumberIndex = header.indexOf("insurerPolicyNumber");
  if (insurerPolicyNumberIndex > -1 && row[insurerPolicyNumberIndex])
    add.insurerPolicyNumber = row[insurerPolicyNumberIndex];

  const insurerValueIndex = header.indexOf("insurerValue");
  if (insurerValueIndex > -1 && row[insurerValueIndex])
    add.insurerValue = parseInt(row[insurerValueIndex]);

  const ownerIndex = header.indexOf("owner");
  if (ownerIndex > -1 && row[ownerIndex])
    add.owner = JSON.parse(row[ownerIndex]);

  const locationIndex = header.indexOf("location");
  if (locationIndex > -1 && row[locationIndex])
    add.location = JSON.parse(row[locationIndex]);

  const conditionIndex = header.indexOf("condition");
  if (conditionIndex > -1 && row[conditionIndex])
    add.condition = JSON.parse(row[conditionIndex]);

  return add;
}
async function uploadArtwork(csv: string[][], state: IState) {
  const index = state.activeIndex;
  const row = csv[index];

  const toAdd = parseArtwork(csv[0], row);

  const result = await Artwork.add({ ...toAdd, collection: state.collection });
  if (result.isSuccess()) {
    const id = result.getResult().data.id;
    return [index + "", "ok", id, ...row];
  } else {
    throw result.getBody();
  }
}

async function upload(
  type: "artist" | "artwork",
  csv: string[][],
  selectedFields: string[],
  state: IState,
  setState: (state: IState) => void
) {
  const results = [...state.results];
  let errors = state.errors;
  const index = state.activeIndex;

  try {
    if (type === "artist") {
      const result = await uploadArtist(csv, state);
      results.push(result);
    } else {
      const result = await uploadArtwork(csv, state);
      results.push(result);
    }
  } catch (err) {
    errors++;
    if (csv[index]) {
      results.push([
        index + "",
        "error: " + JSON.stringify(err),
        "",
        ...csv[index],
      ]);
    } else {
      results.push([index + "", "error: " + JSON.stringify(err)]);
    }
  }

  const newIndex = index + 1;

  const notFinished = newIndex < csv.length;

  const newState = {
    ...state,
    init: false,
    activeIndex: newIndex,
    finished: !notFinished,
    results: results,
    errors: errors,
  };
  if (MOUNTED) {
    setState(newState);

    if (notFinished)
      setTimeout(() => {
        upload(type, csv, selectedFields, newState, setState);
      }, DELAY);
  }
}

export default function Import(props: IProps) {
  const classes = useClasses(styles);
  const i18n = useI18n("import");

  const [state, setState] = useState<IState>({
    init: true,
    activeIndex: 1,
    errors: 0,
    finished: false,
    results: [],
    collection: props.collection,
  }); //since first row is header

  const firstRow = props.csv[0];
  const { type, csv, selectedFields } = props;

  useEffect(() => {
    MOUNTED = true;
    if (state.init) {
      upload(type, csv, selectedFields, state, setState);
    }
    return () => {
      MOUNTED = false;
    };
  }, [state, type, csv, selectedFields]);

  return (
    <Row spacing={16} vertical root={0}>
      <Typo variant="h1">{i18n.get("import_" + props.type)}</Typo>
      <Col lg={5}>
        <Block>
          {!state.finished && (
            <>
              <Row>
                <Col md="calc(100% - 115px)">
                  <Typo variant="section" color="primary" margin="none">
                    5.{i18n.get("import")}
                  </Typo>
                  <Typo variant="p" color="font">
                    {i18n.get("import_desc")}
                  </Typo>
                  <Typo variant="h1" color="font">
                    {i18n.get("at")} <b>{state.activeIndex}</b> {i18n.get("of")}{" "}
                    <b>{props.csv.length - 1}</b>
                  </Typo>
                </Col>
                <Col md={"115px"}>
                  <div className={classes.progressHolder}>
                    <div className={classes.state}>
                      <FontAwesomeIcon icon={faSpinnerThird} spin />
                      <span className={classes.stateTxt}>
                        {Math.min(
                          Math.floor(
                            (100 / (props.csv.length - 1)) * state.activeIndex
                          ),
                          99
                        )}
                        %
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
              <table className={classes.previewTable}>
                <thead>
                  <tr className={classes.previewTableRowEven}>
                    <th className={classes.previewTableCell}>Pos</th>
                    <th className={classes.previewTableCell}>Status</th>
                    {firstRow.map((field, n) => (
                      <th
                        key={`cell-${n}`}
                        className={classes.previewTableCell}
                      >
                        {field}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createTableRow(
                    state.activeIndex,
                    props.csv,
                    state.results,
                    classes
                  )}
                  {createTableRow(
                    state.activeIndex + 1,
                    props.csv,
                    state.results,
                    classes,
                    true
                  )}
                  {createTableRow(
                    state.activeIndex + 2,
                    props.csv,
                    state.results,
                    classes
                  )}
                  {createTableRow(
                    state.activeIndex + 3,
                    props.csv,
                    state.results,
                    classes
                  )}
                </tbody>
              </table>
              {state.errors > 0 && (
                <Typo variant="p" color="danger">
                  {i18n.get("errors")}: {state.errors}
                </Typo>
              )}
            </>
          )}
          {state.finished && (
            <Row
              spacing={16}
              vertical
              horizontalAlign="center"
              root={{ left: 0, right: 0, bottom: 0 }}
            >
              <Col override={{ col: classes.successCol }}>
                <FontAwesomeIcon
                  className={classes.successIcon}
                  icon={faCheckCircle}
                />
                <br />
                <Typo variant="h1">{i18n.get("finished")}!</Typo>
                <Typo variant="p">
                  {state.errors} {i18n.get("errors occurred")}.
                </Typo>
              </Col>
              <Button
                xs={"100px"}
                onClick={() => {
                  props.onReset();
                }}
              >
                {i18n.get("reset")}
              </Button>
              <Button
                xs={"100px"}
                primary
                disabled={!state.finished}
                onClick={() => {
                  const resultsCSV = [
                    ["Position", "Status", "ID", ...firstRow],
                    ...state.results,
                  ];
                  const escapedRow = resultsCSV.map((row) => {
                    const escaped = row.map((cell) => {
                      if (
                        cell.includes('"') ||
                        cell.includes(";") ||
                        cell.includes("\n")
                      ) {
                        const escaped = cell.replaceAll('"', '""');
                        return '"' + escaped + '"';
                      } else {
                        return cell;
                      }
                    });
                    return escaped.join(";");
                  });

                  const element = document.createElement("a");
                  const file = new Blob([escapedRow.join("\n")], {
                    type: "text/plain",
                  });
                  element.href = URL.createObjectURL(file);
                  element.download = "results.csv";
                  document.body.appendChild(element); // Required for this to work in FireFox
                  element.click();
                }}
              >
                {i18n.get("download")}
              </Button>
            </Row>
          )}
        </Block>
      </Col>
    </Row>
  );
}

import React, { useEffect } from "react";
import {
  Button,
  AdminPage,
  ITheme,
  useClasses,
  Row,
  Col,
  usePathVariables,
  useI18n,
} from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faShelves,
  faSquareChevronLeft,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import Thumb from "./Thumb";
import Data from "./Data";
import Delete from "../../Shared/Delete";
import { useCollection } from "../../../Hooks/Collections/Edit";
import { ICollection } from "@maxeb/art-sdk";

export interface IProps {
  id?: string;
  onChange?: (collection: ICollection) => void;
  close?: (isDeleted?: boolean) => void;
}

const styles = (theme: ITheme) => ({
  overlay: {
    padding: "132px",
    backgroundColor: "rgba(236, 236, 236, 0.95) !important",
  },
  alignCenter: {
    textAlign: "center",
  },
  row: {
    [theme.breakpoints.up("md")]: {
      width: "330px",
    },
    flexWrap: "nowrap",
    width: "calc(100% + 21px)",
  },
  icon: {
    fontSize: "4rem",
    margin: "0px 16px 0px 0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
      margin: "0px 4px 0px 0px",
    },
  },
  link: {
    textDecoration: "none",
  },
});

export default function CollectionsEdit(props: IProps) {
  const classes = useClasses(styles);
  const params = usePathVariables();

  const i18n = useI18n("collection_edit");

  const [collection, actions] = useCollection({
    id: props.id || params.id,
    onChange: props.onChange,
  });
  const { status, data, errors } = collection;

  useEffect(() => {
    if (
      props.id &&
      window.location.href.endsWith &&
      window.location.href.endsWith("/collections")
    ) {
      window.history.pushState({}, "", `/collections/${props.id}`);
    }
  }, [props]);

  return (
    <AdminPage
      icon={<FontAwesomeIcon className={classes.icon} icon={faShelves} />}
      title={i18n.get("collection")}
      state={status === "init" ? "pending" : status}
      breadcrumbs={[
        { label: i18n.get("collection"), onClick: props.close },
        { label: "Edit" },
      ]}
      actions={
        <Row
          override={{ row: classes.row }}
          spacing={16}
          root={0}
          horizontalAlign="right"
        >
          {props.close && (
            <Button
              xs="100px"
              onClick={async () => {
                if (window?.history?.pushState)
                  window.history.pushState({}, "", `/collections`);
                if (props.close) props.close();
              }}
            >
              <FontAwesomeIcon
                style={{ margin: "0px 8px 0px 0px" }}
                icon={faSquareChevronLeft}
              />
              {i18n.get("back")}
            </Button>
          )}
          <Button
            xs="110px"
            danger
            onClick={async () => {
              actions.setConfirmDelete(true);
            }}
          >
            <FontAwesomeIcon
              style={{ margin: "0px 8px 0px 0px" }}
              icon={faTrash}
            />
            {i18n.get("delete")}
          </Button>
          <Button
            xs="120px"
            primary
            onClick={async () => {
              actions.save();
            }}
          >
            <FontAwesomeIcon
              style={{ margin: "0px 8px 0px 0px" }}
              icon={faSave}
            />
            {i18n.get("save")}
          </Button>
        </Row>
      }
      override={{ overlay: classes.overlay }}
    >
      {data.id && (
        <Row spacing={16} vertical root={0}>
          <Col lg="400px">
            <Thumb
              id={data.id}
              thumb={data.thumb}
              setPending={actions.pending}
              setSuccess={actions.reload}
            />
          </Col>
          <Col lg="calc(100% - 400px)">
            <Data data={data} errors={errors} onChange={actions.setData} />
          </Col>
          <Delete
            name={`Sammlung - ${data.name}`}
            open={collection.confirmDelete}
            onClose={() => {
              actions.setConfirmDelete(false);
            }}
            onDelete={async () => {
              await actions.remove();
              if (props.close) props.close(true);
            }}
          />
        </Row>
      )}
    </AdminPage>
  );
}

import { Col, ITheme, Row, Typo, useClasses, useI18n } from "@maxeb/admin-ui";
import { Actions } from "../../../Hooks/Artworks/ListCollection";
import Card from "../../Shared/Card";
import { faPaintBrush } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IsVisible from "../../Shared/IsVisible";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { FullCollection } from "../../../Hooks/Artworks/ListCollection/State";

export interface Props {
  collection: FullCollection;
  actions: Actions;
}

const styles = (theme: ITheme) => ({
  icon: {
    fontSize: "4rem",
    margin: "0px 16px 0px 0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
      margin: "0px 4px 0px 0px",
    },
  },
  filter: {
    position: "fixed",
    zIndex: 4,
  },
  active: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.get("default"),
  },
  pending: {
    color: theme.palette.get("primary"),
    fontSize: "5rem",
    textAlign: "center",
    padding: "32px",
  },
  link: {
    textDecoration: "none",
  },
});

export default function GridView(props: Props) {
  const classes = useClasses(styles);

  const { actions, collection } = props;
  const { status, artworksData, artworksNext, index, fullyLoaded } = collection;
  const i18n = useI18n("artworks");

  return (
    <Row spacing={16} vertical root={0}>
      {actions.filterArtworks(index).map((artwork) => (
        <Col
          key={`artwork-${artwork.id}`}
          xs={10}
          sm={5}
          md={10 / 3}
          lg={10 / 4}
          xl={10 / 5}
        >
          <a
            className={classes.link}
            href={`/artworks/${artwork.collection}/${artwork.id}`}
            onClick={(e) => {
              e.preventDefault();
              actions.setActive([collection.index, artwork.index], artwork);
              window.history.pushState(
                {},
                "",
                `/artworks/${artwork.collection}/${artwork.id}`
              );
            }}
          >
            <Card
              name={artwork.artist}
              subscript={artwork.title}
              icon={faPaintBrush}
              thumb={
                artwork.thumb && {
                  ...artwork.thumb,
                  url: artwork.thumb["xs-url"],
                }
              }
            />
          </a>
        </Col>
      ))}
      {status === "ok" && artworksData.length === 0 && (
        <Row>
          <Col lg={5}>
            <Typo variant="p" margin="none">
              {i18n.get("no_artworks")}
            </Typo>
          </Col>
        </Row>
      )}
      {status === "pending" && (
        <Col
          xs={10}
          sm={5}
          md={10 / 3}
          lg={10 / 4}
          xl={10 / 5}
          override={{ col: classes.pending }}
        >
          <FontAwesomeIcon icon={faSpinnerThird} spin />
        </Col>
      )}
      <Col xs={10} sm={5} md={10 / 3} lg={10 / 4} xl={10 / 5}>
        <IsVisible
          onChange={(isVisible) => {
            if (
              isVisible &&
              ((status === "ok" && artworksNext) || status === "init") &&
              !fullyLoaded
            ) {
              actions.nextArtworks(props.collection.index);
            }
          }}
        />
      </Col>
    </Row>
  );
}

import React, { useEffect, useState } from "react";
import {
  ITheme,
  useClasses,
  StateHandler,
  useI18n,
  Row,
  Button,
  Col,
  LightBox,
} from "@maxeb/admin-ui";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faXmark,
  faXmarkCircle,
} from "@fortawesome/pro-light-svg-icons";
import MockupGenerator from "../../Shared/MockupGenerator";
import ImageHandler from "../../Shared/ImageHandler";
import { Artwork } from "@maxeb/art-sdk";

export interface IProps {
  id: string;
  collection: string;
  value?: { name: string; hash: string; url: string };
  width?: number;
  height?: number;
  thumb?: { hash: string; name: string; url: string };
  setPending: () => void;
  setSuccess: () => void;
}

export interface IState {
  status: "ok" | "pending" | "success" | "error";
}

export interface IStateLoadUrl {
  status: "ok" | "init" | "pending";
  src?: string;
}

const styles = (theme: ITheme) => ({
  alignCenter: {
    textAlign: "center",
  },
  row: {
    padding: "40px 16px !important",
    height: "273px !important",
  },
  success: {
    color: theme.palette.get("success"),
  },
  error: {
    color: theme.palette.get("danger"),
  },
  list: {
    listStyle: "none",
  },
});

async function remove(
  props: IProps,
  state: IState,
  setState: (state: IState) => void
) {
  setState({ ...state, status: "pending" });
  try {
    const result = await Artwork.update(
      { mockup: null },
      { id: props.id, collection: props.collection }
    );
    if (result.isSuccess()) {
      setState({ ...state, status: "ok" });
      return props.setSuccess();
    }
  } catch (error) {
    console.error(error);
  }
  setState({ ...state, status: "error" });
}

let MOUNTED = false;

async function loadNewSrc(
  props: IProps,
  state: IStateLoadUrl,
  setState: (state: IStateLoadUrl) => void
) {
  const result = await Artwork.get(
    { collection: props.collection, id: props.id },
    ["thumb", "id", "team"],
    1
  );
  if (result.isSuccess()) {
    const artwork = result.getResult().data[0];
    if (MOUNTED) setState({ ...state, src: (artwork.thumb || {}).url });
    return;
  }
}

export default function Mockup(props: IProps) {
  const classes = useClasses(styles);
  const i18n = useI18n("artwork_mockups");
  const [state, setState] = useState<IState>({ status: "ok" });
  const [srcState, setSrcState] = useState<IStateLoadUrl>({ status: "init" });

  const hasThumb = Boolean(props.thumb);
  const hasDimensions = Boolean(props.width && props.height);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    MOUNTED = true;
    if (srcState.status === "init") {
      const newState: IStateLoadUrl = { ...srcState, status: "ok" };
      setSrcState(newState);
      loadNewSrc(props, newState, setSrcState);
    }
    return () => {
      MOUNTED = false;
    };
  }, [srcState, props]);

  //mockup exists
  if (props.value)
    return (
      <StateHandler state={state.status}>
        <ImageHandler
          value={props.value}
          onChange={(value) => {
            remove(props, state, setState);
          }}
          height="335px"
        >
          <div className={classes.alignCenter}>
            <b>{i18n.get("thumb")}</b>
            <br />
            {i18n.get("upload")}
          </div>
        </ImageHandler>
      </StateHandler>
    );

  //create Mockup
  return (
    <StateHandler state={state.status}>
      <Row override={{ row: classes.row }}>
        <Col>
          <p>{i18n.get("before")}</p>
          <ul className={classes.list}>
            <li
              className={classnames([
                hasThumb ? classes.success : classes.error,
              ])}
            >
              <FontAwesomeIcon icon={hasThumb ? faCheckCircle : faXmark} />{" "}
              {i18n.get("req_1")}
            </li>
            <li
              className={classnames([
                hasDimensions ? classes.success : classes.error,
              ])}
            >
              <FontAwesomeIcon
                icon={hasDimensions ? faCheckCircle : faXmarkCircle}
              />{" "}
              {i18n.get("req_2")}
            </li>
          </ul>
          <Row root={{ top: 32 }} horizontalAlign="center">
            <Button
              xs="100px"
              success
              disabled={!hasDimensions || !hasThumb}
              onClick={() => setOpen(true)}
            >
              {i18n.get("create")}
            </Button>
          </Row>
        </Col>
      </Row>
      <LightBox
        title={i18n.get("create_mockup")}
        open={open}
        onClose={function (): void {
          setOpen(false);
        }}
      >
        {srcState.src && props.width && props.height && props.thumb?.url && (
          <MockupGenerator
            artwork={props.id}
            collection={props.collection}
            width={props.width}
            height={props.height}
            thumb={srcState.src}
            setSuccess={() => {
              setOpen(false);
              props.setSuccess();
            }}
          />
        )}
      </LightBox>
    </StateHandler>
  );
}

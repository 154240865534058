import { IArtist } from "@maxeb/art-sdk";

export interface State {
  status: "init" | "ok" | "pending" | "error" | "success";
  search: { open: boolean; name?: string; direction: "ASC" | "DESC" };
  data: IArtist[];
  next?: string;
  active: IArtist | null;
  activeIndex: number | null;
}
export type SetState = (state: State) => void;
export function initialState(): State {
  return {
    status: "init",
    search: { open: false, direction: "ASC" },
    data: [],
    active: null,
    activeIndex: null,
  };
}

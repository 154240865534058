import React, { useState } from "react";
import { Typo, useI18n } from "@maxeb/admin-ui";
import SelectFields from "./SelectFields";
import Instructions from "./Instructions";
import Upload from "./Upload";
import Import from "./Import";

export interface IProps {
  type: "artist" | "artwork";
  onReset: () => void;
  team: string;
}

export interface IState {
  mode: "selectFields" | "instructions" | "upload" | "import";
  fields?: string[];
  csv?: string[][];
  collection: string;
}

export interface IFields {
  artist: { label: string; name: string; desc?: React.ReactNode }[];
  artwork: { label: string; name: string; desc?: React.ReactNode }[];
}

export default function HowToAndSelectType(props: IProps) {
  const i18n = useI18n("import");
  const i18nArtist = useI18n("artist_edit");
  const i18nArtistEdu = useI18n("artist_edu");
  const i18nArtistAwa = useI18n("artist_awards");
  const i18nArtworkData = useI18n("artwork_data");
  const i18nArtworkSelling = useI18n("artwork_selling");
  const i18nArtworkPropriator = useI18n("artwork_proprietor");

  const { type } = props;
  const [state, setState] = useState<IState>({
    mode: "selectFields",
    collection: props.team,
  });
  const { mode } = state;

  const fields: IFields = {
    artist: [
      {
        label: i18nArtist.get("thumb"),
        name: "thumb",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artist_thumb")}
            <br />
            {i18n.get("desc_artist_thumb_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtist.get("name"),
        name: "name",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artist_name")}
            <br />
            {i18n.get("desc_artist_name_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtist.get("birthdate"),
        name: "birthdate",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artist_birthdate")}
            <br />
            {i18n.get("desc_artist_birthdate_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtist.get("description"),
        name: "description",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artist_description")}
            <br />
            {i18n.get("desc_artist_description_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtist.get("visibility"),
        name: "public",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artist_public")}
            <br />
            {i18n.get("desc_artist_public_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtistEdu.get("education"),
        name: "education",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artist_education")}
            <br />
            {i18n.get("desc_artist_education_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtistAwa.get("awa"),
        name: "awardsExhibition",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artist_awa")}
            <br />
            {i18n.get("desc_artist_awa_ie")}
          </Typo>
        ),
      },
    ],
    artwork: [
      {
        label: i18nArtist.get("thumb"),
        name: "thumb",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_thumb")}
            <br />
            {i18n.get("desc_artwork_thumb_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkData.get("title"),
        name: "title",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_title")}
            <br />
            {i18n.get("desc_artwork_title_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkData.get("artist"),
        name: "artistId",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_artist")}
            <br />
            {i18n.get("desc_artwork_artist_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkData.get("id"),
        name: "customId",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_customId")}
            <br />
            {i18n.get("desc_artwork_customId_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkData.get("theme"),
        name: "themeId",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_themeId")}
            <br />
            {i18n.get("desc_artwork_themeId_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkData.get("category"),
        name: "categoryId",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_categoryId")}
            <br />
            {i18n.get("desc_artwork_categoryId_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkData.get("technique"),
        name: "techniqueId",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_techniqueId")}
            <br />
            {i18n.get("desc_artwork_techniqueId_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkData.get("year"),
        name: "year",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_year")}
            <br />
            {i18n.get("desc_artwork_year_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkData.get("width"),
        name: "width",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_width")}
            <br />
            {i18n.get("desc_artwork_width_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkData.get("height"),
        name: "height",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_height")}
            <br />
            {i18n.get("desc_artwork_height_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkData.get("depth"),
        name: "depth",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_depth")}
            <br />
            {i18n.get("desc_artwork_depth_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkData.get("weight"),
        name: "weight",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_weight")}
            <br />
            {i18n.get("desc_artwork_weight_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkData.get("description"),
        name: "description",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_description")}
            <br />
            {i18n.get("desc_artwork_description_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkData.get("signed"),
        name: "signed",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_signed")}
            <br />
            {i18n.get("desc_artwork_signed_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkData.get("dated"),
        name: "dated",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_dated")}
            <br />
            {i18n.get("desc_artwork_dated_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkData.get("framed"),
        name: "frame",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_frame")}
            <br />
            {i18n.get("desc_artwork_frame_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkData.get("available"),
        name: "available",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_available")}
            <br />
            {i18n.get("desc_artwork_available_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkData.get("color"),
        name: "color",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_color")}
            <br />
            {i18n.get("desc_artwork_color_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkSelling.get("reserved_from"),
        name: "reservedFrom",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_reservedFrom")}
            <br />
            {i18n.get("desc_artwork_reservedFrom_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkSelling.get("reserved_until"),
        name: "reservedUntil",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_reservedUntil")}
            <br />
            {i18n.get("desc_artwork_reservedUntil_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkSelling.get("reserved_for"),
        name: "reservedFor",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_reservedFor")}
            <br />
            {i18n.get("desc_artwork_reservedFor_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkSelling.get("reserved_reason"),
        name: "reservedReason",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_reservedReason")}
            <br />
            {i18n.get("desc_artwork_reservedReason_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkSelling.get("selling_price"),
        name: "sellingPrice",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_sellingPrice")}
            <br />
            {i18n.get("desc_artwork_sellingPrice_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkPropriator.get("proprietor"),
        name: "proprietor",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_proprietor")}
            <br />
            {i18n.get("desc_artwork_proprietor_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkPropriator.get("source"),
        name: "source",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_source")}
            <br />
            {i18n.get("desc_artwork_source_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkPropriator.get("purchase_price"),
        name: "purchasingPrice",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_purchasingPrice")}
            <br />
            {i18n.get("desc_artwork_purchasingPrice_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkPropriator.get("purchase_date"),
        name: "purchasingDate",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_purchasingDate")}
            <br />
            {i18n.get("desc_artwork_purchasingDate_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkData.get("owner"),
        name: "owner",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_owner")}
            <br />
            {i18n.get("desc_artwork_owner_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkData.get("condition"),
        name: "condition",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_condition")}
            <br />
            {i18n.get("desc_artwork_condition_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkData.get("location"),
        name: "location",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_location")}
            <br />
            {i18n.get("desc_artwork_location_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkPropriator.get("insurer"),
        name: "insurer",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_insurer")}
            <br />
            {i18n.get("desc_artwork_insurer_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkPropriator.get("policynumber"),
        name: "insurerPolicyNumber",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_policyNumber")}
            <br />
            {i18n.get("desc_artwork_policyNumber_ie")}
          </Typo>
        ),
      },
      {
        label: i18nArtworkPropriator.get("insurance_value"),
        name: "insurerValue",
        desc: (
          <Typo variant="p">
            {i18n.get("desc_artwork_insurerValue")}
            <br />
            {i18n.get("desc_artwork_insurerValue_ie")}
          </Typo>
        ),
      },
    ],
  };

  if (mode === "selectFields")
    return (
      <SelectFields
        type={type}
        fields={fields}
        values={state.fields}
        onNext={(fields) => {
          setState({ ...state, mode: "instructions", fields });
        }}
        onBack={props.onReset}
      />
    );

  if (mode === "instructions")
    return (
      <Instructions
        type={type}
        fields={fields}
        selectedFields={state.fields}
        onNext={() => {
          setState({ ...state, mode: "upload" });
        }}
        onBack={() => setState({ ...state, mode: "selectFields" })}
      />
    );
  if (mode === "upload")
    return (
      <Upload
        type={type}
        value={state.csv}
        selectedFields={state.fields}
        collection={state.collection}
        setCollection={(value) => {
          setState({ ...state, collection: value || props.team });
        }}
        onNext={(csv) => {
          setState({ ...state, csv, mode: "import" });
        }}
        onBack={() => setState({ ...state, mode: "instructions" })}
      />
    );
  if (mode === "import" && state.fields && state.csv)
    return (
      <Import
        type={type}
        csv={state.csv}
        selectedFields={state.fields}
        onBack={() => setState({ ...state, mode: "instructions" })}
        onReset={props.onReset}
        collection={state.collection}
      />
    );

  return <div />;
}

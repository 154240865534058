import React, { useState } from "react";

import {
  Col,
  Form,
  ITheme,
  Row,
  StateHandler,
  Table,
  UploadArea,
  useClasses,
  useI18n,
} from "@maxeb/admin-ui";
import { IArtwork, Artwork as ArtworkSDK } from "@maxeb/art-sdk";
import DocumentActions from "./DocumentActions";

export interface IProps {
  data: IArtwork;
  setOk: () => void;
  setPending: () => void;
  setSuccess: () => void;
}

const style = (theme: ITheme) => ({
  alignCenter: {
    textAlign: "center",
  },
  actions: { width: "60px" },
});

export default function Documents(props: IProps) {
  const classes = useClasses(style);
  const i18n = useI18n("artwork_documents");
  const [error, setError] = useState<string | undefined>();
  const { data } = props;

  const documentsCount = (props.data.documents || []).length;
  const documentsLeft = (props.data.documents || []).length < 10;

  return (
    <Form>
      <StateHandler state={"ok"}>
        <Row spacing={16} root={{ top: 16, bottom: 0, left: 0, right: 0 }}>
          {documentsLeft && (
            <UploadArea
              inline
              lg={4}
              height={"168px"}
              error={error && i18n.get(error)}
              onFileChange={async (file) => {
                if (file) {
                  setError(undefined);
                  props.setPending();
                  try {
                    const result = await ArtworkSDK.update(
                      {
                        documents: { "!add": [file.name + " | " + file.size] },
                      },
                      { id: data.id || "", collection: data.collection || "" }
                    );
                    if (result.isSuccess()) {
                      const url = result.getResult().data.documents[0].url;

                      await fetch(url, {
                        method: "PUT",
                        body: file,
                      });

                      props.setSuccess();
                    } else {
                      const errors = result.getErrors({
                        "body.documents": "documents",
                      });
                      setError(errors.documents);
                      props.setOk();
                    }
                  } catch (err) {
                    props.setOk();
                  }
                }
              }}
            >
              <div className={classes.alignCenter}>
                <b>{i18n.get("thumb")}</b>
                <br />
                {i18n.get("upload")}
              </div>
            </UploadArea>
          )}
          <Col lg={documentsLeft ? 6 : 10}>
            <Table
              columns={[
                {
                  field: "file",
                  label: "Files " + documentsCount + "/10",
                },
                { field: "actions", label: "", classes: classes.actions },
              ]}
              data={(data.documents || []).map((doc) => ({
                file: doc.name,
                actions: (
                  <DocumentActions
                    id={doc.id}
                    name={doc.name}
                    url={doc.url}
                    artwork={data.id || ""}
                    collection={data.collection || ""}
                    setPending={props.setPending}
                    setSuccess={props.setSuccess}
                  />
                ),
              }))}
            />
          </Col>
        </Row>
      </StateHandler>
    </Form>
  );
}

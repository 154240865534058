import React, { useState } from "react";
import {
  Button,
  ITheme,
  LightBox,
  Row,
  useClasses,
  useI18n,
} from "@maxeb/admin-ui";

import HistoryElement from "./HistoryElement";
import NewOwner from "./Owner/New";
import NewLocation from "./Location/New";
import NewCondition from "./Condition/New";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";

export interface IProps {
  owner?: string[];
  location?: string[];
  condition?: string[];
  addOwner: (
    name: string,
    date: [string, string, string],
    description: string
  ) => Promise<any>;
  deleteOwner: (id: string) => {};
  addLocation: (
    location: string,
    date: [string, string, string],
    description: string,
    latest?: string
  ) => Promise<any>;
  deleteLocation: (id: string) => {};
  addCondition: (
    name: string,
    date: [string, string, string],
    description: string
  ) => Promise<any>;
  deleteCondition: (id: string) => {};
}

export interface IDataElement {
  id: string;
  title: string;
  description: string;
  date: string;
  type: "owner" | "location" | "condition";
}

const styles = (theme: ITheme) => ({
  wrapper: {
    padding: "16px 0px 0px 0px",
  },
});

function generateParser(type: "owner" | "location" | "condition") {
  return (item: string) => {
    const [date, title, description] = item.split("|");
    return { id: item, date, title, description, type };
  };
}

export default function Log(props: IProps) {
  const classes = useClasses(styles);
  const i18n = useI18n("artwork_edit");

  const [add, setAdd] = useState<"owner" | "location" | "condition">();

  const data: IDataElement[] = [
    ...(props.owner || []).map(generateParser("owner")),
    ...(props.location || []).map(generateParser("location")),
    ...(props.condition || []).map(generateParser("condition")),
  ].sort((a, b) => {
    return +new Date(b.date) - +new Date(a.date);
  });

  return (
    <>
      <Row
        spacing={8}
        vertical
        root={{ top: 24, left: 0, right: 0, bottom: 8 }}
        horizontalAlign="center"
      >
        <Button
          xs="100px"
          onClick={() => {
            setAdd("location");
          }}
        >
          <FontAwesomeIcon icon={faPlus} /> Location
        </Button>
        <Button
          xs="100px"
          onClick={() => {
            setAdd("owner");
          }}
        >
          <FontAwesomeIcon icon={faPlus} /> Owner
        </Button>
        <Button
          xs="110px"
          onClick={() => {
            setAdd("condition");
          }}
        >
          <FontAwesomeIcon icon={faPlus} /> Condition
        </Button>
      </Row>
      <div className={classes.wrapper}>
        {data.map((item, index) => {
          return (
            <HistoryElement
              key={`${index}-${item.id}`}
              title={item.title}
              description={item.description}
              date={item.date}
              type={item.type}
              delete={() => {
                switch (item.type) {
                  case "owner":
                    return props.deleteOwner(item.id);
                  case "location":
                    return props.deleteLocation(item.id);
                  case "condition":
                    return props.deleteCondition(item.id);
                }
              }}
            />
          );
        })}
      </div>
      <LightBox
        title={
          (add === "owner" && i18n.get("owner")) ||
          (add === "location" && i18n.get("location")) ||
          (add === "condition" && i18n.get("condition"))
        }
        open={typeof add !== "undefined"}
        onClose={() => {
          setAdd(undefined);
        }}
      >
        {add === "owner" && (
          <NewOwner add={props.addOwner} close={() => setAdd(undefined)} />
        )}
        {add === "location" && (
          <NewLocation
            add={props.addLocation}
            onClose={() => setAdd(undefined)}
          />
        )}
        {add === "condition" && (
          <NewCondition
            add={props.addCondition}
            onClose={() => setAdd(undefined)}
          />
        )}
      </LightBox>
    </>
  );
}

import { IArtworkAdd } from "@maxeb/art-sdk";

export interface Props {
  collection: string;
}

export interface State {
  status: "ok" | "pending" | "error" | "success";
  values: IArtworkAdd;
  artistName?: string;
  errors: { [key: string]: string };
  collection: string;
}

export type SetState = (state: State) => void;

export function initialState(props: Props): State {
  const { collection } = props;

  return { status: "ok", values: {}, errors: {}, collection };
}

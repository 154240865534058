import React, { useState } from "react";
import { AdminPage, ITheme, useClasses, useI18n } from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/pro-light-svg-icons";

import HowToAndSelectType from "./HowToAndSelectType";
import Wizzard from "./Wizzard";

export interface IProps {
  team: string;
}

export interface IState {
  type?: "artist" | "artwork";
}

const styles = (theme: ITheme) => ({
  icon: {
    fontSize: "4rem",
    margin: "0px 16px 0px 0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
      margin: "0px 4px 0px 0px",
    },
  },
});

export default function Artists(props: IProps) {
  const classes = useClasses(styles);
  const i18n = useI18n("import");

  const [state, setState] = useState<IState>({});

  const breadcrumbs = [
    { label: i18n.get("import") },
    { label: i18n.get("data") },
  ];
  if (state.type) breadcrumbs.push({ label: i18n.get(state.type) });

  return (
    <AdminPage
      icon={<FontAwesomeIcon className={classes.icon} icon={faUpload} />}
      title={i18n.get("import")}
      state="ok"
      breadcrumbs={breadcrumbs}
      override={{ overlay: classes.overlay, main: classes.main }}
    >
      {!state.type && (
        <HowToAndSelectType
          onChange={(type) => {
            setState({ ...state, type });
          }}
        />
      )}
      {state.type && (
        <Wizzard
          type={state.type}
          team={props.team}
          onReset={() => setState({})}
        />
      )}
    </AdminPage>
  );
}

import React, { useEffect, useRef } from "react";

export interface IProps {
  onChange: (isVisible: boolean) => void;
}

function useIsVisible(
  ref: React.RefObject<HTMLDivElement>,
  callback: (isVisible: boolean) => void
) {
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      callback(entry.isIntersecting);
    });
    if (ref.current !== null && observer.takeRecords().length === 0) {
      observer.observe(ref.current);
    }
    return () => {
      observer.disconnect();
    };
  });
}

function IsVisble(props: IProps) {
  //handle state
  const ref = useRef<HTMLDivElement>(null);
  useIsVisible(ref, props.onChange);

  return <div ref={ref} />;
}

export default IsVisble;

import React, { useState } from "react";
import {
  ITheme,
  useClasses,
  useI18n,
  SelectCustom,
  ICol,
} from "@maxeb/admin-ui";

import { Artist, IArtist } from "@maxeb/art-sdk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { ISelct as ISelect } from "@maxeb/admin-ui/lib/components/Forms/SelectCustom";
import Input from "@maxeb/admin-ui/lib/components/Forms/Shared/Input";
import IsVisible from "../Shared/IsVisible";
import { useArtists } from "../../Hooks/Artists/List";

export interface IProps extends ICol {
  value?: string;
  onChange: (id?: string, name?: string) => void;
  override?: ISelect["override"];
  allowReset?: boolean;
  error?: string;
}

export interface IState {
  status: "init" | "ok" | "pending" | "error" | "success";
  search: { open: boolean; name?: string; direction: "ASC" | "DESC" };
  data: IArtist[];
  value?: string;
}

const styles = (theme: ITheme) => ({
  curtain: {
    zIndex: "201 !important",
  },
  list: {
    padding: "0px",
    margin: "0px",
    listStyle: "none",
  },
  listElement: {
    cursor: "pointer",
    height: "32px",
    lineHeight: "32px",
    margin: "0px -16px",
    padding: "5px 24px  5px 24px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "&:hover": {
      backgroundColor: theme.palette.get("primary"),
      color: "#fff",
    },
  },
  pending: {
    fontSize: "4rem",
    textAlign: "center",
    color: theme.palette.get("primary"),
  },
});

let MOUNTED = false;

const DELAY = 250;
let textTimeout: NodeJS.Timeout | null = null;

export async function init(state: IState, setState: (state: IState) => void) {
  setState({ ...state, status: "pending" });
  try {
    const result = await Artist.get(
      {},
      ["id", "name", "thumb", "team"],
      20,
      "secondaryIndex",
      state.search.direction
    );

    if (MOUNTED) {
      if (result.isSuccess())
        setState({ ...state, status: "ok", data: result.body.data });
    }
  } catch (err) {
    console.error(err);
    if (MOUNTED) setState({ ...state, status: "error" });
  }
}

export default function ArtistsSelect(props: IProps) {
  const classes = useClasses(styles);
  const i18n = useI18n("artist_select");

  const i18nErrors = useI18n("errors");

  const [artist, actions] = useArtists();
  const { status, data } = artist;
  const [search, setSearch] = useState<string>();
  const [filter, setFilter] = useState<{ name?: string }>({});

  const [label, setLabel] = useState<string | undefined>(props.value);
  const [open, setOpen] = useState(false);

  return (
    <SelectCustom
      inline
      label={i18n.get("artist")}
      {...props}
      open={open}
      value={label}
      error={props.error && i18nErrors.get(props.error)}
      setOpen={() => {
        setOpen(!open);
      }}
      override={{ ...(props.override || {}), curtain: classes.curtain }}
    >
      <ul className={classes.list}>
        <li className={classes.search}>
          <Input
            inline
            autoFocus
            placeholder="Search"
            value={search || ""}
            onChange={(value) => {
              setSearch(value);

              if (textTimeout) clearTimeout(textTimeout);
              textTimeout = setTimeout(() => {
                setFilter({ name: value?.toLocaleLowerCase() });
              }, DELAY);
            }}
            rows={0}
            type="text"
            setFocus={() => {}}
            hasError={false}
          />
        </li>
        {props.allowReset && (
          <li
            className={classes.listElement}
            onClick={() => {
              setLabel(undefined);
              props.onChange();
            }}
          >
            Alle
          </li>
        )}
        {data
          .filter((artist) => {
            if (filter && filter.name) {
              const name = artist.name?.toLocaleLowerCase();
              return name?.includes(filter.name);
            } else return true;
          })
          .map((artist) => (
            <li
              key={`artist-${artist.id}`}
              className={classes.listElement}
              onClick={() => {
                setLabel(artist.name);
                props.onChange(artist.id || "", artist.name);
              }}
            >
              {artist.name}
            </li>
          ))}
        {(status === "init" || status === "pending") && (
          <li className={classes.pending}>
            <FontAwesomeIcon icon={faSpinnerThird} spin />
          </li>
        )}
        <IsVisible
          onChange={(isVisible) => {
            if (isVisible && status === "ok" && artist.next) actions.next();
          }}
        />
      </ul>
    </SelectCustom>
  );
}

import React from "react";
import {
  Form,
  Typo,
  Block,
  TextInput,
  NumberInput,
  DateInput,
  useI18n,
  ITheme,
  useClasses,
} from "@maxeb/admin-ui";

import { IArtwork } from "@maxeb/art-sdk";
import { State as IParentState } from "../../../Hooks/Artworks/Edit";

export interface IProps {
  data: IArtwork;
  errors: { [key: string]: string };
  onChange: (data: IParentState["data"]) => void;
}

function getDate(value?: string): [string, string, string] {
  if (value) {
    const [year, month, day] = value.split("-");
    return [day, month, year];
  } else return ["", "", ""];
}

const styles = (theme: ITheme) => ({
  headline: {
    "@media print": {
      marginTop: "180px",
    },
  },
});

export default function Selling(props: IProps) {
  const { data } = props;

  const classes = useClasses(styles, props);
  const i18n = useI18n("artwork_selling");

  return (
    <Block>
      <Typo
        variant="section"
        color="primary"
        margin="none"
        override={{ className: classes.headline }}
      >
        {i18n.get("selling")}
      </Typo>
      <Form spacing={16} vertical root={0}>
        <DateInput
          lg={5}
          print={10 / 3}
          label={i18n.get("reserved_from")}
          value={getDate(data.reservedFrom)}
          onChange={(value) =>
            props.onChange({
              ...data,
              reservedFrom: value
                ? `${value[2]}-${value[1]}-${value[0]}`
                : value,
            })
          }
        />
        <DateInput
          lg={5}
          print={10 / 3}
          label={i18n.get("reserved_until")}
          value={getDate(data.reservedUntil)}
          onChange={(value) =>
            props.onChange({
              ...data,
              reservedUntil: value
                ? `${value[2]}-${value[1]}-${value[0]}`
                : value,
            })
          }
        />
        <TextInput
          lg={5}
          print={10 / 3}
          label={i18n.get("reserved_for")}
          value={data.reservedFor}
          onChange={(value) =>
            props.onChange({
              ...data,
              reservedFor: value,
            })
          }
        />
        <TextInput
          lg={5}
          print={10 / 3}
          label={i18n.get("reserved_reason")}
          value={data.reservedReason}
          onChange={(value) =>
            props.onChange({
              ...data,
              reservedReason: value,
            })
          }
        />
        <NumberInput
          print={10 / 3}
          label={i18n.get("selling_price")}
          value={data.sellingPrice}
          onChange={(value) =>
            props.onChange({
              ...data,
              sellingPrice: value,
            })
          }
        />
      </Form>
    </Block>
  );
}

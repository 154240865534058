import React, { useState } from "react";
import {
  Block,
  Button,
  Col,
  Form,
  ITheme,
  Radio,
  RadioItem,
  Row,
  Typo,
  UploadArea,
  useClasses,
  useI18n,
} from "@maxeb/admin-ui";

import CollectionSelect from "../Collections/Select";

export interface IProps {
  type: "artist" | "artwork";
  selectedFields?: string[];
  value?: { [key: string]: any };
  collection?: string;
  setCollection: (value?: string) => void;
  onNext: (csv: string[][]) => void;
  onBack: () => void;
}

export interface IState {
  string: string;
  array: string[][];
  error?: boolean;
}

const styles = (theme: ITheme) => ({
  next: { textAlign: "right" },
  fieldName: { color: theme.palette.get("primary") },
  previewTable: {
    borderCollapse: "collapse",
    marginTop: "16px",
    maxWidth: "100%",
    maxHeight: "200px",
    overflow: "scroll",
    display: "block",
  },
  previewTableRowEven: {
    backgroundColor: theme.palette.get("default"),
  },
  previewTableRowOdd: {},
  previewTableCell: {
    border: "1px solid",
    padding: "4px 8px",
    whiteSpace: "nowrap",
    maxWidth: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

function CSVToArray(
  strData: string,
  strDelimiter: string,
  selectedFields: string[],
  setState: (state: IState) => void
) {
  strDelimiter = strDelimiter || ",";

  const hasBOM = strData.startsWith("ï»¿");
  console.log(hasBOM);

  if (hasBOM) strData = strData.replace("ï»¿", "");

  const objPattern = new RegExp(
    // Delimiters.
    "(\\" +
      strDelimiter +
      "|\\r?\\n|\\r|^)" +
      // Quoted fields.
      '(?:"([^"]*(?:""[^"]*)*)"|' +
      // Standard fields.
      '([^"\\' +
      strDelimiter +
      "\\r\\n]*))",
    "gi"
  );

  const arrData: string[][] = [[]];

  let arrMatches = null;
  let firstRow: string[] | null = null;

  while ((arrMatches = objPattern.exec(strData))) {
    const strMatchedDelimiter = arrMatches[1];
    if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter)
      arrData.push([]);

    let strMatchedValue;
    if (arrMatches[2])
      strMatchedValue = arrMatches[2].replace(new RegExp('""', "g"), '"');
    else strMatchedValue = arrMatches[3];

    arrData[arrData.length - 1].push(strMatchedValue);

    if (!firstRow) firstRow = arrData[0];
  }
  console.log(
    selectedFields.filter((field) => !firstRow?.includes(field)),
    firstRow?.filter((field) => !selectedFields?.includes(field))
  );
  const error = !(
    firstRow?.length === selectedFields.length &&
    !selectedFields.filter((field) => !firstRow?.includes(field)).length
  );

  setState({ string: strData, array: arrData, error });
}

export default function Upload(props: IProps) {
  const classes = useClasses(styles);
  const i18n = useI18n("import");

  const [delimiter, setDelimiter] = useState(",");
  const [csv, setCSV] = useState<IState>();

  return (
    <Row spacing={16} vertical root={0}>
      <Typo variant="h1">{i18n.get("import_" + props.type)}</Typo>
      <Col lg={5}>
        <Block>
          <Typo variant="section" color="primary" margin="none">
            4.{i18n.get("upload_csv")}
          </Typo>
          <Typo variant="p">{i18n.get("upload_csv_desc")}</Typo>
          <Form
            spacing={16}
            vertical
            root={{ top: 16, left: 0, right: 0, bottom: 0 }}
          >
            <Radio
              label="Delimiter"
              value={delimiter}
              onChange={(value) => {
                setDelimiter(value || ",");
                if (csv?.string)
                  CSVToArray(
                    csv.string,
                    value || ",",
                    props.selectedFields || [],
                    setCSV
                  );
              }}
            >
              <RadioItem xs={10 / 4} label="," value="," />
              <RadioItem xs={10 / 4} label=";" value=";" />
            </Radio>
            {!csv && (
              <UploadArea
                onChange={(value) => {
                  const [, data] = (value || "").split(",");
                  const csvString = window.atob(data || "");
                  CSVToArray(
                    csvString,
                    delimiter,
                    props.selectedFields || [],
                    setCSV
                  );
                }}
                height={"200px"}
              >
                {i18n.get("upload_csv")}
              </UploadArea>
            )}
            {csv && (
              <>
                <Typo variant="section" margin="none">
                  {i18n.get("preview")}
                </Typo>
                <table className={classes.previewTable}>
                  <tbody>
                    {csv.array.map((row, index) => {
                      return (
                        <tr
                          className={
                            index % 2
                              ? classes.previewTableRowOdd
                              : classes.previewTableRowEven
                          }
                          key={`row-${index}`}
                        >
                          {row.map((col, n) => (
                            <td
                              key={`row-${index}-col-${n}`}
                              className={classes.previewTableCell}
                            >
                              {col}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {csv.error && (
                  <Typo variant="p" color="danger">
                    hasError
                  </Typo>
                )}
              </>
            )}
            <Typo variant="p">{i18n.get("collection_desc")}</Typo>
            <CollectionSelect
              value={props.collection}
              onChange={(value) => props.setCollection(value)}
            />
            <Row>
              <Col xs={5}>
                <Button width={"100px"} onClick={props.onBack}>
                  Back
                </Button>
              </Col>
              <Col override={{ col: classes.next }} xs={5}>
                <Button
                  width={"100px"}
                  primary
                  onClick={() => {
                    if (csv) props.onNext(csv.array);
                  }}
                  disabled={Boolean(csv?.error || !csv?.array)}
                >
                  Start Import
                </Button>
              </Col>
            </Row>
          </Form>
        </Block>
      </Col>
    </Row>
  );
}

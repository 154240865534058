import { IArtistAdd } from "@maxeb/art-sdk";

export interface State {
  status: "ok" | "pending" | "error" | "success";
  values: IArtistAdd;
  errors: { [key: string]: string };
}
export type SetState = (state: State) => void;

export function initialState(): State {
  return {
    status: "ok",
    values: {
      name: "",
      public: false,
    },
    errors: {},
  };
}

import { IWebsite, Website } from "@maxeb/art-sdk";
import { SetState, State, initialState } from "./State";

export async function get(
  props: { team: string },
  state: State,
  setState: SetState
) {
  setState({ ...state, status: "pending" });

  try {
    const result = await Website.get(
      {
        team: props.team,
      },
      [
        "id",
        "thumb",
        "path",
        "title",
        "teaser",
        "description",
        "name",
        "legalForm",
        "personResponsible",
        "email",
        "jurisdiction",
        "corporateRegisterNumber",
        "address",
        "country",
        "language",
        "team",
      ],
      20,
      "secondaryIndex"
    );

    if (result.isSuccess() && result.body.data.length === 1) {
      const data = result.body.data[0];
      setState({ ...state, status: "ok", data });
      return data;
    } else if (result.isSuccess() && result.body.data.length === 0) {
      setState({ ...state, status: "ok", data: null });
      return null;
    }
  } catch (err) {
    console.error(err);
  }
  setState({ ...state, status: "error" });
}

export async function activate(
  props: { path: string },
  state: State,
  setState: SetState
) {
  setState({ ...state, status: "pending" });

  try {
    const response = await Website.add({ path: props.path });

    if (response.isSuccess()) {
      success(state, setState);
      return null;
    } else {
      const errors = response.getErrors({
        path: "path",
      });

      if (Object.keys(errors).length)
        return setState({ ...state, status: "ok", errors });
    }
  } catch (err) {
    console.error(err);
  }
  setState({ ...state, status: "error" });
}

export async function deactivate(state: State, setState: SetState) {
  if (state.data?.id) {
    setState({ ...state, status: "pending" });

    try {
      const response = await Website.remove({ id: state.data?.id });

      if (response.isSuccess()) {
        success(state, setState);
        return null;
      }
    } catch (err) {
      console.error(err);
    }
    setState({ ...state, status: "error" });
  }
}

export async function save(state: State, setState: SetState) {
  if (state.data?.id) {
    setState({ ...state, status: "pending" });

    try {
      const { data } = state;
      const response = await Website.update(
        {
          title: data.title || null,
          language: data.language || null,
          teaser: data.teaser || null,
          description: data.description || null,
          name: data.name || null,
          legalForm: data.legalForm || null,
          personResponsible: data.personResponsible || null,
          email: data.email || null,
          jurisdiction: data.jurisdiction || null,
          corporateRegisterNumber: data.corporateRegisterNumber || null,
          address: data.address || null,
          country: data.country || null,
        },
        { id: state.data.id }
      );

      if (response.isSuccess()) {
        success(state, setState);
        return null;
      } else {
        const errors = response.getErrors({
          "body.title": "title",
          "body.teaser": "teaser",
          "body.description": "description",
        });

        if (Object.keys(errors).length)
          return setState({ ...state, status: "ok", errors });
      }
    } catch (err) {
      console.error(err);
    }
    setState({ ...state, status: "error" });
  }
}

export function resetErrors(state: State, setState: SetState) {
  setState({ ...state, errors: {} });
}

export async function setData(
  props: IWebsite,
  state: State,
  setState: SetState
) {
  setState({ ...state, data: { ...state.data, ...props } });
}

export async function success(state: State, setState: SetState) {
  setState({ ...state, status: "success" });
  setTimeout(() => {
    setState(initialState());
  }, 1000);
}

export async function upload(
  props: { thumb: string | null },
  state: State,
  setState: SetState
) {
  if (state.data?.id) {
    setState({ ...state, status: "pending" });

    try {
      const response = await Website.update(
        {
          thumb: props.thumb,
        },
        { id: state.data.id }
      );

      if (response.isSuccess()) {
        success(state, setState);
        return null;
      } else {
        const errors = response.getErrors({
          "body.thumb": "thumb",
        });

        if (Object.keys(errors).length)
          return setState({ ...state, status: "ok", errors });
      }
    } catch (err) {
      console.error(err);
    }
    setState({ ...state, status: "error" });
  }
}

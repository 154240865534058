import React from "react";
import {
  Form,
  Typo,
  Block,
  TextInput,
  Radio,
  RadioItem,
  NumberInput,
  useI18n,
  Row,
  Button,
  Col,
} from "@maxeb/admin-ui";

import { ICollection } from "@maxeb/art-sdk";
import { State as IParentState } from "../../../Hooks/Collections/Edit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faShareAlt } from "@fortawesome/pro-light-svg-icons";

export interface IProps {
  data: ICollection;
  errors: { [key: string]: string };
  onChange: (data: IParentState["data"]) => void;
}

export default function Data(props: IProps) {
  const i18n = useI18n("collection_edit");
  const { data, errors } = props;

  return (
    <Block>
      <Typo variant="section" color="primary" margin="none">
        {i18n.get("data")}
      </Typo>
      <Form
        spacing={16}
        vertical
        root={{ top: 16, left: 0, right: 0, bottom: 0 }}
      >
        <TextInput
          inline
          label={i18n.get("name")}
          lg={7}
          value={data.name}
          error={errors.name}
          onChange={(value) => props.onChange({ ...data, name: value })}
        />
        <NumberInput
          inline
          label={i18n.get("weight")}
          lg={3}
          value={data.weight}
          error={errors.name}
          onChange={(value) => props.onChange({ ...data, weight: value })}
        />
        <TextInput
          inline
          label={i18n.get("description")}
          rows={12}
          value={data.description}
          error={errors.description}
          onChange={(value) => props.onChange({ ...data, description: value })}
        />
        <Radio
          lg={3}
          label={i18n.get("visibility")}
          inline
          value={data.public ? "public" : "private"}
          error={errors.public}
          onChange={(value) => {
            props.onChange({ ...data, public: value === "public" });
          }}
        >
          <RadioItem xs={5} label={i18n.get("public")} value="public" />
          <RadioItem xs={5} label={i18n.get("private")} value="private" />
        </Radio>
        <Col lg={7}>
          {data.public && (
            <Row spacing={8} root={0}>
              <TextInput
                xs="rest"
                inline
                label={i18n.get("description")}
                value={`https://share.webcollector.at?t=${data.team}&c=${data.id}`}
                error={errors.description}
                onChange={(value) => {}}
              />
              <Button
                primary
                xs={"50px"}
                onClick={async () => {
                  try {
                    await navigator.share({
                      title: data.name,
                      text: "",
                      url: `https://share.webcollector.at?t=${data.team}&c=${data.id}`,
                    });
                  } catch (err) {}
                }}
              >
                <FontAwesomeIcon icon={faShareAlt} />
              </Button>
              <Button
                success
                xs={"50px"}
                onClick={() => {
                  navigator.clipboard.writeText(
                    `https://share.webcollector.at?t=${data.team}&c=${data.id}`
                  );
                }}
              >
                <FontAwesomeIcon icon={faCopy} />
              </Button>
            </Row>
          )}
        </Col>
      </Form>
    </Block>
  );
}

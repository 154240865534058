import React, { useState } from "react";
import {
  Row,
  Form,
  Button,
  StateHandler,
  ITheme,
  useClasses,
} from "@maxeb/admin-ui";
import { IDP, ISession } from "@maxeb/idp-sdk";
import TeamListItem from "./TeamListItem";

const spacing = 16;

const styles = (theme: ITheme) => ({
  overlay: {
    margin: "0px -16px",
  },
});

export interface IProps {
  user: ISession;
  setSession: (session: ISession | null) => void;
  onClose?: () => void;
}

async function swap(team: string, user: ISession) {
  return IDP.swapTeam({
    team: team,
    owner: user.id,
  });
}

export default function TeamSwap(props: IProps) {
  const classes = useClasses(styles);
  const [state, setState] = useState<"success" | "ok" | "pending" | "error">(
    "ok"
  );
  const [selected, setSelected] = useState<string | undefined>();

  return (
    <StateHandler override={{ overlay: classes.overlay }} state={state}>
      <Form spacing={spacing} vertical root={0}>
        <Row spacing={spacing} vertical root={0}>
          {props.user.teams?.map((team) => (
            <TeamListItem
              key={team}
              id={team}
              selected={team === selected}
              onSelected={() => {
                setSelected(team);
              }}
            />
          ))}
        </Row>
        <Row spacing={spacing} vertical root={0} horizontalAlign="right">
          {props.onClose && (
            <Button xs="100px" onClick={props.onClose}>
              Cancel
            </Button>
          )}
          <Button
            xs="100px"
            primary
            onClick={async () => {
              if (selected) {
                setState("pending");
                try {
                  const response = await swap(selected, props.user);
                  if (response.isSuccess()) {
                    setState("success");
                    const session = response.getResult().data;
                    props.setSession(session);
                    setTimeout(() => {
                      window.location.href = "/";
                    }, 1000);
                  } else {
                    response.getErrors({});
                    setState("error");
                  }
                } catch (err) {
                  console.error(err);
                  setState("error");
                }
              }
            }}
          >
            Swap
          </Button>
        </Row>
      </Form>
    </StateHandler>
  );
}

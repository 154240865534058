import React, { useState } from "react";
import {
  AdminPage,
  Button,
  Col,
  Form,
  ITheme,
  LightBox,
  Row,
  StateHandler,
  TextInput,
  Typo,
  useClasses,
  useI18n,
} from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrowser } from "@fortawesome/pro-light-svg-icons";
import { State, Actions } from "../../Hooks/Website/Edit";

export interface IProps {
  state: State;
  actions: Actions;
}

const styles = (theme: ITheme) => ({
  icon: {
    fontSize: "4rem",
    margin: "0px 16px 0px 0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
      margin: "0px 4px 0px 0px",
    },
  },
});

export default function DeactivatedWebsite(props: IProps) {
  const classes = useClasses(styles);
  const i18n = useI18n("website");
  const i18nErrors = useI18n("errors");

  const [confirmAdd, setConfirmAdd] = useState(false);
  const [path, setPath] = useState<string>();

  const { state, actions } = props;

  return (
    <AdminPage
      title={i18n.get("website")}
      state={state.status === "init" ? "pending" : state.status}
      icon={<FontAwesomeIcon className={classes.icon} icon={faBrowser} />}
      breadcrumbs={[{ label: i18n.get("website") }]}
      actions={
        <Button primary onClick={() => setConfirmAdd(true)}>
          Activate
        </Button>
      }
      override={{ overlay: classes.overlay, main: classes.main }}
    >
      <Row>
        <Col lg={5}>
          <Typo variant="h1">How does it work?</Typo>
          <Typo variant="p">
            This feature allows you to host your own public website managed with
            webcollector.
            <br />
            The website contains an artworks, about, and imprint section.
          </Typo>
          <Typo variant="h3">Artworks</Typo>
          <Typo variant="p">
            Contains all public collections and it's artworks.
          </Typo>
          <Typo variant="h3">About</Typo>
          <Typo variant="p">
            This section provides a title, teaser and description. You are also
            able to upload an image displayed on this page.
          </Typo>
          <Typo variant="h3">Imprint</Typo>
          <Typo variant="p">
            The legal section of your page with all information you are legally
            required to provide to your visitors.
          </Typo>
          <Typo variant="h1">Getting Started?</Typo>
          <Typo variant="p">Click "Activate" and follow instructions.</Typo>
        </Col>
      </Row>

      <LightBox
        open={confirmAdd}
        title={"Activate Website"}
        onClose={() => {
          actions.resetErrors();
          setConfirmAdd(false);
        }}
      >
        <StateHandler
          state={state.status === "init" ? "pending" : state.status}
        >
          <p>Enter a path for your website. Use only alphanumeric values.</p>
          <Form spacing={16} vertical horizontalAlign="right" root={0}>
            <Col lg="220px">
              <p style={{ marginTop: "4px" }}>
                https://my-site.webcollector.at/
              </p>
            </Col>
            <TextInput
              label="Path"
              inline
              lg="calc(100% - 220px)"
              value={path}
              error={state.errors.path && i18nErrors.get(state.errors.path)}
              onChange={(value) => {
                function isAlphaNumeric(str: string) {
                  const lastChar = str.charCodeAt(str.length - 1);

                  return (
                    (lastChar > 47 && lastChar < 58) ||
                    (lastChar > 64 && lastChar < 91) ||
                    (lastChar > 96 && lastChar < 123)
                  );
                }

                actions.resetErrors();

                if (typeof value === "undefined") setPath(value);
                else if (isAlphaNumeric(value))
                  setPath(value.toLocaleLowerCase());
              }}
            />
            <Button
              xs="120px"
              onClick={() => {
                actions.resetErrors();
                setConfirmAdd(false);
              }}
            >
              Close
            </Button>
            <Button
              xs="120px"
              primary
              onClick={() => {
                if (path) actions.activate(path);
              }}
            >
              Activate
            </Button>
          </Form>
        </StateHandler>
      </LightBox>
    </AdminPage>
  );
}

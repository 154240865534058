import React from "react";
import {
  Form,
  Typo,
  Block,
  TextInput,
  NumberInput,
  Select,
  Col,
  Row,
  Checkbox,
  useClasses,
  ITheme,
  useI18n,
} from "@maxeb/admin-ui";

import { IArtwork } from "@maxeb/art-sdk";
import { State as IParentState } from "../../../Hooks/Artworks/Edit";
import ArtistsSelect from "../../Artists/Select";
import ThemesSelect from "../../Theme/Select";
import CategorySelect from "../../Category/Select";
import TechniqueSelect from "../../Technique/Select";

export interface IProps {
  data: IArtwork;
  errors: { [key: string]: string };
  onChange: (data: IParentState["data"]) => void;
}

const style = (theme: ITheme) => ({
  size: {
    padding: "6px 8px",
  },
});

export default function Data(props: IProps) {
  const { data, errors } = props;

  const classes = useClasses(style);
  const i18n = useI18n("artwork_data");

  return (
    <Block>
      <Typo variant="section" color="primary" margin="none">
        Data
      </Typo>
      <Form
        spacing={16}
        vertical
        root={{ top: 16, left: 0, right: 0, bottom: 0 }}
      >
        <ArtistsSelect
          lg={10 / 3}
          print={10 / 3}
          value={data.artist}
          onChange={(value) => props.onChange({ ...data, artistId: value })}
        />
        <TextInput
          inline
          label={i18n.get("title")}
          lg={10 / 3}
          print={10 / 3}
          value={data.title}
          error={errors.name}
          onChange={(value) => props.onChange({ ...data, title: value })}
        />
        <TextInput
          inline
          label={i18n.get("id")}
          lg={10 / 3}
          print={10 / 3}
          value={data.customId}
          error={errors.name}
          onChange={(value) => props.onChange({ ...data, customId: value })}
        />
        <ThemesSelect
          lg={10 / 3}
          print={10 / 3}
          value={data.themeId}
          onChange={(value) => props.onChange({ ...data, themeId: value })}
        />
        <CategorySelect
          lg={10 / 3}
          print={10 / 3}
          value={data.categoryId}
          onChange={function (value: string): void {
            props.onChange({ ...data, categoryId: value });
          }}
        />
        <TechniqueSelect
          lg={10 / 3}
          print={10 / 3}
          categoryId={data.categoryId}
          value={data.techniqueId}
          onChange={(value) => props.onChange({ ...data, techniqueId: value })}
        />
        <NumberInput
          inline
          label={i18n.get("year")}
          lg={10 / 3}
          print={10 / 3}
          value={data.year}
          error={errors.year}
          onChange={(value) => props.onChange({ ...data, year: value })}
        />
        <Select
          inline
          lg={10 / 3}
          print={10 / 3}
          label={i18n.get("color")}
          value={data.color}
          options={[
            { label: i18n.get("none"), value: 0 },
            { label: i18n.get("white"), value: "#ffffff" },
            { label: i18n.get("red"), value: "#b80e00" },
            { label: i18n.get("orange"), value: "#db3e00" },
            { label: i18n.get("yellow"), value: "#fccb00" },
            { label: i18n.get("green"), value: "#008b02" },
            { label: i18n.get("blue"), value: "#004dcf" },
            { label: i18n.get("purple"), value: "#5300eb" },
            { label: i18n.get("black"), value: "#000000" },
          ]}
          onChange={(value) => {
            if (typeof value === "string")
              props.onChange({ ...data, color: value });
            else props.onChange({ ...data, color: undefined });
          }}
        />
        <Select
          inline
          lg={10 / 3}
          print={10 / 3}
          label={i18n.get("availability")}
          value={data.available}
          options={[
            { label: i18n.get("sold"), value: 0 },
            { label: i18n.get("on request"), value: 1 },
            { label: i18n.get("available"), value: 2 },
          ]}
          onChange={(value) => {
            if (typeof value === "number")
              props.onChange({ ...data, available: value });
          }}
        />
        <Col lg="calc(66.666% - 40px)">
          <Row spacing={16} root={0}>
            <NumberInput
              inline
              label={i18n.get("width")}
              xs={10 / 3}
              value={data.width}
              error={errors.width}
              onChange={(value) => props.onChange({ ...data, width: value })}
            />
            <NumberInput
              inline
              label={i18n.get("height")}
              xs={10 / 3}
              value={data.height}
              error={errors.height}
              onChange={(value) => props.onChange({ ...data, height: value })}
            />
            <NumberInput
              inline
              label={i18n.get("depth")}
              xs={10 / 3}
              value={data.depth}
              error={errors.depth}
              onChange={(value) => props.onChange({ ...data, depth: value })}
            />
          </Row>
        </Col>
        <Col lg="40px" print="hidden">
          <div style={{ paddingTop: "6px" }}>cm</div>
        </Col>
        <Col lg={10 / 3} print="hidden">
          <div className={classes.size}>
            {i18n.get("scope")}:{" "}
            {data.width && data.height
              ? `${2 * (data.width + data.height)} cm`
              : "-"}
          </div>
        </Col>
        <NumberInput
          inline
          label={i18n.get("weight")}
          lg={10 / 4}
          value={data.weight}
          error={errors.weight}
          onChange={(value) => props.onChange({ ...data, weight: value })}
        />
        <Checkbox
          inline
          lg={10 / 4}
          print={10 / 3}
          value={data.signed}
          onChange={(value) => props.onChange({ ...data, signed: value })}
        >
          {i18n.get("signed")}
        </Checkbox>
        <Checkbox
          lg={10 / 4}
          print={10 / 3}
          inline
          value={data.dated}
          onChange={(value) => props.onChange({ ...data, dated: value })}
        >
          {i18n.get("dated")}
        </Checkbox>
        <Checkbox
          inline
          lg={10 / 4}
          print={10 / 3}
          value={data.frame}
          onChange={(value) => props.onChange({ ...data, frame: value })}
        >
          {i18n.get("framed")}
        </Checkbox>
        <TextInput
          inline
          label={i18n.get("description")}
          rows={5}
          value={data.description}
          error={errors.description}
          onChange={(value) => props.onChange({ ...data, description: value })}
        />
      </Form>
    </Block>
  );
}

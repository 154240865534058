import React, { useEffect, useState } from "react";
import { ITheme, StateHandler, useClasses } from "@maxeb/admin-ui";

import { ILog, Log as LogSDK } from "@maxeb/art-sdk";
import LogElement from "./LogElement";

export interface IProps {
  id: string;
}

export interface IState {
  id: string;
  status: "init" | "ok" | "pending" | "error";
  data: ILog[];
}

const styles = (theme: ITheme) => ({
  wrapper: {
    padding: "16px 0px 0px 0px",
  },
});

let MOUNTED = false;

async function get(state: IState, setState: (state: IState) => void) {
  setState({ ...state, status: "pending" });

  try {
    const result = await LogSDK.get(
      {
        object: "artwork",
        objectId: state.id,
      },
      ["id", "event", "fields", "user", "usersName", "createdAt", "team"],
      6,
      "secondaryIndex",
      "DESC"
    );

    if (MOUNTED && result.isSuccess())
      return setState({ ...state, status: "ok", data: result.body.data });
  } catch (err) {
    console.error(err);
  }
  if (MOUNTED) setState({ ...state, status: "error" });
}

export default function Log(props: IProps) {
  const classes = useClasses(styles);
  const [state, setState] = useState<IState>({
    id: props.id,
    status: "init",
    data: [],
  });

  const { status, data } = state;

  useEffect(() => {
    MOUNTED = true;
    if (status === "init") get(state, setState);
    return () => {
      MOUNTED = false;
    };
  }, [status, state]);

  return (
    <StateHandler state={status === "init" ? "pending" : status}>
      <div className={classes.wrapper}>
        {(data || []).map((log) => {
          return (
            <LogElement
              key={log.id}
              id={log.id}
              method={log.event}
              body={log.fields}
              user={log.user}
              usersName={log.usersName}
              createdAt={log.createdAt}
            />
          );
        })}
      </div>
    </StateHandler>
  );
}

import React from "react";
import {
  Form,
  Typo,
  Block,
  TextInput,
  DateInput,
  RadioItem,
  Radio,
  useI18n,
} from "@maxeb/admin-ui";

import { IArtist } from "@maxeb/art-sdk";
import { State as IParentState } from "../../../Hooks/Artists/Edit";

export interface IProps {
  data: IArtist;
  errors: { [key: string]: string };
  onChange: (data: IParentState["data"]) => void;
}

function getBirthdate(
  value: IProps["data"]["birthdate"]
): [string, string, string] {
  if (value) {
    const [year, month, day] = value.split("-");
    return [day, month, year];
  } else return ["", "", ""];
}

export default function Thumb(props: IProps) {
  const { data, errors } = props;
  const i18n = useI18n("artist_edit");

  const birthdate = getBirthdate(data.birthdate);

  return (
    <Block>
      <Typo variant="section" color="primary" margin="none">
        {i18n.get("vita")}
      </Typo>
      <Form
        spacing={16}
        vertical
        root={{ top: 16, left: 0, right: 0, bottom: 0 }}
      >
        <TextInput
          inline
          label={i18n.get("name")}
          value={data.name}
          error={errors.name}
          onChange={(value) => props.onChange({ ...data, name: value })}
        />
        <DateInput
          inline
          lg={5}
          label={i18n.get("birthdate")}
          value={birthdate}
          error={errors.birthdate}
          onChange={(value) => {
            if (value)
              props.onChange({
                ...data,
                birthdate: `${value[2]}-${value[1]}-${value[0]}`,
              });
            else props.onChange({ ...data, birthdate: value });
          }}
        />
        <TextInput
          inline
          lg={5}
          label={i18n.get("living")}
          value={data.placeOfBirth}
          error={errors.placeOfBirth}
          onChange={(value) => props.onChange({ ...data, placeOfBirth: value })}
        />
        <TextInput
          inline
          label={i18n.get("description")}
          rows={12}
          value={data.description}
          error={errors.description}
          onChange={(value) => props.onChange({ ...data, description: value })}
        />
        <Radio
          label={i18n.get("visibility")}
          inline
          value={data.public ? "public" : "private"}
          error={errors.public}
          onChange={(value) => {
            props.onChange({ ...data, public: value === "public" });
          }}
        >
          <RadioItem xs={5} label={i18n.get("public")} value="public" />
          <RadioItem xs={5} label={i18n.get("private")} value="private" />
        </Radio>
      </Form>
    </Block>
  );
}

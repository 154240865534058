import { Artist, IArtistSet } from "@maxeb/art-sdk";
import { SetState, State } from "./State";

export async function get(state: State, setState: SetState) {
  setState({ ...state, status: "pending" });
  try {
    const response = await Artist.get(
      { id: state.id },
      [
        "id",
        "name",
        "thumb",
        "team",
        "birthdate",
        "placeOfBirth",
        "description",
        "education",
        "awardsExhibition",
        "public",
      ],
      20
    );

    if (response.isSuccess() && response.getResult().data.length === 1) {
      const data = response.getResult().data[0];
      setState({ ...state, status: "ok", data, oldName: data.name });
      if (state.onChange) state.onChange(data);
      return data;
    }
  } catch (err) {
    console.error(err);
  }
  setState({ ...state, status: "error" });
}
export async function save(state: State, setState: SetState) {
  setState({ ...state, status: "pending" });

  try {
    const { data } = state;
    const set: IArtistSet = {
      birthdate: data.birthdate || null,
      placeOfBirth: data.placeOfBirth || null,
      description: data.description || null,
      public: data.public || false,
    };

    if (state.oldName !== data.name) set.name = data.name || null;

    const response = await Artist.update(set, { id: state.id });

    if (response.isSuccess()) {
      setState({ ...state, status: "success" });
      return setTimeout(() => {
        setState({ ...state, oldName: data.name, status: "init" });
      }, 1000);
    } else {
      const errors = response.getErrors({
        "body.name": "name",
        "body.birthdate": "birthdate",
        "body.placeOfBirth": "placeOfBirth",
        "body.description": "description",
      });

      if (Object.keys(errors).length)
        return setState({ ...state, status: "ok", errors });
    }
  } catch (error) {
    console.error(error);
  }

  return setState({ ...state, status: "error" });
}
export function setPending(state: State, setState: SetState) {
  setState({ ...state, status: "pending" });
}
export function reload(state: State, setState: SetState) {
  setState({
    ...state,
    status: "success",
  });
  setTimeout(() => {
    setState({
      ...state,
      status: "init",
    });
  }, 1000);
}
export function setData(
  props: { data: State["data"] },
  state: State,
  setState: SetState
) {
  const { data } = props;
  setState({ ...state, data });
}
export async function remove(
  props: { navigate: (to: string) => void },
  state: State,
  setState: SetState
) {
  const newState = { ...state, confirmDelete: false };
  setState({ ...newState, status: "pending" });

  const { navigate } = props;

  try {
    const response = await Artist.remove({ id: state.id });

    if (response.isSuccess()) {
      setState({ ...newState, status: "success" });
      return setTimeout(() => {
        navigate("/artists");
      }, 1000);
    }
  } catch (error) {
    console.error(error);
  }

  return setState({ ...newState, status: "error" });
}
export function setConfirmDelete(
  props: { to: boolean },
  state: State,
  setState: SetState
) {
  const { to } = props;
  setState({ ...state, confirmDelete: to });
}

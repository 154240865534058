import { IArtwork } from "@maxeb/art-sdk";

export interface State {
  status: "init" | "ok" | "pending" | "error" | "success";
  search: {
    open: boolean;
    currentLocation?: string;
    artist?: string;
    artistId?: string;
    categoryId?: string;
    collection: string;
    price: number;
    size: number;
    color?: string;
    proprietor?: string;
    location?: string;
    text?: string;
    sorting: number;
  };
  data: IArtwork[];
  next?: string;
  active: IArtwork | null;
  activeIndex: number | null;
  team: string;
}
export type SetState = (state: State) => void;
export interface Props {
  collection: string;
  team: string;
  searchParams?: State["search"];
}

export function searchInit(collection: string): State["search"] {
  try {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const plainQuery = urlParams.get("q");

    if (plainQuery) {
      const jsonString = decodeURIComponent(plainQuery);
      const query = JSON.parse(jsonString);
      query.collection = collection;
      return query;
    }
  } catch (err) {
    console.error("Could not read deep link query param:", err);
  }

  return {
    open: false,
    collection: collection,
    price: 0,
    size: 0,
    sorting: 1,
  };
}
export function initialState(props: Props): State {
  const { searchParams, collection, team } = props;
  return {
    status: "init",
    search: searchParams || searchInit(collection),
    data: [],
    active: null,
    activeIndex: null,
    team: team,
  };
}
